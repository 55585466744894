@import "styles/variable";

.commercial {
    margin-top: -110px;
    display: flex;
    flex-wrap: wrap;
    &-title {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 150px 20px 90px;
        min-height: 557px;
        width: 100%;
        transition: 1s;
        opacity: 1;
        &--start {
            opacity: 0;
        }
        &__container {
            max-width: 1440px;
            margin: 0 auto;
        }
        h1 {
            color: #fff;
            text-transform: uppercase;
            max-width: 800px;
            line-height: 1.25;
        }
    }
    &-item {
        width: 50%;
        position: relative;
        opacity: 1;
        transition: 1s;
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
        &--start {
            &_left {
                left: -60px;
                opacity: 0;
            }
            &_right {
                right: -60px;
                opacity: 0;
            }
        }
    }
    &-cell {
        max-width: 640px;
        margin-left: auto;
    }
    &__title {
        max-width: 495px;
        margin: 72px auto 45px 0;
    }
    &__desc {
        max-width: 538px;
        &:nth-last-of-type(1) {
            margin-bottom: 113px;
        }
    }
    &-slider {
        top: -61px;
        z-index: 1;
        &__item {
            img {
                width: 100%;
                height: auto;
            }
        }
        button {
            bottom: 42px;
            &.slick-prev {
                left: -48px;
            }
            &.slick-next {
                left: 0;
            }
        }
    }
    &-data {
        &__title {
            margin-bottom: 30px;
        }
        > p {
            max-width: 564px;
            margin-bottom: 40px;
        }
    }

    .floor-scheme {
        margin-bottom: 0;
    }
}

.floor-scheme {
    background: rgba(134, 134, 159, 0.1);
    width: 100%;
    position: relative;
    margin-top: -103px;
    padding-top: 31px;
    padding-bottom: 33px;
    margin-bottom: 53px;
    opacity: 1;
    top: 0;
    transition: 1s;
    &--start {
        opacity: 0;
        top: 60px;
    }
    &--underground {
        margin-top: -31px;
    }
    &__pic {
        position: relative;
        padding: 0 0 27px;
        .scheme__img {
            display: block;
            margin: 75px auto 0;
            max-width: 100%;
            height: auto;
        }
    }
    &__info {
        margin-bottom: -75px;
        position: relative;
        z-index: 1;
    }
    &__subtitle {
        font-family: $font-geo;
        font-size: 28px;
        font-weight: 300;
        margin-bottom: 10px;
    }
    &__item {
        position: absolute;
        width: 298px;
        padding: 32px;
    }
    &__manual {
        color: $color-blue-dark;
        opacity: 0.5;
        margin-right: 37px;
    }
    &__title {
        margin: 0 auto 5px 0;
    }
    &__bottom {
        position: absolute;
        bottom: 0;
        display: flex;
    }
    &__desc {
        padding-top: 31px;
        max-width: 120px;
        h2 {
          font-size: 64px;
        }
        p {
            margin-bottom: 40px;
            font-size: 14px;
            &:nth-last-of-type(1){
                margin-bottom: 0;
            }
        }
    }
    &__link {
        text-transform: uppercase;
        color: $color-blue-darker;
        font-size: 18px;
        line-height: 133%;
        font-weight: 500;
        &:hover {
            color: $color-yellow-light;
        }
    }
    &__scroll {
        display: none;
        width: 100%;
        position: relative;
        cursor: pointer;
        padding: 17px 0;
    }
    &__scale {
        width: 100%;
        height: 2px;
        background: #8787A0;
        opacity: 0.4;
    }
    &__pin {
        width: 36px;
        height: 36px;
        position: absolute;
        top: 0;
        left: 30px;
        background: $color-blue-dark;
        border-radius: 50%;
        &::before {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 13px;
            right: 8px;
            border: 2px solid #fff;
            border-right-color: transparent;
            border-bottom-color: transparent;
            transform: rotate(135deg);
        }
        &::after {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            top: 13px;
            left: 8px;
            border: 2px solid #fff;
            border-right-color: transparent;
            border-bottom-color: transparent;
            transform: rotate(-45deg);
        }
    }
    &__order {
        max-width: 430px;
        position: absolute;
        left: calc(50% - 215px);
        background: #F3F3F5;
        padding: 10px;
        bottom: 195px;
        text-align: center;
        border: 2px solid $color-blue-lighter;
        border-radius: 5px;
        button {
            margin-top: 15px;
        }
        p {
            font-size: 20px;
        }
    }
}

.specification {
    background: $color-blue-dark;
    left: 825px;
    top: 20px;
    z-index: 1;
    &::before {
        content: '';
        width: 6px;
        height: 6px;
        border: 3px solid $color-blue-dark;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
        bottom: -6px;
        left: 0;
        position: absolute;
    }
    &__name {
        color: $color-yellow-light;
        border-bottom: 1px solid rgba(222, 209, 162, 0.3);
        padding-bottom: 14px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    &__desc {
        font-size: 14px;
        color: #fff;
        line-height: 20px;
        span {
            display: block;
        }
        margin-bottom: 10px;
    }
    &__list {
        padding: 0;
        margin: 0;
        li {
            display: flex;
            justify-content: space-between;
            padding-top: 14px;
            margin-bottom: 0;
            span {
                color: #fff;
                &:first-child {
                    opacity: 0.5;
                }
            }
        }
    }
}
.statistics {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50px;
    margin-top: 61px;
    position: relative;
    top: 0;
    opacity: 1;
    transition: 1s;
    &--start {
        top: 60px;
        opacity: 0;
    }
    &__item {
        max-width: 263px;
        line-height: 1.5;
        margin-right: 15px;
        &:nth-last-of-type(1) {
            margin-right: 0;
        }
    }
    &__num {
        font-family: $font-geo;
        font-size: 56px;
        line-height: 70px;
        display: block;
        margin-bottom: 10px;
    }
}

@media all and (max-width: 1439px) {
    .commercial {
        &-cell {
            max-width: 548px;
            padding-left: 40px;
            padding-bottom: 20px;
        }
        &__desc {
            max-width: 470px;
        }
    }
    .specification {
        left: 57%;
    }
    .floor-scheme__desc{
        padding-top: 5px;
    }
}

@media all and (max-width: 940px) {
    .commercial {
        &__title {
            font-size: 26px;
            line-height: 33px;
            margin-top: 35px;
            margin-bottom: 20px;
        }
        &-slider {
            top: -40px;
            button {
                display: none!important;
            }
        }
        &__desc {
            max-width: 293px;
        }
    }
    .floor-scheme {
        &__title {
            font-size: 26px;
            line-height: 33px;
        }
        &__subtitle {
            font-size: 20px;
            line-height: 25px;
        }
        &__info {
            margin-bottom: 0;
            width: calc(100% - 80px);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
        }
        &__desc{
            padding-top: 0;
            max-width: none;
            width: 100%;
            h2{
                font-size: 56px;
            }
            p{
                margin-bottom: 10px;
            }
        }
    }
    .specification {
        top: 120px;
        right: 0;
        left: auto  ;
    }
    .statistics {
        &__num {
            font-size: 44px;
            line-height: 60px;
        }
        &__item {
            max-width: 216px;
        }
    }
}

@media all and (max-width: 700px) {
    .commercial {
        &-item {
            width: 100%;
        }
        &-cell {
            max-width: 100%;
            padding: 0px 20px;
        }
        &__title {
            font-size: 22px!important;
            line-height: 28px!important;
        }
        &-slider {
            display: none;
        }
        &__desc {
            max-width: 100%;
        }
    }
    .floor-scheme {
        section {
            display: flex;
            flex-direction: column;
        }
        &__info {
            position: relative;
            width: 100%;
            order: 1;
            flex-wrap: wrap;
        }
        &__pic {
            order: 3;
        }
        &__bottom {
            order: 2;
            position: static;
        }
        &__manual {
            max-width: 148px;
        }
        &__title {
            max-width: 300px;
        }
        &__desc {
            padding-top: 0;
            top: auto;
            bottom: -50px;
            h2{
                font-size: 42px;
            }
            p{
                margin-bottom: 3px;
            }
        }
        &__scroll {
            display: block;
            order: 4;
        }
        &__order {
            max-width: 100%;
            left: 0;
        }
    }
    .specification {
        max-width: 100%;
        bottom: 80px;
        top: auto;
        padding: 32px 22px;
    }
    .statistics {
        flex-wrap: wrap;
    }
}