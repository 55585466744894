@import "styles/variable";

.gallery {
    width: 100%;
    background: #0f1c2c;
    height: 100vh;
    position: relative;
    &__head {
        position: absolute;
        display: flex;
        padding: 40px;
        align-items: center;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;

        &--environment {
            padding-bottom: 50px;
        }
        &-exit {
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 8px;
            transform: translateY(-50%);
            background: url("/files/images/svg/exit-white.svg") center no-repeat;
            width: 32px;
            height: 32px;
            background-size: 100%;
            transition: .5s;
            &:hover {
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
    &__link {
        font-weight: 500;
        padding: 12px 16px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: #fff;
        margin-right: 20px;
        cursor: pointer;
        flex-shrink: 0;
        background: #0F1C2C;
        &:hover {
            background: #405166;
            color: #fff;
        }
        &--active {
            background: rgba(255, 255, 255, 0.9);
            color: #0F1C2C;

            &:hover {
                background: rgba(255, 255, 255, 0.8);
                color: #0F1C2C;
            }
        }
        &--gold {
            color: $color-yellow-light;
            border-color: $color-yellow-light;
        }
    }
    &__back {
        margin-left: auto;
        color: #DED1A2;
        border: 1px solid #DED1A2;
        font-weight: 500;
        padding: 12px 16px 12px 40px;
        cursor: pointer;
        background: url('/files/images/visual-choice/arrow-back-gold.svg') 12px center no-repeat, #0F1C2C;
        position: absolute;
        left: 24px;
        top: 120px;
        z-index: 99;

        &:hover {
            background-color: #405166;
        }
    }
    &__close {
        position: absolute;
        right: 40px;
        top: 40px;
        width: 32px;
        height: 32px;
        cursor: pointer;
        &::before {
            content: '';
            position: absolute;
            transform: rotate(45deg);
            width: 24px;
            height: 1.5px;
            background: #fff;
            top: 15px;
            left: 4px;
        }
        &::after {
            content: '';
            position: absolute;
            transform: rotate(-45deg);
            width: 24px;
            height: 1.5px;
            background: #fff;
            top: 15px;
            left: 4px;
        }
    }
    &-slider {
        position: relative;
        height: 100%;
        .slick-arrow {
            width: 48px;
            height: 48px;
            font-size: 0;
            border: 0.5px solid #FFFFFF;
            background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #0F1C2C;
            transition: .5s;
            position: absolute;
            z-index: 1;
            top: calc(50% - 24px);
            cursor: pointer;
            &.slick-prev {
                transform: rotate(180deg);
                left: 40px;
            }
            &.slick-next {
                right: 40px;
            }
            &:hover {
                background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #111F30;
              }
        }

        &__item {
            overflow: hidden;
            position: relative;
            height: 100vh;
        }

        .swiper__btn {
            top: calc(50% - 24px);
            z-index: 1;

            &--prev {
                left: 24px;
            }

            &--next {
                right: 24px;
            }
        }
    }

    &__pic {
        display: block;
        height: auto;
        min-height: 100%;
        max-width: 100%;
        width: 1920px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

@media all and (max-width: 1168px) {
    .gallery {
        &__head {
            padding: 20px 80px 20px 20px;
            flex-wrap: wrap;
            &--environment {
                height: auto;
                //padding: 110px 20px 5px;
            }
        }
        &__close {
            right: 20px;
            top: 20px;
        }
        &__link {
            margin-bottom: 15px;
        }
        &__pic{
            min-height: auto;
        }
    }
}

@media all and (max-width: 700px) {
    .gallery {
        &__head {
            padding-right: 50px;
        }
        &__link {
            padding: 12px;
            font-size: 12px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
        &__button {
            width: 30px;
            height: 30px;
            &--prev {
                left: 20px;
            }
            &--next {
                right: 20px;
            }
        }
    }
}

@media all and (max-height: 420px) {
    .gallery{
        &__head{
            flex-wrap: nowrap;
            overflow: auto;
        }
        &__link{
            margin-bottom: 0;
            margin-right: 10px;
            padding: 12px 10px;
        }
    }
}