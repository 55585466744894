@import "styles/variable";

.visual {
  margin-top: -110px;
  padding-top: 128px;
  background: rgba(134, 134, 159, 0.1);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 134px;
    bottom: 0;
    left: 0;
    background: #fff;
  }

  &-section {
    display: flex;
  }

  &__title {
    text-transform: uppercase;
    font-size: 48px;
    margin-bottom: 12px;
    position: relative;
    z-index: 1;

    &--white {
      color: #fff;
      position: absolute;
      top: 168px;
      left: 66px;
    }
  }

  &-parameters {
    background: #FFFFFF;
    box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.06);
    padding: 41px 72px 78px 66px;
    min-height: 682px;
    position: relative;
    top: 0;
    opacity: 1;
    transition: 1s;

    &--start {
      top: -200px;
      opacity: 0;
    }

    p {
      margin-bottom: 20px;
    }

    button {
      margin-top: 26px;
    }
  }

  &-item {
    width: 50%;
  }

  &__solutions {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 66px 78px;
  }

  &-select {
    margin-top: -128px;
    height: 762px;
    padding-top: 70px;
    position: relative;
    background: linear-gradient(180deg, #1f334d 55.17%, rgba(151, 168, 226, 0) 88.34%);
    opacity: 1;
    top: 0;
    transition: 1s;

    &--start {
      top: 200px;
      opacity: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 378px;
      background: linear-gradient(180deg, #1E324B 0%, rgba(30, 50, 75, 0) 100%);
    }

    p {
      position: absolute;
      top: 296px;
      left: 66px;
      color: #fff;
    }

    &__pic {
      width: 100%;
      height: auto;
    }

    &__building {
      position: absolute;
      z-index: 1;
      font-size: 10px;
      display: flex;
      color: #fff;
      align-items: stretch;
      cursor: pointer;

      &--first {
        top: 12.7%;
        left: 48.5%;
      }

      &:hover .visual-select__info {
        display: block;
      }
    }

    &__corpus {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 340px;
      width: 100%;
      cursor: pointer;

      &:hover .visual-select__info {
        display: block;
      }
    }

    &__bubble {
      position: relative;
      width: 50px;
      height: 78px;
      background: $color-blue-dark;
      text-align: center;
      font-family: Geometria, Arial, sans-serif;
      padding: 12px 0;
      margin-right: 1px;

      &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        top: 100%;
        left: 0;
        border: 6px solid #1e324b;
        border-bottom-color: transparent;
        border-right-color: transparent;
      }

      div {
        font-size: 30px;
        line-height: 38px;
        font-weight: 300;
      }
    }

    &__info {
      max-width: 71px;
      padding: 20px 8px 12px;
      background: rgba(30, 50, 75, .9);
      line-height: 15px;
      //display: none;
    }
  }

  &-scheme {
    margin-top: -110px;
    display: flex;

    &__content {
      position: relative;
    }

    &__top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 110px;
      z-index: 3;

      &--fixed{
        position: fixed;
      }

      h1 {
        color: #fff;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 16px;
      }
    }

    &__nav {
      margin-bottom: 13px;
    }

    &__link {
      color: #fff;
      padding-left: 30px;
      background: url("/files/images/visual-choice/arrow-back.svg") left center no-repeat;
    }

    &__buildings {
      display: flex;
      align-items: center;
      color: #fff;
    }

    &__switcher {
      margin-left: 21px;
      border: 1px solid rgba(30, 50, 75, 0.8);
    }

    &__pic {
      position: relative;
      width: 100vw;
      display: flex;
      justify-content: center;
      background: #1E324B;
    }

    &__svg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: auto;
      top: auto;
      width: 100%;
      height: auto;
      min-width: auto;
      min-height: 810px;
      z-index: 2;

      svg {
        //min-width: 100%;
        //min-height: 100%;
        //width: auto;
        //height: auto;
        g {
          fill: none;
          pointer-events: all;
          cursor: pointer;

          path {
            transition: .5s;
          }
        }
      }
    }

    &__location {
      position: absolute;
      top: 212px;
      right: 3%;
      z-index: 1;
      width: 86px;
    }

    &__item {
      padding-top: 110px;
    }

    &__block {
      max-width: 700px;
      width: 50%;
    }

    &__floor {
      display: flex;
      justify-content: center;
      flex-grow: 1;
    }
  }
}

.building {
  &__img {
    display: block;
    width: 1920px;
    height: auto;
  }

  &__sections {
    position: absolute;
    min-width: 1920px;
    min-height: 950px;
    top: 0;
  }

  &__item {
    position: absolute;
    color: #fff;

    &--active {
      z-index: 10;

      .building__bubble {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.2s ease-in, visibility 0.2s ease-in;
      }

      .building__number {
        background: transparent;
        font-size: 0;
        width: 32px;
        height: 32px;
        padding: 0;
        border-radius: 50%;
        display: flex;
        border: 1px solid rgba(#FFFFFF, 0.2);

        &::before {
          content: '';
          margin: auto;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #FFFFFF;
        }

        &-discount {
          opacity: 0;
          visibility: hidden;
          @media (pointer: coarse) {
            visibility: visible;
          }
        }
      }
    }
  }

  &__number {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    text-align: center;
    background: $color-blue-dark;
    font-size: 12px;

    &--discount {
      border-radius: 100px 0 0 100px;
      position: relative;
    }
  }

  &__number-discount {
    position: absolute;
    left: 100%;
    top: 0;
    width: 30px;
    height: 32px;
    background: #C6B166 url("/files/images/svg/percent.svg") no-repeat left 7px center / 11px;
    border-radius: 0 50% 50% 0;
  }

  &__bubble {
    margin-top: 10px;
    position: absolute;
    top: 100%;
    left: 0;
    font-family: $font-mont;
    font-weight: 300;
    width: 300px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0s ease-in, visibility 0s ease-in;
  }

  &__bubble-header {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;
    background: #1E324B;
  }

  &__bubble-header-item {
    margin-right: 23px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.5);

    strong {
      margin-right: 8px;
      font-weight: 300;
      font-size: 24px;
      line-height: 32px;
      color: #C6B166;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__section {
    margin-bottom: 8px;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    color: #FFFFFF;
  }

  &__bubble-body {
    padding: 24px;
    background: #FFFFFF;
  }

  &__apartments {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #1E324B;
  }

  &__apartments-item {
    padding: 10px 24px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFB;

    & + & {
      margin-top: 4px;
    }
  }

  &__apartments-item-discount {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__bubble-btn {
    margin-top: 24px;
    width: 100%;
    text-align: center;
  }
}

.block {
  position: relative;
  background: #C6E2F7;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 386px;
    background: linear-gradient(180deg, #1E324B 0%, rgba(30, 50, 75, 0) 100%);
    //background: linear-gradient(to bottom,  #1e324b 0%,#52a4ca 100%);

  }

  &__item {
    overflow: hidden;
  }

  &__img {
    display: block;
    transition: 1s;
    height: 852px;
  }

  &__svg {
    position: absolute;
    bottom: 0;
    left: 0;

    svg {
      .bg {
        stroke-opacity: 1.0;
        stroke-width: 2;
        stroke: #FFFFFF;
      }

      .floor {
        fill: none;
        pointer-events: all;
        cursor: pointer;
        transition: .5s;

        &:hover {
          stroke-opacity: 1.0;
          stroke-width: 2;
          stroke: #FFFFFF;
        }

        &__choiced {
          stroke-opacity: 1.0;
          stroke-width: 2;
          stroke: #FFFFFF;
        }
      }
    }
  }

  &__back {
    position: absolute;
    top: 0;
    left: 0;
  }

  &__button {
    width: 51px;
    height: 36px;
    border: .5px solid rgba(255, 255, 255, .2);
    background: #21364e;
    transition: .5s;
    position: absolute;
    z-index: 1;
    color: #fff;
    bottom: 272px;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 5px;
      top: 0;
      background: url(/files/images/svg/arrow-slider.svg) center / 100% no-repeat;
    }

    &--prev {
      left: 0;
      text-align: right;

      &::before {
        left: 10px;
        transform: rotate(180deg);
      }
    }

    &--next {
      right: 0;

      &::before {
        right: 10px;
      }
    }
  }

  &__slider {
    background: rgba(67, 67, 129, 0.4);
    position: relative;
  }

  &__selection {
    &-floor {
      opacity: 0;
      transition: .5s;
      position: absolute;
      background: #ffffff;
      font-size: 10px;
      display: flex;
      align-items: center;
      top: 50%;
      left: 50%;
      max-width: 80px;
      padding: 12px 6px;
      font-family: $font-geo;

      span {
        font-size: 30px;
        font-weight: 300;
        margin-right: 8px;
      }
    }
  }

  &__turn {
    position: absolute;
    padding: 7px 13px 7px 33px;
    background: url("/files/images/visual-choice/visual-turn.svg") 10px center no-repeat $color-blue-dark;
    font-size: 12px;
    bottom: 22px;
    left: calc(50% - 57px);
    width: 114px;
    color: #fff;
    border-radius: 60px;
    cursor: pointer;
    z-index: 2;
  }

  &__bubble {
    opacity: 0;
    transition: .5s;
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 6px;
    background: $color-blue-dark;
    font-family: $font-geo;
    text-align: center;
    font-weight: 300;
    width: 50px;
    color: #fff;
  }

  &__section {
    span {
      &:first-child {
        font-size: 30px;
      }

      &:last-child {
        font-size: 10px;
        padding-bottom: 6px;
        display: block;
        position: relative;
        margin-bottom: 9px;

        &::before {
          content: '';
          position: absolute;
          width: 20px;
          height: 1px;
          left: calc(50% - 10px);
          bottom: 0;
          background: $color-blue-lighter;
          opacity: 0.4;
        }
      }
    }
  }

  &__apartments {
    font-size: 12px;
  }

}

.floor {
  &__cell {
    width: 88%;
    max-width: 1000px;
    margin-right: auto;
    padding-top: 250px;
    padding-left: 159px;
  }

  &__scheme {
    position: relative;
    width: 100%;

    svg {
      width: 100%;
    }
  }

  &__side {
    position: absolute;
    color: #0F1C2C;
    opacity: 0.3;
    width: 100%;
    text-align: center;

    &--top {
      top: -42px;
    }

    &--bottom {
      bottom: -38px;
    }

    &--left {
      margin-right: 15px;
      width: 72%;
      height: auto;
      top: 0;
      transform: rotate(-90deg) translateY(-100%);
      transform-origin: 100% 0;
      right: 100%;
    }

    &--right {
      margin-left: 15px;
      width: 72%;
      height: auto;
      top: 0;
      transform: rotate(90deg) translateY(-100%);
      transform-origin: 0 0;
      left: 100%;
    }
  }

  &__location {
    position: absolute;
    top: -84px;
    right: -40px;
  }

  &__item {
    opacity: 0;
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 25px 10px;
    text-align: center;
    cursor: pointer;
    color: #1e324b;
    background: rgba(255, 255, 255, 0.5);
    border: 2px solid white;
    transition: 1s;

    &:hover {
      transition: 1s;
      background: rgba(255, 255, 255, 1);
    }
  }

  &__area {
    font-size: 12px;

    span {
      font-size: 20px;
      font-weight: 400;
    }
  }

  &__rooms {
    font-size: 12px;
    line-height: 21px;
  }
}

#rooms {
  fill: #0f1c2c;
}

//@media all and (max-width: 1640px) {
//    .visual-scheme__svg {
//        display: none;
//    }
//}

@media all and (max-width: 1600px) {
  .floor__cell {
    padding-left: 70px;
  }
}

//@media all and (max-width: 1440px){
//    .visual-scheme__svg, .visual-scheme__svg svg, .building__img {
//        width: 1440px;
//        height: 810px;
//    }
//}

@media all and (max-width: 1439px) {
  .visual {
    &::before {
      height: 195px;
    }

    &-section {
      align-items: flex-start;
    }

    &-select {
      height: auto;

      &__building {
        &--first {
          top: 15.7%;
        }
      }
    }

    &-scheme {
      &__top {
        left: calc(50% - 584px)
      }

      .choice-nav {
        max-width: 352px;
      }
    }
  }
  .floor__cell {
    padding-left: 30px;
  }
  .building {
    &__sections {
      cursor: grab;

      .indiana-scroll-container--dragging & {
        cursor: grabbing !important;
      }
    }
  }

  .visual-scheme__pic {
    justify-content: flex-start;
  }
  .visual-scheme__svg {
    justify-content: flex-start;
    left: 0;
    display: block;
  }
  .building__sections {
    left: 0;
    transform: none;
  }
}

@media all and (max-width: 1168px) {
  .visual {
    &-parameters {
      padding: 50px 40px 41px;
      min-height: 640px;
    }

    &__title {
      &--white {
        left: 37px;
        top: 177px;
      }
    }

    &-select {
      p {
        left: 37px;
        top: 305px;
      }
    }

    &__solutions {
      padding: 0 40px 41px;
    }

    &-scheme {
      flex-wrap: wrap;

      &__top {
        left: 0;
        padding-top: 110px !important;
      }

      &__content {
        background: #233750;
      }
    }
  }
  .block {
    &__slider {
      background: #fff;
    }
  }
  .floor {
    &__location {
      top: -100px;
      right: -50px;
    }
  }
}

@media all and (max-width: 1140px) {
  .floor__cell {
    max-width: 75%;
    margin: 0 auto;
    padding: 130px 0 100px;
  }
  .visual-select__building--first {
    top: 14.7%;
    left: 54.5%;
  }
  .visual-select__corpus {
    height: 310px;
  }
  .visual {
    &-scheme {
      &__block {
        width: 100%;
        max-width: none;
      }

      &__floor {
        width: 100%;
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .visual-select__corpus {
    height: 600px;
  }
  .visual-select__building--first {
    top: 35.7%;
  }
  .visual-select__info {
    display: block;
  }
  .visual {
    margin-top: 0;
    padding-top: 0;

    &::before {
      display: none;
    }

    &-section {
      padding: 0 !important;
      flex-wrap: wrap;
    }

    &-item {
      width: 100%;
    }

    &-parameters {
      min-height: auto;
    }

    &-select {
      margin-top: 0;
      padding-top: 0;

      &::before {
        background: linear-gradient(244.06deg, #F4D4D8 19.57%, rgba(244, 212, 216, 0) 47.98%);
        height: 100%;
        background-blend-mode: screen;
        mix-blend-mode: screen;
        opacity: 0.4;
      }

      p {
        top: 168px;
        left: 42px;
      }

      &__pic {
        width: 100%;
        display: block;
      }
    }

    &__title {
      font-size: 44px;
      line-height: 56px;
      max-width: 502px;

      &--white {
        top: 48px;
      }
    }

    &__solutions {
      position: relative;
      padding: 0;
      margin-top: 35px;
    }

    &-scheme {
      position: relative;

      .choice-nav {
        top: auto !important;
        bottom: 10px;
        right: auto;
        left: 50%;
        transform: translateX(-50%);
        max-width: 280px;
        justify-content: center;
        padding: 12px 27px;
        height: auto;
        z-index: 3;

        &__link {
          font-size: 14px;
          background: none;
          padding: 0;
        }
      }

      &__content {
        width: 100%;
      }

      &__top {
        h1 {
          font-size: 36px;
        }
      }

      //&__pic {
      //    display: flex;
      //    justify-content: center;
      //    overflow: hidden;
      //}
      &__block {
        width: 100%;
      }

      &__floor {
        width: 100%;
        background: #fff;
        z-index: 1;
      }
    }
  }
  .block {
    &__selection {
      height: 64%;
    }
  }
}

@media all and (max-width: 700px) {
  .visual-select__corpus {
    height: 420px;
  }
  .visual-select__building--first {
    top: 23.7%;
  }
  .visual {
    background: none;

    &-parameters {
      background: none;
      padding: 0 20px 20px;
      box-shadow: none;
    }

    &__title {
      font-size: 30px;
      line-height: 40px;

      &--white {
        top: 32px;
        left: 20px;
      }
    }

    &-item {
      p {
        font-size: 18px;
      }
    }

    &-select {
      p {
        top: 85px;
        left: 20px;
      }
    }

    &-scheme {
      &__top {
        padding-top: 86px !important;
        left: 0;

        h1 {
          font-size: 30px;
        }
      }

      &__location {
        top: 162px;
      }

      //&__block {
      //    padding-top: 180px;
      //}
      &__link {
        font-size: 14px
      }

      &__pic {
        justify-content: flex-start;
      }

      .choice-nav {
        max-width: calc(100% - 40px);
      }
    }
  }
  .block {
    position: static;

    &__button {
      bottom: auto;
      top: 647px;
      width: 48px;
      height: 33px;
      font-size: 12px;
    }

    &__selection {
      height: 58%;
    }

    &__turn {
      font-size: 8px;
      left: calc(50% - 45px);
      width: 90px;
    }

    &__floor {
      font-size: 8px;
      width: 44px;

      span {
        font-size: 20px;
        margin-right: 2px;
      }
    }

    &__bubble {
      left: 21.7%;
      width: 46px;
      font-size: 8px;
      padding: 9px 4px;
    }

    &__section {
      span {
        &:first-child {
          font-size: 20px;
        }

        &:last-child {
          font-size: 11px;
        }
      }
    }

    &__apartments {
      font-size: 11px;
    }
  }
  .floor {
    &__cell {
      max-width: 100%;
      padding: 84px 20px 123px;
    }

    &__side {
      font-size: 12px;
    }

    &__location {
      width: 51px;
      right: 0;
      top: -75px;
    }

    &__item {
      width: 51px;
      height: 51px;
      padding: 15px 5px;

      &--one {
        left: 11%;
        bottom: 13%;
      }

      &--two {
        left: 19%;
        top: 6%;
      }

      &--three {
        top: 6%;
        left: 47%;
      }

      &--four {
        left: 68%;
        bottom: 13%;
      }
    }

    &__area {
      font-size: 8px;

      span {
        font-size: 10px;
      }
    }

    &__rooms {
      font-size: 6px;
      line-height: 10px;
    }
  }
  .building {
    &__img {
      margin-left: -315px;
    }

    &__sections {
      left: -315px;
    }
  }

  .visual-scheme__svg {
    display: none;
  }
}

@media all and (max-width: 460px) {
  .visual-select__corpus {
    height: 280px;
  }
  .visual-select__building--first {
    top: 16.7%;
  }
  .block {
    &__selection {
      height: 50%;
    }
  }
}

@media all and (max-width: 410px) {
  .visual-select p {
    top: 120px;
  }
}

@media all and (max-width: 350px) {
  .visual-select__corpus {
    height: 230px;
  }
}
