.swiper__btn {
  width: 48px;
  height: 48px;
  font-size: 0;
  border: .5px solid rgba(255,255,255,.2);
  background: url("/files/images/svg/arrow-slider.svg") center/10px 20px no-repeat #21364e;
  transition: .5s;
  position: absolute;
  z-index: 1;
  cursor: pointer;

  &--prev {
    transform: rotate(180deg);
  }

  &:hover{
    background-color: #111F30;
  }
}

.slick-slider {
  .slick-arrow {
    width: 48px;
    height: 48px;
    font-size: 0;
    border: .5px solid rgba(255,255,255,.2);
    background: url("/files/images/svg/arrow-slider.svg") center/10px 20px no-repeat #21364e;
    transition: .5s;
    position: absolute;
    z-index: 1;
    &.slick-prev {
      transform: rotate(180deg);
    }
  }
}

.slider-location {
  max-width: 1100px;
  margin-bottom: 40px;
  button {
    top: 113px;
  }
  .slick-prev {
    right: 0;
  }
  .slick-next {
    left: 100%;
  }
}

.slider-stairs {
  position: relative;
  max-height: 840px;
  button {
    top: -84px;
    &.slick-prev {
      right: 48px;
    }
    &.slick-next {
      right: 0;
    }
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 1px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 24.48%, #FFFFFF 80.21%, rgba(255, 255, 255, 0) 100%);
    opacity: .4;
    transform: rotate(180deg);
  }
  .slick-track {
    height: 800px;
  }
  .slick-slide {
    transition: width .5s,margin .5s,height .5s,left .5s;
    left: 0;
    width: 640px;
    background: #1e324b;
    position: relative;
    margin-top: 40px;
    img {
      transition: .5s;
    }

    > div {
      height: 100%;
    }
    /*&:hover {
      img {
        transition: .5s;
        transform: scale(1.2);
      }

    }*/
  }
  .slick-active {
    &--first {
      margin-top: 40px;
    }

    &--second {
      margin-top: 20px;
      width: 346px;
      height: calc(100% + 20px);

      &:hover {
        width: 640px;
        left: -294px;

        & + .slick-active--third {
          left: -294px;
        }
      }
    }

    &--third {
      width: 294px;
      height: calc(100% + 40px);

      &:hover {
        width: 640px;
        left: -346px;
      }
    }
  }
}

.swiper-scrollbar-2 {
  user-select: none;
  width: 440px;
  height: 440px;
  left: calc(50% - 220px);
  top: calc(50% - 220px);
  position: absolute;
  z-index: 3;
  background: url("/files/images/svg/circle.svg") center no-repeat;
  .swiper-scrollbar-drag-2 {
    width: 440px;
    height: 440px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: .5s;
    .swiper-line {
      background: url("/files/images/svg/line.svg") center left no-repeat;
      background-size: 87px 330px;
      width: 87px;
      height: 331px;
      transform: rotate(-3.2deg) translate(-4px, -2px);
    }
  }
}

.swiper-scrollbar {
  background: rgba(255,255,255,0.4);
  height: 2px;
  .swiper-scrollbar-drag {
    background: white;
    width: 32px!important;
  }
}

.swiper-container-horizontal > .swiper-scrollbar {
  display: none;
  position: absolute;
  left: 1%;
  bottom: 5%;
  z-index: 3;
  height: 2px;
  width: 50%;
}

.swiper-text {
  position: absolute;
  z-index: 3;
  height: 440px;
  width: 440px;
  top: calc(50% - 220px);
  left: calc(50% - 220px);
}

.swiper-pagination {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  position: absolute;
  left: 80px;
  bottom: 25px;
  z-index: 2;
}

@media all and (max-width: 1439px) {
  .slider-location {
    max-width: 850px;
  }
  .slider-stairs {
    .slick-slide {
      margin-top: 0;
    }
  }
  .swiper-text {
    width: 300px;
    height: 300px;
    left: calc(50% - 150px);
    top: calc(50% - 150px);
  }
}

@media all and (max-width: 1300px){
  .swiper-text {
    width: 280px;
    height: 280px;
    left: calc(50% - 140px);
    top: calc(50% - 50px);
  }
}

@media all and (max-width: 1168px) {
  .slider-location {
    max-width: 100%;
    margin-bottom: -45px;
    button {
      top: -24px;
      &.slick-prev {
        right: 88px;
      }
      &.slick-next {
        right: 40px;
        left: auto;
      }
    }
  }
}

@media all and (max-width: 940px) {
  .slider-location {
    max-width: initial;
    width: calc(100% + 80px);
    margin: 0 -40px -45px -40px;
  }
  .swiper-text {
    width: 280px;
    height: 280px;
    left: calc(50% - 140px);
    top: calc(50% - 50px);
  }
  .swiper-pagination {
    left: 40px;
  }
  .swiper-scrollbar-2 {
    display: none;
  }
}

@media all and (max-width: 700px) {
  .swiper-scrollbar-2 {
    display: none;
  }
  .swiper-text {
    height: 200px;
    top: calc(50% - 50px);
  }
  .slider-stairs, .slider-location {
    button {
      top: -24px;
      &.slick-prev {
        right: 68px;
      }
      &.slick-next {
        right: 20px;
      }
    }
  }
  .slider-location {
    margin: 0 -20px -20px;
    img {
      width: 100%;
    }
  }
  .slider-stairs {
    .slick-track {
      height: auto;
    }
  }
  .swiper-pagination {
    left: 20px;
  }
}


@media all and (max-height: 420px){
  .slider-stairs{
    max-height: 600px;
  }
  .slider-stairs .slick-track{
    height: 600px;
  }
}