@import "styles/variable";

.politics-title {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 155px 20px 90px;
  min-height: 405px;
  margin-top: -110px;
  h1 {
    color: white;
    max-width: 916px;
    margin: 0 auto;
    line-height: 1.25;
  }
}

.politics-content {
  @extend %center;
  padding: 30px 20px 67px;
  div {
    & > div:first-of-type{
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      margin-bottom: 25px;
      color: #1E324B;
    }
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 25px;
  }
  .back {
    margin-bottom: 30px;
  }
  ul {
    li {
      line-height: 24px;
      span {
        font-weight: 500;
      }
    }
  }
}

@media (max-width: 940px) {
  .politics-title h2 {
    font-size: 44px;
    line-height: 1.25;
  }
}

@media (max-width: 700px) {
  .politics-title h2 {
    font-size: 30px;
    line-height: 1.25;
  }
}
