@import "styles/variable";

.sorting-list {
  padding-left: 15px;
  display: flex;
  margin-top: 40px;
  padding-right: 110px;
  justify-content: flex-start;
  span {
    margin-right: 32px;
  }
  &__rooms {
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }
  &__area {
    width: 15%;
    display: flex;
    justify-content: flex-end;
  }
  &__floor {
    width: 12%;
    display: flex;
    justify-content: flex-end;
  }
  &__corpus {
    width: 15%;
    margin-right: 155px;
    display: flex;
    justify-content: flex-end;
  }
  &__price {
    width: 16%;
    text-align: left;
    display: flex;
    justify-content: flex-end;
  }
  &__select {
    padding-bottom: 14px;
    position: relative;
    border-bottom: 2px solid transparent;
    padding-right: 28px;
    cursor: pointer;
    margin-right: 20px;
    text-align: right;

    &:nth-last-of-type(1){
      margin-right: 0;
    }
    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      border: 2px solid $color-blue-dark;
      border-right-color: transparent;
      border-bottom-color: transparent;
      transform: rotate(225deg);
      right: 0;
      top: 13px;
      transform-origin: left top;
    }
    &--active {
      transition: .5s;
      font-weight: 600;
      border-color: $color-blue-dark;
      &--decs:after {
        transform: rotate(405deg);
        top: 4px!important;
      }
    }
  }
}

@media all and (max-width: 1000px){
  .sorting-list {
    font-size: 12px;
    padding-left: 0;
    &__select {
      &::after {
        width: 8px;
        height: 8px;
        border: 1px solid #1E324B;
        border-right-color: transparent;
        border-bottom-color: transparent;
        top: 9px;
      }
    }
    &__area {
      width: 18%;
    }
    &__price {
      width: 18%;
    }
    &__rooms {
      width: 16%;
    }
  }

}

@media all and (max-width: 700px){
  .sorting-list {
    display: none;
  }
}