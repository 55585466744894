@import "styles/variable";

.progress {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    &-item {
        width: 50%;
        &:nth-of-type(1){
            padding-right: 25px;
        }
    }
    &-cell {
        max-width: 640px;
        margin-left: auto;
        h1 {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }
    &__choice {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 0;
        margin-right: auto;
        margin-top: 70px;
    }
    &__date {
        position: relative;
    }
    &__select {
        border-radius: 0;
        border: 1px solid $color-blue-lighter;
        cursor: pointer;
        min-width: 300px;
        background: url("/files/images/progress/calendar.svg") right 19px center no-repeat;
        padding: 12px 16px;
        line-height: 24px;
        &:hover {
            border-color: #C3DCEE;
        }
    }
    &__calendar {
        border: 1px solid $color-blue-lighter;
        position: absolute;
        right: 0;
        z-index: -1;
        min-width: 300px;
        top: 70px;
        display: block;
        background: white;
        height: auto;
        opacity: 0;
        transition: 200ms;
        padding: 22px 14px 18px;

        &--active {
            opacity: 1;
            z-index: 2;
        }
        &-year {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid $color-blue-lighter;
            position: relative;
            &__arrow {
                padding: 10px 30px;
                width: 20px;
                height: 16px;
                background-size: cover;
                cursor: pointer;
                transition: .5s;
                position: absolute;
                top: calc(50% - 8px);
                &:hover {
                    transform: scale(1.1);
                }
                &--next {
                    right: 0;
                    background: url("/files/images/svg/arrow-right.svg") center no-repeat;
                }
                &--prev {
                    left: 0;
                    background: url("/files/images/svg/arrow-left.svg") center no-repeat;
                }
            }
            &__item {
                transition: 1s;
                font-size: 16px;
                padding: 12px 16px;
                opacity: 1;
                user-select: none;
            }
        }
        &-month {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            padding: 10px;
            &__item {
                text-align: center;
                width: 25%;
                transition: .5s;
                cursor: pointer;
                font-size: 16px;
                padding: 12px 16px;
                opacity: 1;
                border: 1px solid $color-blue-lighter;
                &--active {
                    background: $color-blue-dark;
                    color: white;
                }

                &:hover {
                    background: $color-blue-dark;
                    color: white;
                }
                &--disabled {
                    background: rgba(134, 134, 159, 0.1);
                    cursor: default;
                    &:hover {
                        background: rgba(134, 134, 159, 0.1);
                        color: #1E324B;
                    }
                }
                &:nth-of-type(12){
                    border-top: none;
                    border-left: none;
                }
                &:nth-of-type(11){
                    border-top: none;
                    border-left: none;
                }
                &:nth-of-type(10){
                    border-top: none;
                    border-left: none;
                }
                &:nth-of-type(9){
                    border-top: none;
                }
                &:nth-of-type(8){
                    border-top: none;
                    border-left: none;
                }
                &:nth-of-type(7){
                    border-top: none;
                    border-left: none;
                }
                &:nth-of-type(6){
                    border-top: none;
                    border-left: none;
                }
                &:nth-of-type(5){
                    border-top: none;
                }
                &:nth-of-type(4){
                    border-left: none;
                }
                &:nth-of-type(3){
                    border-left: none;
                }
                &:nth-of-type(2){
                    border-left: none;
                }
            }
        }
    }
    &-content {
        width: 100%;
        background: url("/files/images/progress/progress-bg.svg") top left no-repeat;
        background-size: auto 100%;
        overflow: hidden;
        padding-bottom: 151px;
        &__container {
            display: flex;
        }
        &__item {
            position: relative;
            opacity: 1;
            transition: 1s;
            top: 0;
            &--left {
                align-self: flex-start;
                margin-right: 20px;
                width: 351px;
                left: 0;
            }
            &--right {
                width: calc(100% - 371px);
                padding-top: 40px;
                right: 0;
            }
            &--start {
                &_left {
                    left: -100px;
                    top: 100px;
                    opacity: 0;
                }
                &_right {
                    right: -100px;
                    top: 100px;
                    opacity: 0;
                }
            }
        }
    }
    &-title {
        font-family: $font-geo;
        font-size: 40px;
        line-height: 1;
        font-weight: 300;
        margin-top: 34px;
        margin-bottom: 16px;
        text-transform: uppercase;
    }
    &-body{
      padding: 30px 32px 208px;
      background: #fff;
    }
    &-description {
      margin-right: 20px;
      max-height: 230px;
      overflow: hidden;
      position: relative;
      transition: all 0.3s linear;

        p {
            margin-bottom: 22px;
            &:nth-of-type(1) {
                font-weight: 600;
            }
        }

      &:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 150px;
        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in;
      }

      &--short{
        &:after{
          opacity: 1;
          visibility: visible;
        }
      }

      &._open{
        max-height: none;

        &:after{
          opacity: 0;
          visibility: hidden;
        }
      }

      &-toggle{
        margin-top: 26px;
        padding: 0;
        border: none;
        display: flex;
        align-items: center;
        text-transform: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        background: none;

        &:hover{
          text-decoration: underline;
          background: none;
        }

        &:after{
          content: url("/files/images/svg/toggle.svg");
          margin-left: 9px;
        }


        &._open{
          &:after{
            transform: scale(-1);
          }
        }
      }
    }
    &-list {
        list-style-type: none;
        li {
            line-height: 1.5;
            position: relative;
            &::before {
                content: '';
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #86869F;
                position: absolute;
                left: -15px;
                top: 10px;
            }
        }
    }
    &-slider {
        width: 150%;
        &__item:hover {
            cursor: pointer;
        }
        .slick-list {
            padding-bottom: 30px;
        }
        .slick-slide {
            transform: translateX(450px);
            position: relative;
            top: -10px;
            transition: top 1s ease-out 0.5s;
            &.slick-current {
                top: 10px;
                transition: top 1s ease-out 0.5s;
            }
        }
        button {
            top: 78px;
            &.slick-prev {
                left: calc(50% + 158px);
            }
            &.slick-next {
                left: calc(50% + 205px);
            }
        }
        img {
            max-width: 100%;
            padding-right: 20px;;
        }
    }
}
.live {
    width: 200px;
    height: 200px;
    display: block;
    position: absolute;
    background: url("/files/images/progress/live-camera.svg") center 75px  no-repeat #213E60;
    left: 0;
    bottom: -50px;
    padding: 18px 20px;
    font-size: 14px;
    color: #fff;
    line-height: 1.4;
    transition: 1s;
    filter: none;
    cursor: pointer;
    &:hover {
        filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 0.2));
        left: -10px;
        bottom: -40px;
    }
    &--up {
        bottom: -20px;
        &:hover {
            bottom: -10px;
        }
    }
    &--down {
        bottom: -80px;
        &:hover {
            bottom: -70px;
        }
    }
}
.aero {
    width: 175px;
    height: 175px;
    display: block;
    position: absolute;
    background: url("/files/images/progress/aero.svg") center 75px  no-repeat #C8A0AF;
    right: 0;
    bottom: -109px;
    padding: 18px 20px;
    font-size: 14px;
    color: #fff;
    line-height: 1.4;
    transition: 1s;
    filter: none;
    cursor: pointer;
    &:hover {
        filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 0.2));
        right: 10px;
        bottom: -99px;
    }
    &--up {
        bottom: -79px;
        &:hover {
            bottom: -69px;
        }
    }
    &--down {
        bottom: -139px;
        &:hover {
            bottom: -129px;
        }
    }
}

.calendar {
    background: #ffffff;
    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1);
    padding: 22px 14px 18px;
    &__month {
        display: flex;
        justify-content: center;
        font-size: 26px;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 8px;
        position: relative;
        span {
            user-select: none;
        }
    }
    &__arrow {
        width: 7px;
        height: 7px;
        border: 1px solid $color-blue-dark;
        border-bottom-color: transparent;
        border-left-color: transparent;
        transform: rotate(-135deg);
        cursor: pointer;
        position: absolute;
        top: 9px;
        transition: .5s;
        &--disable {
            cursor: default;
            opacity: 0;
        }
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
            transform: rotate(45deg);
        }
    }
    &__years {
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }
    &__year {
        font-size: 14px;
        padding: 0px 5px;
        cursor: pointer;
        &--active {
            color: $color-yellow-light;
            text-decoration: underline;
        }
    }
    &__days {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
    }
    &__day {
        padding: 5px 7px;
        width: calc(100% / 7);
        margin-bottom: 10px;
        cursor: pointer;
        text-align: center;
        &--weekday {
            font-weight: 500;
            cursor: default;
            user-select: none;
        }
        &--active {
            position: relative;
            color: #fff;
           &::before {
               content: '';
               position: absolute;
               width: 24px;
               height: 24px;
               border-radius: 50%;
                background: $color-yellow-light;
                top: 0;
                left: calc(50% - 12px);
                z-index: -1;
           }
        }
        &--disable{
            opacity: .4;
            pointer-events: none;
        }
    }
}

.show {
    display: block;
}

@media all and (max-width: 1439px) {
    .progress {
        &-cell {
            max-width: 548px;
            padding-left: 40px;
        }
        &__choice {
            padding-left: 0;
            padding-right: 40px;
        }
        &-slider {
            button {
                &.slick-next {
                    left: calc(50% + 105px);
                }
                &.slick-prev {
                    left: calc(50% + 58px);
                }
            }
            .slick-slide{
                transform: translateX(-353px);
            }
        }
    }
}

@media all and (max-width: 1200px){
    .progress {
        &-content {
            &__item {
                &--left {
                    width: 35%;
                }
                &--right {
                    width: 65%;
                }
            }
        }
        &-slider {
            width: 100%;
            .slick-slide {
                transform: translateX(0);
            }
            .slick-slide.slick-current {
                top: 0;
            }
            button {
                top: -18px;
                &.slick-next {
                    right: auto;
                    left: 48px;
                }
                &.slick-prev {
                    right: auto;
                    left: 0;
                }
            }
        }
    }
}

@media all and (max-width: 1168px) {
    .progress {
        flex-wrap: wrap;
        &-title {
            font-size: 32px;
        }
        &-item {
            width: 100%;
        }
        &-body{
          padding-bottom: 190px;
        }
        &-cell {
            max-width: 100%;
            padding: 0 40px;
            h1 {
                margin-bottom: 20px;
            }
        }
        &__choice {
            padding: 0 40px;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
    .aero {
        right: -40px;
    }
}

@media all and (max-width: 940px) {
    .progress {
        &-content {
            padding-bottom: 60px;
            &__container {
                flex-wrap: wrap;
            }
            &__item {
                width: 100%;
            }
        }
        &-body{
          padding: 30px 32px;
        }
        &-links  {
          margin-top: 40px;
            display: flex;
            flex-wrap: wrap;
        }
        &-list {
          margin-bottom: 0;
            width: 100%;
        }
    }
    .live {
        position: static;
        margin-right: 20px;
    }
    .aero {
        position: static;
    }
}

@media all and (max-width: 700px) {
    .progress {
        &-cell {
            padding: 0 20px;
            h1 {
                margin-top: 0;
            }
        }
        &__choice {
            flex-wrap: wrap;
            margin-bottom: 5px;
        }
        &__switcher {
            margin-bottom: 15px;
        }
        &__date {
            margin-bottom: 15px;
        }
        &-title {
            font-size: 26px;
        }
        &-slider img {
            padding-right: 0;
        }
    }
    .live {
        margin: 0 auto 15px;
        width: 120px;
        height: 120px;
        padding: 10px;
        background-position: right 10px bottom 10px;
        background-size: 60px;
    }
    .aero {
        width: 120px;
        height: 120px;
        margin: 0 auto;
        padding: 10px;
        background-position: right 10px bottom 10px;
        background-size: 90px;
    }

    .progress__calendar {
      width: 100%;
    }
}

@media all and (max-width: 500px) {
    .progress__date {
        width: 100%;
    }
}
