@import 'styles/variable';

.view-more {
  position: relative;
  right: 0;
  opacity: 1;
  transition: 1s;

  margin-top: 80px;
  margin-bottom: 120px;

  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 11px;
    width: 90%;
    height: 83%;
    background-color: #F3F3F6;
    z-index: -1;
  }

  &--start-right {
    right: -100px;
    opacity: 0;
  }

  &__wrapper {
    padding-top: 60px;
  }

  &__inner {
    margin-bottom: 20px;
  }

  &__title {
    margin-bottom: 20px;

    font-size: 40px;
    line-height: 51px;
    text-transform: uppercase;
    letter-spacing: -0.03em;
  }

  &__description {
    max-width: 500px;
  }
}

@media all and (max-width: 940px) {
  .view-more {
    margin-top: 35px;
    margin-bottom: 0px;

    &::before {
      display: block;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@media all and (max-width: 700px) {
  .view-more {
    &__title {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0;
    }
  }
}

@media all and (max-width: 420px) {
  .view-more {
    &__wrapper {
      padding-top: 25px;
    }
  }
}
