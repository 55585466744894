.promo-slider {
  height: 100%;
  .slick-dots {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    position: absolute;
    left: 81px;
    bottom: 25px;
    li {
      margin-bottom: 0;
      margin-right: 8px;
      &.slick-active {
        button {
          opacity: 1;
        }
      }
    }
    button {
      font-size: 0;
      height: 2px;
      width: 32px;
      background: #fff;
      padding: 0;
      border: 0;
      opacity: .4;
    }
  }
}
.promo-slider__item {
  position: relative;
}

.promo-slider__pic {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  img {
    width: auto;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
  }
}

.promo-slider__glass {
  position: absolute!important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.promo-slider__glass-slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 640px;
    backdrop-filter: blur( 0.1px );
    position: absolute;
    top: calc(50% - 320px);
    left: calc(50% - 320px);
    transition: 1s;
    &.active {
      transition: 1s;
      background: rgba( 255, 255, 255, 0 );
      box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
      backdrop-filter: blur( 3.0px );
      -webkit-backdrop-filter: blur( 3.0px );
    }
  }
  .promo-slider__pic-img {
    clip-path: circle(320px at 50% 50%);
    transition: clip-path .1s;
    width: auto;
    min-height: 100%;
    min-width: 100%;
    &--active {
      clip-path: circle(321px at 50% 50%);
    }
  }

}

.promo-slider__text {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 300px;
  min-height: 300px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 23px;
  font-weight: 300;
}

.promo-slider__bullet {
  font-size: 0;
  height: 20px;
  width: 32px;
  padding: 0;
  border: 0;
  opacity: .4;
  cursor: pointer;
  margin-right: 8px;
  position: relative;
  &:after {
    content: "";
    height: 2px;
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    top: 9px;
    left: 0;
  }
  &.active {
    opacity: 1;
  }
}

@media all and (max-width: 1439px){
  .promo-slider__text {
    font-size: 16px;
    width: 300px;
    height: 300px;
  }
}

@media all and (max-width: 1300px){
  .promo-slider__text {
    width: 280px;
    height: 280px;
    left: calc(50% - 140px);
    top: calc(50% - 140px);
  }
}

@media all and (max-width: 1168px) {
  .img {
    height: 76%;
    top: -85px !important;
    transform: rotate(85deg);
    left: 38.5% !important;
  }
}

@media all and (max-width: 940px) {
  .promo-slider {
    min-height: 491px;
  }
  .promo-slider__glass {
    min-height: 491px;
  }
  .promo-slider__pic {
    img {
      max-height: 491px;
      min-width: auto;
    }
  }
  .promo-slider__text {
    width: 100%;
    height: 100%;
    left: 0;
    top: 10%;
  }
  .promo-slider__glass-slide {
    .promo-slider__pic-img {
      clip-path: circle(200px at 50% 50%);
      transition: clip-path .1s;
      width: auto;
      min-height: 100%;
      min-width: 100%;
      &--active {
        clip-path: circle(201px at 50% 50%);
      }
    }
  }
}

@media all and (max-width: 700px) {
  .promo-slider__text {
    //background: rgba(30, 50, 75, 0.61);
    padding: 20px;
    top: auto;
  }
  .img {
    display: none!important;
  }
  .promo-slider__pic {
    display: flex;
    justify-content: center;
    overflow: hidden;
    img {
      width: auto;
    }
  }
  .promo-slider__glass-slide {
    .promo-slider__pic-img {
      clip-path:none;
      transition: .1s;
      &--active {
        clip-path: none;
      }
    }
  }
}

@media all and (max-height: 937px) {
  .promo-slider__pic img{
    height: 100%;
  }
  .promo-slider__glass-slide .promo-slider__pic-img{
    height: 100%;
  }
}

@media all and (max-height: 768px) {

  .promo-slider__pic img{
    height: calc(100% + 66px);
  }
  .promo-slider__glass-slide .promo-slider__pic-img{
    height: calc(100% + 66px);
  }
}