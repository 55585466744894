@import "styles/variable";

.infrastructure {
  h1 {
    text-transform: uppercase;
    margin-top: 26px;
    margin-bottom: 41px;
  }

  &__title {
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 16px;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
  }

  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }

  &-section {
    display: flex;
    flex-wrap: wrap;

    &--one {
      padding-bottom: 60px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 480px;
        width: 100%;
        top: 205px;
        left: 0;
        background: rgba(134, 134, 159, 0.1);
      }

      .infrastructure-item:nth-of-type(2) {
        padding-bottom: 69px;
      }
    }

    &--three {
      padding-bottom: 97px;
      margin-bottom: 60px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 66%;
        top: 0;
        right: 0;
        background: rgba(134, 134, 159, 0.1);
      }
    }

    &--two {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        height: 240px;
        width: 66%;
        bottom: 0;
        right: 0;
        background: rgba(134, 134, 159, 0.1);
      }
    }
  }

  &-banner {
    width: 100%;
    display: block;
    margin-top: -138px;
    margin-bottom: 60px;
    opacity: 1;
    transition: 1s;

    &--start {
      opacity: 0;
    }
  }

  &__pic {
    display: block;
    width: 100%;
  }

  &-item {
    width: 50%;
    display: flex;
    align-items: center;
    position: relative;
    left: 0;
    opacity: 1;
    transition: 1s;
    z-index: 2;

    &--start-left {
      left: -100px;
      opacity: 0;
    }

    &--start-right {
      left: 100px;
      opacity: 0;
    }
  }

  &-cell {
    max-width: 649px;
    width: 100%;

    p {
      width: 100%;
      white-space: normal;
    }

    &--left {
      margin-left: auto;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    &--left {
      padding-right: 65px;
    }

    &--right {
      padding-left: 65px;
    }

    &__title {
      font-size: 40px;
      line-height: 52px;
      margin-bottom: 26px;
      text-transform: uppercase;
    }

    &__subtitle {
      margin-top: 16px;
      padding-right: 81px;

      p:last-child:not(:first-child) {
        font-size: 24px;
        line-height: 30px;
      }
    }

    ul {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.map {
  position: relative;
  overflow: hidden;
  margin-bottom: 70px;
  z-index: 1;

  &-min {
    min-height: 660px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    z-index: 401;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  }

  &__wrapper {
    width: 100%;
    height: 100%;
  }

  &__block {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  &__pic {
    img {
      display: block;
      width: 100%;
    }
  }

  &__button {
    position: absolute;
    width: 48px;
    height: 48px;
    right: 26px;
    border: 0.5px solid rgba(33, 62, 96, 0.21);
    cursor: pointer;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.06));
    z-index: 401;
    font-size: 0;

    &--in {
      top: 26px;
      background: url("/files/images/infrastructure/plus.svg") center no-repeat, #fff;
    }

    &--out {
      top: 73px;
      background: url("/files/images/infrastructure/minus.svg") center no-repeat, #fff;
    }

    &--customization {
      top: 146px;
      background: url("/files/images/infrastructure/gear.svg") center no-repeat, #fff;
    }

    &:hover {
      background-color: $color-blue-lightest;
    }
  }

  &-aside {
    position: absolute;
    height: 100%;
    width: 330px;
    padding: 53px 50px;
    right: -350px;
    top: 0;
    background: #fff;
    box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.06);
    z-index: 1000;
    opacity: 0;
    transition: 1s ease-in-out;

    &__overlay{
      display: none;
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      @media (max-width: 1168px){
        display: block;
      }
    }

    &--visible {
      right: 0;
      opacity: 1;
      transition: 1s ease-in-out;
    }

    &__title {
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 15px;
    }

    &__kind {
      padding: 8px 0 8px 52px;
      background-position: 5px center;
      background-repeat: no-repeat;
      margin-bottom: 10px;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

      &--active {
        opacity: 1;
      }
    }

    &__close {
      position: absolute;
      width: 17px;
      height: 17px;
      right: 30px;
      top: 30px;
      cursor: pointer;
      z-index: 3;

      &::before {
        content: '';
        width: 2px;
        height: 22px;
        position: absolute;
        transform: rotate(45deg);
        background: #213E60;
        right: 8px;
        top: -3px;
      }

      &::after {
        content: '';
        width: 2px;
        height: 22px;
        position: absolute;
        transform: rotate(-45deg);
        background: #213E60;
        right: 8px;
        top: -3px;
      }
    }
  }
}


@media all and (max-width: 1439px) {
  .map {
    &-aside {
      padding: 30px;

      &__title {
        margin-bottom: 10px;
      }
    }
  }

  .infrastructure {
    &-cell {
      max-width: 504px;
    }
  }

  .infrastructure-text__subtitle {
    padding-right: 0;
  }
}

@media all and (max-width: 1168px) {
  .map {
    display: flex;
    flex-wrap: wrap;

    &__info {
      &--departure {
        right: 30%;
      }

      &--embankment {
        right: 6.5%;
      }

      &--complex {
        right: 25.5%;
      }
    }

    &__complex {
      width: 4.2%;
      height: 10%;

      &--min {
        width: 10.8%;
        height: 23%;
      }
    }

    &-aside {
      //position: relative;
      width: 100%;
      height: auto;
      top: auto;
      bottom: 0;
      right: 0;
      transform: translateY(100%);

      &__section {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding: 20px 20px 0 0;
      }

      &__kind {
        margin-right: 35px;
        margin-bottom: 15px;
      }

      &--visible {
        transform: translateY(0);
      }
    }

    &__building {
      &--one {
        left: 47.6%;
        top: 26.7%;
      }

      &--two {
        top: 41.6%;
      }
    }
  }

  .infrastructure {
    &-cell {
      max-width: calc(100% - 40px);
    }
  }
}

@media all and (max-width: 940px) {
  .map {
    margin-bottom: 35px;

    &__block {
      height: 660px;
    }

    &__wrapper {
      width: 940px;
      position: absolute;
    }

    &__point {
      width: 32px;
      height: 32px;
      padding: 10px 0;
    }

    &__info {
      &--departure {
        right: 28%;
      }

      &--embankment {
        right: 5.5%;
      }

      &--departure {
        right: 31.5%;
      }
    }

    &__text {
      font-size: 14px;
      margin-right: 0;
    }

    &__bubble {
      img {
        width: 100%;
      }
    }

    &__info {
      &--complex {
        width: 70px;
        right: 26.5%;
      }
    }

    &-aside {
      &__close {
        right: 10px;
        top: 10px;
      }
    }
  }

  .infrastructure {
    &-section{
      flex-wrap: nowrap;
    }
    &-item{
      width: auto;
    }
    &__title {
      font-size: 48px;
      line-height: 58px;
    }

    &-text {
      padding-top: 15px;
      padding-bottom: 15px;
      min-width: 430px;

      &__title {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 18px;
      }

      &__subtitle {
        font-size: 18px;
        line-height: 24px;
      }

      &--right {
        padding-left: 35px;
      }

      &--left {
        padding-right: 35px;
      }
    }

    &-banner {
      margin-top: 0;
    }
  }

  .infrastructure-section--one:before,
  .infrastructure-section--three:before{
    top: 0;
    left: -40px;
    height: calc(100% - 60px);
    width: calc(100% + 80px);
  }

  .infrastructure-section--two:before{
    display: none;
  }
  .infrastructure-cell--left{
    margin-left: 0;
  }
}

@media all and (max-width: 700px) {
  .infrastructure {
    h1 {
      margin-top: 0;
    }

    &__pic {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }

    &-item {
      width: 100%;
      align-items: flex-start;
    }

    &-cell {
      max-width: none;
      margin: 0;
    }

    &-text {
      padding: 50px 0;
      min-width: auto;

      &__title {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-section {
      &--one {
        padding-bottom: 0;
      }

      &--two, &--three, &--five {
        padding: 0;
      }

      &--one .infrastructure-item:nth-of-type(2) {
        padding-bottom: 0;
      }

      &--one:before, &--two:before, &--four:before, &--four:before {
        content: none;
      }
    }

    &__title {
      font-size: 28px;
      line-height: 32px;
      text-align: left;
    }
  }
  .map {
    &__info {
      &--departure {
        right: 9%;
      }

      &--embankment {
        right: 0.5%;
      }

      &--metro {
        left: 30%;
      }

      &--complex {
        right: 24.5%;
      }
    }

    &__wrapper {
      width: 700px;
    }

    &__bubble {
      padding: 8px;
    }

    &__text {
      font-size: 12px;
    }

    &-aside {
      padding: 20px;

      &__section {
        margin: 0 15px 15px 0;
        padding: 0;
      }
    }

    &__symbol {
      width: 40px;
      height: 40px;
    }

    &__picto {
      width: 50%;
    }

    &__img {
      width: 60px;
      left: calc(50% - 30px);
      top: calc(50% - 30px);
    }

    &__name {
      top: -47px;
      font-size: 12px;
    }

    &-min {
      .map__info {
        &--embankmentmin {
          right: 12.5%;
        }
      }
    }
  }
  .infrastructure-section {
    flex-direction: column-reverse;
  }
  .infrastructure-section--one, .infrastructure-section--two, .infrastructure-section--five {
    flex-direction: column;
  }
  .infrastructure-section--three{
    margin-bottom: 0;
  }
  .infrastructure-section--three:before {
    display: none;
  }
}

@media all and (max-width: 550px) {
  .map-aside {
    position: fixed;
  }
  .map-aside__section{
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 50vh;
  }
  .map-aside__kind{
    width: 100%;
  }
}

@media all and (max-width: 430px) {
  .infrastructure {
    h1 {
      font-size: 28px;
    }
  }
}

@media all and (max-height: 420px) {
  .map-aside {
    bottom: auto;
    top: 0;
    width: 75%;
    height: 100%;
    transform: translateX(100%);
  }
  .map-aside--visible {
    transform: translateX(0);
  }
}
