@import 'slick.css';
@import 'swiper/swiper';
@import 'swiper/components/effect-fade/effect-fade';

@import 'base/_fonts';
@import 'common/_reset';
@import 'common/_typography';
@import 'common/_common';
@import 'common/_common-adaptive';
@import 'blocks/_purchase';
@import 'blocks/_documents';
@import 'blocks/_mortgage';
@import 'blocks/_offer';
@import 'blocks/_installment-plan';
@import 'blocks/_developer';
@import 'blocks/_politics';
@import 'blocks/_commercial';
@import 'blocks/_finish';
@import 'blocks/_contacts';
@import 'blocks/_news';
@import 'blocks/_gallery';
@import "blocks/_favorites";
@import "blocks/_compaire";
@import "blocks/_about";
@import "blocks/_underground";
@import "blocks/_visual-choice";
@import "blocks/_main";
@import "blocks/_choice";
@import "blocks/_infrastructure";
@import "blocks/_progress";
@import "blocks/_apartment";
@import "blocks/_improvement";
@import 'blocks/_locations';
@import 'blocks/_view-more';
@import 'blocks/_roominess';
@import 'blocks/_location';
@import "blocks/_lobby";
@import "blocks/_architecture";
@import "components/main/promo/_promo";
@import "components/main/promo/_promoSlider";
@import "components/layout/_hidden";
@import "components/layout/_layout";
@import "components/main/_main";
@import "components/progress/_progress";
@import "components/catalog/_catalog";
@import "components/catalog/Filters/_filters";
@import "components/catalog/Filters/range/_rangeFilter";
@import "components/catalog/Filters/btnCircle/_btnCircle";
@import "components/catalog/Info/_info";
@import "components/catalog/List/_catalogList";
@import "components/catalog/List/_listItem";
@import "components/general/modal/_shareLink";
@import "components/catalog/Sorting/_sorting";
@import "components/catalog/Tile/_TileItem";
@import "components/catalog/Tile/_Tile";
@import 'customSlider';