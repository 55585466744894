@import "styles/variable";

.list-item {
  display: flex;
  padding: 10px 36px;
  align-items: center;
  background: rgba(231, 231, 236, 0.2);
  transition: .5s;
  font-size: 22px;
  font-weight: 400;
  color: $color-blue-dark;
  margin-bottom: 8px;
  position: relative;
  &:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  &__link {
    display: flex;
    justify-content: flex-start;
    color: $color-blue-dark;
    width:100%;
    font-size: 22px;
    font-weight: 400;
    padding-right: 60px;
  }
  &--sale {
    background: linear-gradient(163.5deg,hsla(47,48%,75%,.5),hsla(47,48%,75%,0) 17.02%),#fafafb;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      height: 30px;
      background: url("/files/images/choice/sale.svg") center / cover no-repeat;
    }
  }
  &:hover {
    background: $color-gray-lighter;
  }
  &__pic {
    width: 25.5%;
  }
  &__characteristics {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  &__characteristic {
    text-align: center;
    &--rooms {
      width: 21%;
      margin-right: 15px;
      span {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        text-align: center;
        font-size: 22px;
        padding: 14px 0;
        background: #fff;
        display: block;
      }
    }

    &--area {
      font-size: 30px;
      width: 20%;
      margin-right: 10px;
      span {
        font-size: 16px;
      }
    }
    &--floor {
      width: 17%;
      margin-right: 22px;
    }
    &--building {
      width: 10%;
      margin-right: 20px;
    }
    &--price {
      font-size: 30px;
      width: 31%;
      &.sale {
        color: rgba(198, 177, 102, 1);
        position: relative;
      }

    }
  }
  &__old-price {
    position: absolute;
    text-decoration: line-through;
    font-size: 16px;
    top: -80%;
    right: 40%;
    color: $color-blue-dark;
  }
  &__pic {
    svg {
      max-height: 70px;
      max-width: 120px;
    }
    img {
      max-height: 70px;
    }
  }
  &__svg {
    svg {
      max-width: 100px;
      max-height: 70px;
    }
  }
  &__block {
    display: none;
    flex-direction: column;
  }
  &__cell {
    font-size: 12px;
    min-width: 130px;
    margin-right: 10px;
  }

  &__hint {
    position: absolute;
    z-index: 99;
    bottom: calc(100% + 8px);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 12px;
    line-height: 120%;
    padding: 8px;
    background: $color-blue-dark;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;

    @media all and (max-width: 700px) {
      left: auto;
      right: 0;
      transform: none;
    }
  }

  &__chosennes {
    cursor: pointer;
    width: 22px;
    height: 20px;
    margin-left: 30px;
    background: url("/files/images/svg/favorite-card.svg") center center / cover no-repeat;
    position: relative;
    flex-shrink: 0;
    &--active {
      background: url("/files/images/svg/favorite-card-active.svg") center center / cover no-repeat;
    }
    @media (hover: hover) {
      &:hover {
        .list-item__hint {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
  &__compare {
    cursor: pointer;
    width: 24px;
    height: 24px;
    margin-left: 20px;
    background: url("/files/images/svg/to-compaire.svg") center center / 100% no-repeat;
    position: relative;

    .list-item & {
      width: 32px;
      height: 32px;
    }

    &--active {
      background: url("/files/images/svg/in-compaire.svg") center center / 100% no-repeat;
    }
    @media (hover: hover) {
      &:hover {
        .list-item__hint {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  .flat-card__price-shield{
    transform: none;
  }
  .list-item__discounts{
    margin-right: 28px;
    width: 204px;
    flex-shrink: 0;
  }
}

@media all and (max-width: 1440px) {
  .list-item__pic {
    width: 19%;
  }
  .list-item__characteristic--floor {
    width: 26%;
    margin-right: 22px;
  }
  .list-item__characteristic--area {
    font-size: 24px;
    width: 22%;
    margin-right: 10px;
  }
  .list-item__old-price {
    right: 18%;
  }
  .list-item__characteristic--price {
    padding-left: 4%;
    font-size: 24px;
  }
  .list-item .list-item__discounts{
    margin-right: 0;
  }
}

@media all and (max-width: 1100px) {
  .list-item__old-price {
    right: 35px;
  }
}

@media all and (max-width: 1000px){
  .list-item__characteristic {
    &--building {
      display: none;
    }

    &--rooms {
      width: 20%;
      span {
        width: 38px;
        height: 38px;
        font-size: 18px;
      }
    }

    &--area {
      font-size: 20px;
      white-space: nowrap;
      width: 25%;

      span {
        font-size: 14px;
      }
    }

    &--floor {
      position: relative;
      left: 42px;
      width: 11%;
      font-size: 18px;
    }

    &--price {
      padding-left: 10%;
      font-size: 18px;
      white-space: nowrap;
    }
  }
  .list-item__old-price {
    font-size: 12px;
  }
  .list-item__pic {
    width: 24%;
  }
  .list-item__old-price {
    right: auto;
    top: -100%;
  }
}

@media all and (max-width: 700px){
  .list-item {
    padding: 10px;
    &--sale {
      &::before {
        width: 20px!important;
        height: 20px!important;
      }
      .list-item__characteristic {
        &--price {
          margin-top: 20px;
        }
      }
      .list-item__old-price {
        right: auto;
        left: 0;
      }
    }
    &__cell {
      min-width: 95px;
    }
    &__block {
      display: flex;
    }
    &__link {
      align-items: center;
    }
    &__pic {
      margin-right: 10px;
      width: 50%;
      img {
        max-height: 50px;
      }
    }
    &__characteristic {
      &--rooms {
        display: none;
      }
      &--area {
        display: none;
      }
      &--floor {
        display: none;
      }
      &--building {
        display: none;
      }
      &--price {
        font-size: 14px;
      }
    }
  }
}

@media all and (max-width: 500px){
  .list-item__chosennes {
    margin-left: 0;
  }
}

@media all and (max-width: 450px){
  .list-item__link {
    flex-wrap: wrap;
  }
  .list-item__pic {
    width: calc(50% - 10px);
  }
  .list-item__characteristic--price {
    width: 100%;
  }
  //.list-item__block {
  //  margin-bottom: 15px;
  //}
  .list-item__svg svg {
    max-width: 150px;
    max-height: 110px;
  }
}

@media all and (max-width: 360px){
  .list-item__cell {
    min-width: 105px;
  }
}
