@import "styles/variable";

.apartment {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    h1 {
        font-size: 40px;
        line-height: 44px;
        max-width: 360px;
        margin-bottom: 25px;
    }
    .back {
        margin-bottom: 15px;
        &__link {
            text-transform: initial;
        }
    }

    &-text {
        text-align: center;
        margin-top: 30px;
        margin-bottom: -30px;
        color: #9098A5;
    }

    &-sale {
        background: #c6b166;
        &::before {
            content: '';
            position: absolute;
            width: 45px;
            height: 45px;
            background: url("/files/images/choice/sale-detail.svg");
            left: 0;
            top: 0;
        }
    }
    &-item {
        opacity: 1;
        transition: 1s;
        &--left {
            width: 62%;
            .apartment-cell {
                max-width: 793px;
                margin-left: auto;
            }
        }
        &--right {
            width: 38%;
            background: #0f1c2c;
            margin-top: -110px;
            padding-top: 163px;
            .apartment-cell {
                max-width: 487px;
                margin-right: auto;
                position: relative;
                margin-bottom: 93px;
            }
        }
        &--start {
            &_left {
                opacity: 0;
            }
            &_right {
                opacity: 0;
            }
        }
    }
    &__title {
        display: flex;
        justify-content: space-between;
    }
    &__scheme {
        padding-top: 54px;
    }
    &__outside {
        display: flex;
        justify-content: space-between;
        width: 277px;
        height: 100px;
    }
    &__signature {
        position: absolute;
        color: #000;
        opacity: 0.3;
        width: 100%;
        text-align: center;
        &--top {
            top: -40px;
        }
        &--bottom {
            bottom: -40px;
        }
        &--left {
            transform: rotate(-90deg);
            right: calc(100% - 65px);
            transform-origin: right bottom;
        }
        &--right {
            transform: rotate(-90deg);
            top: 0;
            right: -140px;
            transform-origin: right bottom;
        }
    }
    &__pic {
        padding-right: 170px;
        display: none;
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
        &--active {
            display: block;
        }
        &--relative {
            position: relative;
        }
    }
    &-svg {
        position: relative;
    }
    &__points {
        margin-top: 70px;
        background: rgba(134, 134, 159, 0.1);
        display: flex;
        padding: 67px 80px 48px 80px;
        max-width: 629px;
    }
    &__point {
        font-size: 14px;
        color: #213E60;
        padding: 5px;
        cursor: pointer;
        margin-right: 20px;
        &--active {
            color: $color-yellow-light;
            border-bottom: 1px solid $color-yellow-light;
        }
        &:hover {
            opacity: .8;
        }
        &:nth-last-of-type(1){
            margin-right: 0;
        }
    }
    &__data {
        padding-left: 72px;
    }
    &__spec {
        padding-left: 0;
        margin-bottom: 30px;
        margin-right: 95px;
        li {
            color: #fff;
            display: flex;
            align-items: center;
            p {
                margin-bottom: 0;
                white-space: nowrap;
                &:first-of-type {
                    min-width: 160px;
                }
                span {
                    font-size: 30px;
                }
            }
            > span {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                padding: 0 5px;
                text-align: center;
                border: 1px solid $color-yellow-light;
                font-size: 22px;
                line-height: 42px;
            }
        }
    }
    &__more {
        border-top: 1px solid rgba(135, 135, 160, 0.24);
        padding-top: 30px;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 60px;
        margin-right: 95px;
        .item {
            color: rgba(255, 255, 255, 0.64);;
            font-size: 14px;
            text-align: center;
            max-width: 100px;
            width: 100%;
            img {
                display: block;
                margin: 0 auto 15px;
                max-height: 60px;
                height: 100%;
                transition: 1s;
            }
        }
    }
    &__action {
        display: flex;
        flex-wrap: wrap;
        width: 606px;
        position: relative;
        opacity: 1;
        transition: 1s;
        left: -180px;
        bottom: 0;
        margin-bottom: 40px;
        &--start {
            opacity: 0;
            left: -175px;
            bottom: -60px;
        }
    }
    &__price {
        width: 100%;
        display: flex;
        padding: 21px 67px;
        background: $color-blue-dark;
        justify-content: space-between;
        align-items: center;
        p {
            font-size: 32px;
            color: #fff;
            text-align: right;
            white-space: nowrap;
        }
        div {
            >span {
                font-size: 12px;
                color: #fff;
            }
        }
        button {
            padding: 16px 22px;
            text-transform: uppercase;
            background: linear-gradient(264.06deg, #D4C48A -8.36%, #DED1A2 61.09%, #C6B166 107.6%);
            border: 1px solid rgba(255, 255, 255, 0.5);
            color: #0F1C2C;
            font-weight: 500;
            margin-left: 36px;
            height: auto;
            font-size: 16px;
            &:hover {
                background: linear-gradient(264.06deg, #F1E4B4 -8.36%, #F9EBB9 61.09%, #F1E4B4 107.6%);
            }

        }
    }
    &__link {
        width: 303px;
        //width: 606px;
        cursor: pointer;
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        span {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg,rgba(30,50,75,0.9) 0,rgba(30,50,75,0) 100%);
            color: $color-yellow-light;
            text-align: center;
            padding-top: 15px;
            font-size: 14px;
            transition: background 0.5s ease,
                        height 0.5s ease;
        }
        &:hover {
            span {
                height: 106px;
                background: linear-gradient(180deg,rgba(30,50,75,0.7) 0,rgba(30,50,75,0) 100%);
            }
        }
    }
    &__call {
        position: absolute;
        width: 64px;
        height: 64px;
        background: url("/files/images/apartment/icon-call.svg") center no-repeat $color-blue-dark;
        right: -32px;
        bottom: -32px;
        border-radius: 50%;
        cursor: pointer;
    }
    &__info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        color: #fff;
        a {
            color: #fff;
            padding: 12px 20px;
            &:hover {
                color: $color-yellow-light;
            }
        }
        .share {
           cursor: pointer;
           padding: 12px 48px 12px 20px;
           color: #fff;
           background: url("/files/images/apartment/share.svg") right 11px center no-repeat;
            transition: .5s;
            &:hover {
                color: $color-yellow-light;
            }
        }
        .pdf {
            padding: 12px 20px;
            cursor: pointer;
            color: #fff;
            transition: .5s;
            &:hover {
                color: #DED1A2;
            }
        }
    }
    &__calculation {
        margin-top: 50px;
        opacity: 1;
        transition: 1s;
        position: relative;
        top: 0;
        &--start {
            opacity: 0;
            top: 30px;
        }
    }
    &__result {
        opacity: 1;
        transition: 1s;
        position: relative;
        right: 0;
        &--start {
            opacity: 0;
            right: -200px;
        }
    }
    &-popup {
        background: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: -1;
        transition: .5s;
        display: flex;
        justify-content: center;
        align-items: center;
        &--active {
            opacity: 1;
            z-index: 111;
        }
    }
    &-sharing {
        height: auto;
        width: 100%;
        background: #EEF3F6;
        position: relative;
        max-width: 550px;
        border-radius: 5px;
        margin: 20px;
        &__title {
            border-radius: 5px;
            color: white;
            padding: 20px;
            background: #1E324B;
            display: flex;
            font-family: "Geometria", Arial, sans-serif;
            font-size: 30px;
            font-weight: 300;
            justify-content: space-between;
            align-items: center;
        }
        &__close {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            background: #fff;
            border-radius: 50%;
            position: relative;
            cursor: pointer;
            transition: 0.5s;
            &::before {
                transition: 1s;
                content: "";
                position: absolute;
                width: 16px;
                height: 1px;
                top: 23px;
                left: 16px;
                background: #1E324B;
                transform: rotate( -45deg);
            }
            &::after {
                transition: 1s;
                content: "";
                position: absolute;
                width: 16px;
                height: 1px;
                top: 23px;
                left: 16px;
                background: #1E324B;
                transform: rotate(45deg);
            }
        }
        &__list {
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__item {
            margin: 20px;
            img {
                width: 40px;
                height: 40px;
            }
            span {
                font-size: 0;
                line-height: 0;
            }
        }
    }
    &-mark {
      margin: -4px 0 0 -4px;
      padding: 4px;
      background: rgba(256, 256, 256, 0.6);
      border-radius: 4px;
    }
}

@media all and (max-width: 1439px) {
.apartment {
    &-item {
        &--left {
            .apartment-cell {
                max-width: 724px;
                padding-left: 40px;
            }
        }
        &--right {
            .apartment-cell {
                max-width: 444px;
            }
        }
    }
    &__info a {
        padding: 12px 16px;
    }
}
}

@media all and (max-width: 1168px) {
.apartment {
    flex-wrap: wrap;
    h1 {
        max-width: calc(100% - 300px);
    }
    &-item {
        width: 100%;
        .apartment-cell {
            max-width: 100%;
            padding: 0 40px;
        }
        &--right {
            padding-top: 0;
            width: calc(100% - 80px);
            margin: 0 auto;

            .apartment-cell {
                padding-bottom: 40px;
            }
        }
    }
    &__scheme {
        padding-top: 0;
    }
    &__pic {
        width: 100%;
        margin: 50px auto;
        padding: 0;
        img {
            max-width: 100%;
            margin: 0 auto;
            display: block;
        }
    }
    &__outside {
        width: 200px;
        img {
            &:first-of-type {
                width: 50%;
                height: auto;
            }
            &:nth-last-of-type(1) {
                width: 40%;
                height: auto;
            }
        }
    }
    &__signature {
        &--right {
            right: 0;
        }
        &--left {
            right: 100%;
        }
    }
    &__points {
        margin: 0 auto 15px;
    }
    &__data {
        padding: 40px 0 0;
        display: flex;
        align-items: flex-start;
    }
    &__spec {
        width: 30%;
        margin-right: 10px;
    }
    &__action {
        position: static;
        width: 40%;
        flex-grow: 1;
    }
    &__more {
        border-top: 0;
        margin-right: 10px;
        width: 20%;
        padding-top: 0;
        flex-wrap: wrap;
        .item {
            &:first-of-type {
                margin-bottom: 15px;
            }
        }
    }
    &__price {
        flex-wrap: wrap;
        padding: 20px;
        justify-content: center;
        div {
            margin-bottom: 15px;
        }
        p {
            text-align: center;
        }
        a {
            margin-left: 0;
        }
    }
    &__link {
        width: 50%;
        //width: 100%;
    }
    &__info {
        margin-left: 0;
    }
}

}

@media all and (max-width: 940px) {
    .apartment {
        &__data {
            flex-wrap: wrap;
            justify-content: space-around
        }

        &__spec {
            width: 40%;
        }

        &__more {
            width: 40%;
        }

        &__price {
            a {
                margin-left: 15px;
            }
        }
        &-mark {
            font-size: 12px;
        }
    }
}

@media all and (max-width: 700px) {
.apartment {
    h1 {
        max-width: 100%;
        font-size: 30px;
        line-height: normal;
    }
    &__title {
        flex-wrap: wrap;
    }
    &-item {
        &--right {
            width: calc(100% - 40px);
        }
        .apartment-cell {
            padding: 0 20px;
        }
    }
    &__outside {
        margin-left: auto;
        height: auto;
    }
    &__points {
        padding: 40px 15px 25px;
        flex-wrap: wrap;
        justify-content: space-evenly;
        max-width: 100%;
    }
    &__point {
        margin-bottom: 15px;
        margin-right: 10px;
    }
    &__spec {
        li p {
            &:first-of-type {
                min-width: 120px;
            }
            span {
                font-size: 16px;
            }
        }
        li > span{
          width: 32px;
          height: 32px;
          font-size: 16px;
          line-height: 30px;
        }
    }
    &__info {
        flex-wrap: wrap;
        justify-content: space-evenly;
        a {
            margin-bottom: 20px;
        }
    }
    &__price {
        justify-content: space-around;
        button {
            margin-left: 0;
            max-width: 100%;
        }
        p {
            font-size: 26px;
        }
        .rouble {
            &::before {
                top: -5.5px;
            }
            &::after {
                top: -2px;
            }
        }
        a {
            font-size: 14px;
        }
    }
    &__pic{
      margin: 0 auto 30px;
      svg{
        width: 100%;
        height: auto;
      }
    }
}
}

@media all and (max-width: 500px) {
.apartment {
    &__spec {
        max-width: 100%;
        width: 300px;
        margin: 0 auto 30px;
        li {
            justify-content: space-between;
            p:first-of-type {
                min-width: auto;
            }
        }
    }
    &__more {
        width: 100%;
    }
    &__pic {
        max-width: 90%;
    }
    &__signature {
        font-size: 14px;
        &--right {
            right: -16px;
        }
        &--left {
            right: calc(100% + 10px);
        }
    }
}
}
