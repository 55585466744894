@import "styles/variable";

.developer {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 40px;
    overflow: hidden;
    &-item {
        width: 50%;
        position: relative;
        transition: 1s;
        opacity: 1;
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
        &--start {
            &_left {
                opacity: 0;
                left: -200px;
            }
            &_right {
                opacity: 0;
                right: -200px;
            };
        }
    }
    h1 {
        margin-top: 73px;
        margin-bottom: 18px;
    }
    &__pic {
        display: block;
        width: 100%;
        height: auto;
    }
    &__descr {
        p {
            max-width: 592px;
        }

        a {
            color: inherit;
            text-decoration: underline;
        }
    }
    &__banner {
        padding-left: 40px !important;
        padding-right: 40px !important;
        width: 100%;
        margin: 40px 0;
        position: relative;
        opacity: 1;
        transition: 1s;
        top: 0;
        &--start {
            top: 50px;
            opacity: 0;
        }
    }
    &__numbers {
        position: absolute;
        background: #213E60;
        padding: 80px 70px;
        width: 528px;
        right: -164px;
        opacity: 1;
        transition: 1s;
        z-index: 1;
        &--start {
            right: 10%;
            opacity: 0;
        }
    }
    &__number {
        color: #fff;
        &:not(:last-child) {
            margin-bottom: 57px;
        }
        span {
            font-size: 56px;
            font-family: $font-geo;
            display: block;
            margin-bottom: 5px;
        }
    }
    &__info {
        padding: 88px 156px;
        position: relative;
        transition: 1s;
        opacity: 1;
        right: 0;
        &--start {
            right: -100px;
            opacity: 0;
        }
    }
    &__point {
        &:not(:last-of-type) {
            margin-bottom: 60px;
        }
        span {
            font-family: $font-geo;
            font-size: 48px;
            display: block;
            margin-bottom: 8px;
        }
    }
}

.banner {
    display: flex;
    justify-content: space-evenly;
    //background-image: url("/files/images/developer/developer-banner.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 36px 0;
    &__item {
        max-width: 298px;
        color: #fff;
        span {
            font-family: $font-geo;
            font-size: 56px;
            display: block;
            margin-bottom: 10px;
        }
    }
}

@media all and (max-width: 1439px) {
    .developer {
        &-item {
            &--mt {
                margin-top: 78px;
            }
            //&:first-of-type {
            //    width: 65%;
            //}
            //&:nth-of-type(2) {
            //    width: 35%;
            //}
        }
        &__point:not(:last-of-type) {
            margin-bottom: 50px;
        }
        &__numbers {
            right: auto;
        }
        &__info {
            padding: 39px 65px;
        }
        &__descr {
            padding-right: 20px;
        }
      h1{
        font-size: 54px;
      }
    }
}

@media all and (max-width: 1168px) {
    .developer {
        h1 {
            font-size: 50px;
        }
        &__info {
            padding: 39px;
        }
        &__numbers {
            height: 100%;
            position: static;
            width: auto;
            padding: 40px;
        }
        &__number {
            &:not(:last-child) {
                margin-bottom: 44px;
            }
        }
        &__descr {
            padding-right: 0;
        }
    }
    .banner {
        &__item {
            max-width: 220px;
        }
    }
}

@media all and (max-width: 940px) {
    .developer {
        h1 {
            font-size: 44px;
        }
        &-item {
            &:first-of-type, &:nth-of-type(2) {
                width: 100%;
            }
        }
        &__descr {
            p {
                max-width: 100%;
            }
        }
        &__numbers {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
        }
        &__number {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
            span {
                font-size: 32px;
            }
        }
        &__info {
            padding: 40px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            margin: 0;
            width: 100%;
            height: 100%;
        }
        &__point {
            &:first-of-type {
                margin-bottom: 0;
            }
            span {
                font-size: 32px;
            }
        }
    }
    .banner {
        flex-wrap: wrap;
        &__item {
            max-width: 250px;
            &:not(:nth-last-of-type(1)) {
                margin-bottom: 20px;
            }
            span {
                font-size: 44px;
            }
        }
    }
}

@media all and (max-width: 700px) {
    .developer {
        h1 {
            margin-top: 0;
            font-size: 30px;
        }
        &__pic {
            &--top {

            }
        }
        &-item {
            width: 100%;
            &--mt {
                margin-top: 30px;
            }
        }
        &__point {
            &:first-of-type {
                margin-bottom: 20px;
            }
        }
    }
}