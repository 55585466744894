@import "styles/variable";

.main-page {
  margin-top: -110px;
}

.main-page__title {
  font-size: 54px;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 32px;
}

.main-page__about {
  span {
    color: #86869F;
  }
}

.main-page__apartment {
  color: #fff;
  span {
    color: #f5f5f5;
    opacity: .5;
  }
}
.main-page__location {
  max-width: 598px;
  color: #A4A4BF;
  position: relative;
  z-index: 1;
  span {
    color: $color-blue-dark;
  }
}
.main-page__advantages {
  max-width: 589px;
  color: #A4A4BF;
  span {
    color: $color-blue-dark;
  }
}
.main-page__architecture {
  max-width: 432px;
  color: #A4A4BF;
  span {
    color: $color-blue-dark;
  }
}
.main-page__infrastructure {
  max-width: 575px;
  color: #A4A4BF;
  span {
    color: $color-blue-dark;
  }
}

.main-page__lobby {
  color: #A4A4BF;
  margin-top: 0;
  margin-bottom: 24px;
  span {
    color: #fff;
  }
}

.main-page__improvement {
  color: #A4A4BF;
  span {
    color: #fff;
  }
}
.main-page__creation {
  color: #A4A4BF;
  span {
    color: $color-blue-dark;
  }
}



.main-page__item {
  width: 50%;
}

@media all and (max-width: 1168px) {
  .main-page__title {
    font-size: 48px;
  }
  .main-page__item:nth-of-type(2) {
    width: calc(50% + 40px);
    margin-right: -40px;
  }
}

@media all and (max-width: 940px) {
  .main-page {
    margin-top: -110px;
  }
  .main-page__title {
    font-size: 44px;
    margin-bottom: 30px;
  }
}

@media all and (max-width: 700px) {
  .main-page__title {
    font-size: 30px;
  }
  .main-page__item {
    width: 100%;
    padding: 0 20px 34px;
    &:nth-of-type(2) {
      margin-right: 0;
      width: 100%;
    }
  }
  .main-page__apartment {
    display: none;
  }
  .main-page__location, .main-page__advantages {
    margin-bottom: 56px;
  }
  .main-page__improvement span {
    color: $color-blue-dark;
  }
}
