.promo {
  position: relative;

  &__inner{
    position: relative;
    height: 100vh;
  }

  &__title {
    position: absolute;
    top: 137px;
    left: 100px;
    max-width: 426px;
    line-height: 80px;
    z-index: 5;
    font-size: 64px;
    color: #fff;

    h2 {
      font-size: 64px;
      font-weight: 400;
      color: #fff;
    }

    span {
      font-family: "geometria", Arial, sans-serif;
      font-weight: 300;
      opacity: 0.44;
    }

    @media all and (min-width: 1620px) {
      left: 50%;
      margin-left: -715px;
    }
  }

  &__fin {
    position: absolute;
    right: 0;
    bottom: 120px;
    z-index: 3;
    cursor: pointer;
  }

  &__controls {
    position: absolute;
    top: 110px;
    right: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 24px;
    z-index: 3;
    cursor: pointer;
    overflow: hidden;

    &-btn {
      --offset: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: auto;
      padding: 16px 24px;
      max-width: 360px;
      max-height: 64px;
      overflow: hidden;
      transform: translateX(100%);
      transition: all 0.3s calc(1s + var(--offset)*0.3s) linear;

      @media all and (max-width: 700px) {
        opacity: 0;
        transform: translateY(100%);
        transition: all 0.5s calc(1s + var(--offset)*0.3s) linear;
      }

      .promo__controls.__animation &{
        transform: translateX(0);
        transition: all 0.5s calc(0.5s + var(--offset)*0.3s) linear;

        @media all and (max-width: 700px) {
          transition: all 0.6s calc(0.5s + var(--offset)* 0.6s) linear;
          transform: translateY(0);
          opacity: 1;
        }
      }


      p {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        margin-right: 16px;
        white-space: nowrap;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

      }

      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

@keyframes pulse-btn {
  from {
    transform: scale(1);
  }
  66% {
    transform: scale(1.1);
  }
  to {
    transform: scale(1);
  }
}

.text {
  position: absolute;
  z-index: 3;
  height: 440px;
  width: 440px;
  top: calc(50% - 220px);
  left: calc(50% - 220px);
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 23px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  div {
    max-width: 240px;
  }
}

.two {
  top: -123px !important;
  transform: rotate(83deg);
  left: 37% !important;
}

.img {
  position: absolute;
  left: calc(50% - 220px);
  top: 9.5%;
}

@media all and (max-width: 1300px) {
  .text {
    width: 302px;
    height: 302px;
    left: calc(50% - 151px);
    top: calc(65% - 151px);
  }
}

@media all and (max-width: 1168px) {
  .promo {
    &__title {
      span {
        font-size: 48px;
      }
    }
  }
}

@media all and (max-width: 940px) {
  .promo {
    &__inner{
      height: 491px;
    }

    &__title {
      left: 20px;
      top: 100px;

      span {
        font-size: 42px;
      }

      h2 {
        font-size: 42px;
      }
    }

    &__fin {
      bottom: 40px;
    }

  }
}

@media all and (max-width: 700px) {
  .promo {
    &__title {
      top: 100px;
      left: 20px;
      line-height: 52px;
    }

    &__controls {
      padding: 43px 20px 20px;
      position: relative;
      top: auto;
      right: auto;
      gap: 12px;
      align-items: center;

      &-btn {
        padding: 16px 16px;
        width: 100%;
        max-width: 335px;
        justify-content: space-between;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        img{
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .text {
    width: auto;
    height: auto;
    background: rgba(30, 50, 75, 0.61);
    padding: 20px;
    bottom: 15%;
    left: 15%;
    top: auto;
  }
  .promo__fin {
    bottom: -27px;
  }
}