﻿@font-face {
  font-family: 'geometria';
  src: url('/fonts/geometria-regular.eot');
  src: url('/fonts/geometria-regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/geometria-regular.woff2') format('woff2'),
  url('/fonts/geometria-regular.woff') format('woff'),
  url('/fonts/geometria-regular.ttf') format('truetype'),
  url('/fonts/geometria-regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'geometria';
  src: url('/fonts/geometria-medium.eot');
  src: url('/fonts/geometria-medium.eot?#iefix') format('embedded-opentype'),
  url('/fonts/geometria-medium.woff2') format('woff2'),
  url('/fonts/geometria-medium.woff') format('woff'),
  url('/fonts/geometria-medium.ttf') format('truetype'),
  url('/fonts/geometria-medium.svg') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'geometria';
  src: url('/fonts/geometria-light.eot');
  src: url('/fonts/geometria-light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/geometria-light.woff2') format('woff2'),
  url('/fonts/geometria-light.woff') format('woff'),
  url('/fonts/geometria-light.ttf') format('truetype'),
  url('/fonts/geometria-light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserrat';
  src: url('/fonts/montserrat-extralight.eot');
  src: url('/fonts/montserrat-extralight.eot?#iefix') format('embedded-opentype'),
  url('/fonts/montserrat-extralight.woff2') format('woff2'),
  url('/fonts/montserrat-extralight.woff') format('woff'),
  url('/fonts/montserrat-extralight.ttf') format('truetype'),
  url('/fonts/montserrat-extralight.svg') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserrat';
  src: url('/fonts/montserrat-light.eot');
  src: url('/fonts/montserrat-light.eot?#iefix') format('embedded-opentype'),
  url('/fonts/montserrat-light.woff2') format('woff2'),
  url('/fonts/montserrat-light.woff') format('woff'),
  url('/fonts/montserrat-light.ttf') format('truetype'),
  url('/fonts/montserrat-light.svg') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserrat';
  src: url('/fonts/montserrat-regular.eot');
  src: url('/fonts/montserrat-regular.eot?#iefix') format('embedded-opentype'),
  url('/fonts/montserrat-regular.woff2') format('woff2'),
  url('/fonts/montserrat-regular.woff') format('woff'),
  url('/fonts/montserrat-regular.ttf') format('truetype'),
  url('/fonts/montserrat-regular.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserrat';
  src: url('/fonts/montserrat-semibold.eot');
  src: url('/fonts/montserrat-semibold.eot?#iefix') format('embedded-opentype'),
  url('/fonts/montserrat-semibold.woff2') format('woff2'),
  url('/fonts/montserrat-semibold.woff') format('woff'),
  url('/fonts/montserrat-semibold.ttf') format('truetype'),
  url('/fonts/montserrat-semibold.svg') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}