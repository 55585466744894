@import "styles/variable";

.news {
  overflow: hidden;
  &-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 40px;
    margin-top: 65px;
    h1 {
      line-height: 0.6;
    }
  }
  &-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 35px;
    &__item {
      max-width: 413px;
      width: 100%;
      position: relative;
      height: 400px;
      margin-bottom: 20px;
      transition: 1s;
      opacity: 1;
      z-index: 1;
      &--no_img {
        &:nth-of-type(2n)  {
          background-image: linear-gradient(#C8A0AF 0%, #B68195 90%);
        }
        &:nth-of-type(2n-1){
          background-image: linear-gradient(#8787A0 0%, #6C6C89 90%);
        }
      }
      &--width_medium {
        max-width: 630px;
      }
      &--width_large {
        max-width: 847px;

        .news-cards__item-title h2 {
          max-width: 733px;
        }
      }
      &--width_full {
        max-width: 100%;

        .news-cards__item-title h2 {
          max-width: 733px;
        }
      }
      &:nth-of-type(2n){
        &:hover {
          transition: .5s;
          transform: translateX(10px);
        }
      }
      &:nth-of-type(2n+1){
        &:hover {
          transition: .5s;
          transform: translateX(-10px);
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: -1;
      }
      &-title {
        position: absolute;
        padding: 35px 20px;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &--violet {
          &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, rgba(86, 86, 182, 0.425) 0%, rgba(49, 49, 189, 0) 100%), linear-gradient(180.22deg, #1E324B 0.19%, rgba(30, 50, 75, 0) 69.3%);
            mix-blend-mode: multiply;
            transform: rotate(-180deg);
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
          }
        }
        &--blue {
          &::before {
            content: '';
            width: 100%;
            height: 100%;
            background: linear-gradient(360deg, $color-blue-lighter 0%, rgba(255, 255, 255, 0) 50%), linear-gradient(180deg, #1E324B 0%, rgba(30, 50, 75, 0) 74.38%);
            mix-blend-mode: multiply;
            transform: rotate(-180deg);
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
          }
        }
        h2 {
          font-family: $font-geo;
          font-weight: 300;
          text-transform: uppercase;
          color: white;
          margin-bottom: 10px;
          max-width: 495px;
        }
        time {
          color: white;
        }
      }
    }
  }
  &-nav {
    display: flex;
    align-items: center;
  }
  &-controls {
    width: 100%;
    max-width: 758px;
    display: flex;
    justify-content: space-between;
    &__link {
      text-transform: uppercase;
      color: $color-gray-light;
      font-size: 18px;
      position: relative;
      transition: .5s;
      &:hover {
        color: $color-blue-dark;
      }
      &--left {
        padding-left: 16px;
        &:before {
          left: 0;
          transform: rotate(-45deg);
        }
      }
      &--right {
        padding-right: 16px;
        margin-left: auto;
        &:before {
          right: 0;
          transform: rotate(135deg);
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 4px;
        width: 8px;
        height: 8px;
        border: 1px solid $color-yellow-light;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }
    }
  }
  &-content {
    margin-top: 20px;
    position: relative;
    min-height: 600px;
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    img {
      max-width: 955px;
      margin-bottom: 20px;
    }
  }
  &-desc {
    max-width: 758px;
    &__promo {
      display: flex;
    }
    time {
      display: block;
      font-size: 16px;
      color: $color-gray-light;
      margin-bottom: 15px;
      margin-right: 10px;
    }
  }
  &-aside {
    order: 2;
    margin-left: auto;
    top: 32px;
    right: 0;
    max-width: 413px;
    background: $color-bg-aside;
    padding: 40px 30px 25px;
    display: flex;
    flex-direction: column;
    opacity: 1;
    transition: 1s;
    width: 100%;
    &--start {
      right: -100px;
      opacity: 0;
    }
    &__more {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 16px;
      color: $color-blue-dark;
      margin-bottom: 20px;
      &-time {
        display: flex;
        time {
          margin-right: 10px;
        }
      }
    }
    &__item {
      padding: 20px 0 20px;
      border-bottom: 1px solid #E0E0E0;
      margin-bottom: 20px;
      &:nth-last-of-type(1){
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
      h3 {
        text-transform: uppercase;
        margin-bottom: 10px;
        font-weight: 400;
        line-height: 133%;
        padding-right: 10px;
        color: $color-blue-dark;
      }
      time {
        font-size: 12px;
        font-weight: 300;
        color: $color-blue-dark;
      }
    }
  }
  &-detail {
    margin-top: 10px;
    h1 {
      font-size: 40px;
      text-transform: uppercase;
      margin-bottom: 30px;
      font-weight: 400;
      max-width: 1000px;
    }
  }
}

@media all and (max-width: 1439px) {
  .news {
    &-cards {
      &__item {
        max-width: 320px;
        &--width_medium {
          max-width: 492px;
        }
        &--width_large {
          max-width: 664px;
        }
        &--width_full {
          max-width: 100%;
        }
        &-title {
          h2 {
            font-size: 30px;
          }
        }
      }
    }
    &-desc {
      max-width: 650px;
    }
    &-controls {
      max-width: 650px;
    }
    &-aside {
      max-width: 340px;
    }
  }
}

@media all and (max-width: 1168px) {
  .news {
    &-cards {
      &__item {
        max-width: calc(33% - 16px);
        &--width_medium {
          max-width: calc(50% - 12px);
        }
        &--width_large {
          max-width: calc(67% - 8px);
        }
        &--width_full {
          max-width: 100%;
        }
        &-title {
          h2 {
            font-size: 24px;
          }
        }
      }
    }
    &-content {
      img {
        max-width: 100%;
      }
    }
    &-desc {
      max-width: 500px;
    }
    &-controls {
      max-width: 100%;
    }
  }
}

@media all and (max-width: 940px) {
  .news {
    &-cards {
      &__item {
        &-title {
          font-size: 24px;
        }
      }
    }
    &-aside {
      display: none;
    }
    &-desc {
      max-width: 100%;
    }
    &-controls {
      max-width: 100%;
    }
  }
}

@media all and (max-width: 700px) {
  .news {
    &-cards {
      &__item {
        max-width: 100%;
      }
    }
    &-title {
      margin-top: 0;
      flex-wrap: wrap;
      h1 {
        margin-bottom: 0;
        line-height: normal;
      }
    }
    &-content {
      margin-bottom: 15px;
      h2 {
        font-size: 30px;
      }
    }
    &-detail h1 {
      font-size: 24px;
    }
  }
}
