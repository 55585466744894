@import "styles/variable";

.compaire {
    padding-bottom: 125px;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    &-nav {
        display: flex;
        position: absolute;
        height: 218px;
        top: -24px;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 22px 20px;
        right: 0;
        background: $color-blue-dark;
        z-index: 1;
        width: 100%;
        max-width: 720px;
        &__link {
            color: #fff;
            text-transform: uppercase;
            padding-right: 28px;
            font-size: 18px;
            margin-right: 25px;
            cursor: pointer;
            &:hover {
                color: $color-yellow-light;
            }
            &--compaire {
                background: url("/files/images/svg/white-arrow-right.svg") right 35%/18px 14px no-repeat;
                font-weight: 600;
            }
            &--share {
                background: url("/files/images/favorites/share.svg") right 35%/18px 14px no-repeat;
            }
            &--delete {
                background: url("/files/images/favorites/delete.svg") right 35%/18px 14px no-repeat;
            }
        }
    }
    h1 {
        text-transform: uppercase;
        margin-top: 26px;
        width: 100%;
    }
    &__block {
        margin-top: 36px;
        border-top: 1px solid rgba(134, 134, 159, 0.1);
        width: 25%;
        position: relative;
        left: 0;
        opacity: 1;
        transition: 1s;
        &--start {
            left: -60px;
            opacity: 0;
        }
    }
    &__desc {
        height: 299px;
        padding-top: 125px;
        padding-left: 40px;
        p {
            font-size: 14px;
            max-width: 243px;
        }
    }
    &__title {
        font-family: $font-geo;
        font-size: 27px;
        text-transform: uppercase;
        max-width: 200px;
        text-align: right;
        font-weight: 300;
        margin-bottom: 20px;
        span {
            color: #A4A4BF;
        }
    }
    &__switch {
      width: 46px;
      height: 24px;
      border: 1px solid #213e6080;
      position: relative;
      border-radius: 12px;
      margin-bottom: 8px;
      cursor: pointer;
      transition: .5s;
      &::before {
        transition: .5s;
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        background: #D8D8E5;
        top: 2px;
        transform: translateX(4px);
        border-radius: 50%;
      }
      &--active {
        border-color: #909EAF;
        &::before {
          transform: translateX(22px);
          background: #21364e;
        }
      }
    }
    &__selector {
      width: 46px;
      height: 24px;
      border: 1px solid #213e6080;
      position: relative;
      border-radius: 12px;
      margin-bottom: 8px;
        &--active {
            background: #909EAF;
            border-color: #909EAF;
            .compaire__knob {
                left: auto;
                right: 3px;
                background: #fff;
            }
        }
    }
    &__knob {
        width: 18px;
        height: 18px;
        background: #D8D8E5;
        position: absolute;
        top: 2px;
        left: 3px;
        border-radius: 50%;
        cursor: pointer;
    }
    &-slider {
        width: 75%;
        margin-top: 36px;
        border-top: 1px solid rgba(134,134,159,.1);
        .slick-arrow {
            z-index: 3;
            transition: 1s;
        }
        .slick-prev {
            left:0;
            top: 160px;
        }
        .slick-next {
            right:0;
            top: 160px;
        }
        .slick-disabled {
            opacity: 0;
            z-index: -1;
            transition: 1s;
        }
        &__item {
            padding-top: 25px;
            &:hover {
                .compaire-slider__favorites, .compaire-slider__delete {
                    display: block;
                }
            }
        }
        &__action {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 30px;
            align-items: center;
            height: 20px;

        }
        &__favorites {
            width: 20px;
            height: 20px;
            background: url("/files/images/svg/star.svg") center no-repeat;
            cursor: pointer;
            display: none;
            transition: background 1s;
            &--active {
                background: url("/files/images/svg/star-selected.svg") center no-repeat;
                transition: background 1s;
            }
        }
        &__delete {
            position: relative;
            width: 17px;
            height: 17px;
            cursor: pointer;
            margin-left: 16px;
            display: none;
            &::before {
                content: '';
                position: absolute;
                top: 7px;
                left: -2px;
                height: 1.5px;
                width: 22px;
                background: $color-blue-lighter;
                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 7px;
                left: -2px;
                height: 1.5px;
                width: 22px;
                background: $color-blue-lighter;
                transform: rotate(-45deg);
            }
        }
        &__pic {
            max-width: 80%;
            margin: 0 auto;
            height: 224px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            img {
                max-width: 100%;
                max-height: 100%!important;
            }
            svg {
                width: 300px;
                max-width: 100%;
                max-height: 224px;
            }
        }
        &__button {
            width: 48px;
            height: 48px;
            font-size: 0;
            border: .5px solid rgba(255,255,255,.2);
            background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #21364e;
            transition: .5s;
            position: absolute;
            z-index: 1;
            top: 224px;
            cursor: pointer;
            &:hover {
                background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #111F30;
            }
            &--next {
                right: -20px;
            }
            &--prev {
                left: -20px;
                transform: rotate(180deg);
            }
        }
    }
    &-static {
        display: flex;
        .compaire-slider__item {
            width: 100%;
        }
    }
    &-table {
        width: 100%;
        position: relative;
        th, td {
            width: 100%;
            padding: 30px 50px;
            line-height: 33px;
        }
         &__head{
            display: flex;
            justify-content: left;
             padding-left: 5%;
            align-items: center;
             text-align: center;
            border-bottom: 1px solid rgba(134, 134, 159, 0.1);
            height: 80px;
            background: #FAFAFB;
            font-size: 19px;
            font-weight: 600;
            color: #8787A0;
        }
        &__cell {
            font-size: 19px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid rgba(134, 134, 159, 0.1);
            height: 80px;
            font-weight: 600;
            &:first-of-type {
                background: #FAFAFB;
                font-weight: 600;
                color: #8787A0;
            }
        }
        &--left {
            th {
                text-align: left;
            }
        }
    }
}

@media all and (max-width: 1439px) {
    .compaire {
        &-nav {
            max-width: 648px;
            &__link {
                font-size: 15px;
            }
        }
        &-table {
            th, th {
                padding: 30px;
            }
        }
        &__descr {
            padding-left: 0;
        }
    }
}

@media all and (max-width: 1168px) {
    .compaire {
        padding-bottom: 125px!important;
        &-nav {
            display: none;
        }
        &-slider {
            &__pic {
                display: flex;
                align-items: center;
            }
        }
    }
}

@media all and (max-width: 940px) {
    .compaire {
        &__block {
            width: 33%;
            flex-grow: 1;
        }
        &__descr {
            height: calc(37% + 66px);
        }
        &-slider {
            width: 66%;
            flex-grow: 1;
            //&__item {
            //    width: 50%;
            //    &:nth-last-of-type(2) {
            //        display: none;
            //    }
            //}
        }
        &-table {
            th {
                font-size: 16px;
                padding: 30px;
            }
            td{
                font-size: 20px;
                padding: 30px;
            }
        }
    }
}

@media all and (max-width: 700px) {
    .compaire {
        h1 {
            margin-top: 0;
        }
        &__block {
            width: 50%;
        }
        &__desc {
            padding-left: 0;
            height: 220px;
            padding-top: 60px;
        }
        &__descr {
            height: 300px;
            padding-top: 90px;
            p {
                font-size: 10px;
            }
        }
        &__title {
            font-size: 16px;
            text-align: left;
            margin-bottom: 20px;
        }
        &-slider {
            width: 50%;
            &__action {
                margin-bottom: 0;
            }
            &__pic {
                height: 175px;
                padding-top: 0;
                img {
                    max-height: 100%;
                }
            }
            &__button {
                width: 36px;
                height: 36px;
            }

            .slick-arrow {
                top: 112px;
            }
        }
        &-table {
            th {
                font-size: 12px;
                font-weight: 400;
                padding: 20px 10px;
                line-height: 22px;
            }
            td {
                font-size: 16px;
                padding: 20px 10px;
                line-height: 22px;
            }
            &__cell, &__head {
                font-size: 16px;
            }
        }
    }
}
