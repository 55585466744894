@import "styles/variable";

html {
  scroll-behavior: smooth;

}

body {
  font-size: 16px;
  font-family: $font-mont;
  font-weight: 400;
  line-height: 1;
  color: $color-blue-dark;
  overflow: auto!important;
  padding-right: 0!important;
}

h1 {
  font-size: 64px;
  font-weight: 400;
}

h2 {
  font-size: 40px;
  font-weight: 400;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 25px;
}

a {
  text-decoration: none;
}

p {
  line-height: 150%;
}

p {
  margin-bottom: 25px;
  &:nth-last-of-type(1){
    margin-bottom: 0;
  }
}

ul {
  margin-top: 0;
  margin-bottom: 25px;
  padding-left: 15px;
  line-height: 1.5;
  li {
    margin-bottom: 15px;
    &:nth-last-of-type(1){
      margin-bottom: 0;
    }
  }
}

time {
  font-size: 18px;
  line-height: 133%;
}

.modal {
  .label {
    transition: .5s;
    font-weight: 400;
    font-family: $font-geo;
    margin-bottom: 5px;
    position: relative;
    top: -28px;
    display: block;
    font-size: 16px;
    color: #A9ADB3;
    z-index: 1;
  }

}

input {
  font-size: 16px;
  max-width: 290px;
  width: 100%;
  color: #111F30;
  min-height: 40px;
  outline: none;
  border: none;
  box-shadow: inset 0px -2px 0px #A9ADB3;
  position: relative;
  z-index: 1;
  font-family: $font-geo;
  -webkit-appearance: none;
  &:focus {
    box-shadow: inset 0px -2px 0px #1E324B;
  }
  &:focus ~ .label {
    top: -55px;
    transition: .5s;font-size: 14px;
    color: $color-blue-dark;
  }
  &::placeholder {
    font-size: 16px;
    color: #A9ADB3;
  }
}

button {
  border: 1px solid $color-blue-lighter;
  outline: none;
  padding: 12px 16px;
  font-size: 18px;
  color: $color-blue-dark;
  line-height: 133%;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  font-family: $font-mont;
  text-transform: uppercase;
  height: 48px;
  &:hover {
    background: $color-blue-lightest;
    color: $color-blue-dark!important;
  }
}

@media all and (max-width: 940px) {
  h1 {
    font-size: 44px;
  }
}

@media all and (max-width: 700px) {
  h1 {
    font-size: 30px;
  }
  button {
    font-size: 14px;
    height: auto;
  }
}