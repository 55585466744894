.improvement {
  margin-top: -110px;
  //display: flex;
  //flex-wrap: wrap;

  &-title {
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 152px 0 110px;
    min-height: 406px;
    width: 100%;
    transition: 1s;
    opacity: 1;
    color: #fff;

    &--start {
      opacity: 0;
    }

    &__container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 80px;
    }

    &__text {
      line-height: 24px;
      max-width: 682px;
    }

    &__name {
      font-size: 64px;
      font-weight: 400;
      margin-bottom: 24px;
    }
  }

  &-section {
    display: flex;
    align-items: flex-start;
    position: relative;
    &--workout {
      &:before {
        content: '';
        width: 50%;
        height: 434px;
        position: absolute;
        top: -80px;
        left: 0;
        background: rgba(134, 134, 159, 0.1);
        z-index: -1;
      }
    }

    &--relax {
      padding-bottom: 80px;
      &:before {
        content: '';
        width: 50%;
        height: calc(100% + 80px);
        position: absolute;
        top: -80px;
        right: 80px;
        background: rgba(134, 134, 159, 0.1);
        z-index: -1;
      }
    }
  }

  &-item {
    width: 50%;
    position: relative;
    opacity: 1;
    transition: 1s;
    left: 0;

    &--right {
      padding-left: 67px;
    }

    &--start-right {
      left: 60px;
      opacity: 0;
    }

    &--start-left {
      left: -60px;
      opacity: 0;
    }

    &__body{
       &--left{
         padding-right: 67px;
       }
       &--right{
         padding-left: 67px;
       }
     }
  }

  &__title {
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 16px;
    margin-top: 128px;
    text-transform: uppercase;

    &--workout {
      margin-top: 173px;
    }

    &--relax {
      margin-top: 197px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 24px;
    padding-right: 84px;
    margin-bottom: 30px;

    &--right {
      padding-right: 0;
    }
  }

  &__pic {
    display: block;
    width: 100%;
    height: auto;

    &--playhub {
      margin-top: -58px;
    }
  }
}

@media all and (max-width: 1439px) {
  .improvement {
    &__title {
      margin-top: 70px;
    }
  }
}

@media all and (max-width: 1168px) {
  .improvement {
    &__title {
      font-size: 56px;
      line-height: 64px;
    }

    &-section {
      &--workout:before {
        top: -40px;
        height: 60%;
      }
      &--relax {
        padding-bottom: 40px;
        &:before {
          top: -40px;
          right: 40px;
          height: calc(100% + 40px);
        }
      }
    }
  }
}

@media all and (max-width: 940px) {
  .improvement {
    &-title__name {
      font-size: 44px;
    }
    &-section{
      padding-top: 40px;
      padding-bottom: 40px;
      flex-wrap: nowrap;
      align-items: center;
    }
    &-item{
      width: auto;
    }
    &__title {
      font-size: 44px;
      line-height: 52px;
      margin-top: 0;

      &--workout, &--relax {
        margin-top: 0;
      }
    }
    &__text {
      padding-right: 40px;
      min-width: 430px;

      &--right {
        padding-right: 0;
      }
    }

    &__pic--playhub {
      margin-top: 0;
    }
  }
  .improvement-section--workout:before {
    top: 0;
    height: 100%;
    width: 100%;
  }
  .improvement-section--relax:before{
    display: none;
  }
}

@media all and (max-width: 700px) {
  .improvement {
    &-title__name {
      font-size: 30px;
    }
    &-title__container {
      padding: 0 20px;
    }
    &-section {
      padding-bottom: 0;
      flex-wrap: wrap;

      &:before {
        content: none;
      }

      &--relax {
        padding-bottom: 0;
      }
    }

    &__title {
    }
    &__text {
      min-width: auto;
    }

    &-item {
      width: 100%;
      padding: 0;
      order: 1;

      &--right {
        order: 0;
      }

      &__body{
        &--left{
          padding-right: 0;
        }
        &--right{
          padding-left: 0;
        }
      }
    }

    &__pic {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }
  }
  .improvement-section--workout{
    flex-direction: column-reverse;
  }
}