@import "styles/variable";

body {
    overflow-x: hidden !important;
    margin-right: calc(-1 * (100vw - 100%)) !important;
}

.lock {
    overflow: hidden !important;
}

.modal-open {
    overflow: hidden !important;
}

.menu-open {
    overflow: hidden !important;
}

.layout {
    position: relative;
    min-height: calc(100vh - 24px);
}

.btn {
    border: 1px solid $color-blue-lighter;
    outline: none;
    padding: 12px 16px;
    font-size: 18px;
    color: $color-blue-dark;
    line-height: 133%;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    font-family: $font-mont;
    text-transform: uppercase;
    min-height: 48px;
    display: inline-block;

    &:hover {
        background: $color-blue-lightest;
        color: $color-blue-dark !important;
    }

    @media all and (max-width: 700px) {
        padding: 16px;
        font-size: 14px;;
    }

    &--sm{
        padding: 8px 16px;
        min-height: 36px;
        font-size: 13px;
        line-height: 20px;
    }
}

.snackbar {
    button {
        height: auto;
        transition: .5s;

        &:hover {
            color: #92A4DF;
        }
    }
}

.main {
    background: linear-gradient(180deg, $color-blue-lighter 0%, rgba(238, 243, 247, 0) 100%) top no-repeat;
    background-size: 100% 208px;
    padding-top: 110px;
    margin: 0 auto;

    &--developer {
        background: none;
    }
}

.section {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 80px;
    position: relative;
    flex-grow: 1;
}

.section-wrapper {
    min-height: 200px;
    position: relative;
}

.logo {
    width: 154px;
    height: 40px;
    background: url("/files/images/svg/logo.svg") center no-repeat;
    background-size: 154px 40px;
    font-size: 0;
    margin-right: 20px;
}

.back {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &__link {
        display: inline-block;
        font-size: 16px;
        text-transform: uppercase;
        line-height: 24px;
        padding-left: 28px;
        background: url("/files/images/svg/arrow-left.svg") left 35% no-repeat;
        background-size: 18px 14px;
        color: $color-blue-dark;
    }
}

.slider-btn {
    width: 48px;
    height: 48px;
    font-size: 0;
    background: #21364E;
    border: 0.5px solid rgba(255, 255, 255, 0.2);
    background-image: url("/files/images/svg/arrow-slider.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 10px 20px;
    transition: .5s;

    &--left {
        transform: rotate(180deg);
    }
}

.switcher {
    border: 1px solid $color-border;
    display: flex;
    justify-content: space-between;
    font-family: $font-mont;

    &__item {
        padding: 8px 17px;
        line-height: 171%;
        font-size: 14px;
        text-align: center;
        color: $color-blue-dark;
        cursor: pointer;
        text-transform: initial;
        flex-grow: 1;

        &:not(:last-child) {
            border-right: 1px solid $color-border;
        }

        &--white {
            background: #fff;
        }

        &--cw {
            color: #fff;

            &:hover {
                color: $color-blue-dark;
            }
        }

        &:hover {
            background: $color-blue-lightest;
        }

        &--active {
            color: #fff;
            background: $color-blue-dark;

            &:hover {
                background: $color-blue-dark;
            }
        }

        &--action {
            padding-right: 46px;
            background: url("/files/images/news/action.svg") right 10px center no-repeat, radial-gradient(18.02% 50% at 78.38% 71.25%, rgba(222, 209, 162, 0.8) 0%, rgba(255, 255, 255, 0.8) 70.76%);

            &:hover {
                background: url("/files/images/news/action.svg") right 10px center no-repeat, $color-blue-lightest;
            }
        }
    }
}

.slick-slider {
    .slick-arrow {
        width: 48px;
        height: 48px;
        font-size: 0;
        border: .5px solid rgba(255, 255, 255, .2);
        background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #21364e;
        transition: .5s;
        position: absolute;
        z-index: 1;

        &.slick-prev {
            transform: rotate(180deg);
        }

        &:hover {
          background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #111F30;

          @media only screen and (hover: none) and (pointer: coarse){
            background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #21364e;
          }
        }

        &:active {
          background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #111F30;
        }
    }
}

.form-inner {
    display: flex;
    padding-top: 15px;
    flex-wrap: wrap;

    &__item {
        width: 50%;
        margin-bottom: 15px;
    }

    input[type="submit"] {
        text-transform: uppercase;
        font-size: 14px;
        border: 1px solid $color-blue-lighter;
        outline: 0;
        padding: 12px 16px;
        color: $color-blue-dark;
        font-weight: 600;
        background: #fff;
        cursor: pointer;
        font-family: $font-mont;
        line-height: 133%;
        max-width: 303px;
        box-shadow: none;

        &:focus {
            box-shadow: none;
        }
    }

    &__submit {
        display: flex;
        align-items: center;
        width: 100%;
    }

    p {
        margin-left: 30px;
        font-family: $font-geo;
        font-size: 14px;
        max-width: 310px;
        margin-bottom: 0;
    }

    a {
        color: $color-yellow-light;
    }
}

.error {
    color: red;
    font-size: 12px;
}

.rouble {
    position: relative;
    font-family: "Montserrat", Arial, sans-serif;
}

.right-nav {
    position: fixed;
    right: 0;
    top: 253px;
    background: linear-gradient(270deg, rgba(17, 31, 48, 0.8) -75%, rgba(17, 31, 48, 0) 152.08%);
    border-radius: 8px 0px 0px 8px;
    width: 48px;
    padding: 16px 18px 16px 16px;
    z-index: 2;
    transition: width .5s;

    &__item {
        display: block;
        color: transparent;
        font-size: 10px;
        line-height: 20px;
        height: 20px;
        text-transform: uppercase;
        margin-bottom: 16px;
        padding-right: 14px;
        position: relative;
        text-align: right;
        opacity: .4;
        transition: opacity .5s, width .5s, padding .5s, color .5s;
        width: 14px;
        margin-left: auto;
        white-space: nowrap;
        font-size: 0;
        overflow: hidden;

        &--wrap {
            white-space: normal;
            height: 34px;
        }

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }

        &::before {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            right: 3px;
            top: calc(50% - 3px);
            border: 1px solid #fff;
            border-radius: 50%;
        }

        &::after {
            content: '';
            position: absolute;
            border: 1px solid rgba(255, 255, 255, 0.4);
            width: 12px;
            height: 12px;
            right: 0;
            border-radius: 50%;
            top: calc(50% - 6px);
            opacity: 0;
        }

        &--active {
            opacity: 1;

            &::before {
                background: #fff;
            }

            &::after {
                opacity: 1;
            }
        }
    }

    &:hover {
        width: 185px;

        .right-nav__item {
            width: 100%;
            padding-right: 24px;
            font-size: 10px;
            color: #fff;
        }
    }
}

.hide {
    display: none;
}

.empty {
    max-width: 668px;
    margin: 243px auto;
    text-align: center;

    p {
        font-size: 32px;
        margin-bottom: 30px !important;
        opacity: 0.3;
        text-transform: uppercase;
    }

    a {
        border: 1px solid $color-blue-lighter;
        outline: none;
        padding: 12px 16px;
        font-size: 18px;
        color: $color-blue-dark;
        line-height: 133%;
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        font-family: $font-mont;
        text-transform: uppercase;
        height: 48px;

        &:hover {
            background: $color-blue-lightest;
            color: $color-blue-dark !important;
        }
    }
}

.title {
    font-family: $font-geo;
    font-size: 40px;
    line-height: 50px;
    color: $color-blue-dark;
    font-weight: 300;
    text-transform: uppercase;
}

.hint {
    display: inline-block;
    background: #C8A0AF;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
}

.icon {
    width: 50px;

    img {
        max-width: 80px;
        border-radius: 50%;
    }
}

.cluster {

    p {
        color: white;
        font-size: 16px;
        line-height: 2.65;
    }
}

.default-mark {
    position: relative;

    p {
        position: absolute;
        top: 0;
        left: 30px;
        color: #213E60;
        font-size: 16px;
        font-weight: 600;
        width: 100px;
        line-height: 1;
        text-shadow: -0   -1px 1px #FFFFFF,
        0   -1px 1px #FFFFFF,
        -0 1px 1px #FFFFFF,
        0 1px 1px #FFFFFF,
        -1px -0 1px #FFFFFF,
        1px -0 1px #FFFFFF,
        -1px 0 1px #FFFFFF,
        1px 0 1px #FFFFFF,
        -1px -1px 1px #FFFFFF,
        1px -1px 1px #FFFFFF,
        -1px 1px 1px #FFFFFF,
        1px 1px 1px #FFFFFF,
        -1px -1px 1px #FFFFFF,
        1px -1px 1px #FFFFFF,
        -1px 1px 1px #FFFFFF,
        1px 1px 1px #FFFFFF;
    }
}

.metro {
    position: relative;

    p {
        position: absolute;
        bottom: -10px;
        text-align: left;
        left: 50px;
        color: #213E60;
        font-size: 16px;
        font-weight: 600;
        width: 100px;
        line-height: 1;
        text-shadow: -0   -1px 1px #FFFFFF,
        0   -1px 1px #FFFFFF,
        -0 1px 1px #FFFFFF,
        0 1px 1px #FFFFFF,
        -1px -0 1px #FFFFFF,
        1px -0 1px #FFFFFF,
        -1px 0 1px #FFFFFF,
        1px 0 1px #FFFFFF,
        -1px -1px 1px #FFFFFF,
        1px -1px 1px #FFFFFF,
        -1px 1px 1px #FFFFFF,
        1px 1px 1px #FFFFFF,
        -1px -1px 1px #FFFFFF,
        1px -1px 1px #FFFFFF,
        -1px 1px 1px #FFFFFF,
        1px 1px 1px #FFFFFF;
    }
}

.ymaps-2-1-79-ground-pane {
    filter: grayscale(1);
    -ms-filter: grayscale(1);
    -webkit-filter: grayscale(1);
    -moz-filter: grayscale(1);
    -o-filter: grayscale(1);
}

.leaflet-container {
    height: 660px;
    width: 100%;
}

.leaflet-tile-container {
    img {
        filter: grayscale(1);
        -ms-filter: grayscale(1);
        -webkit-filter: grayscale(1);
        -moz-filter: grayscale(1);
        -o-filter: grayscale(1);
    }
}

.leaflet-popup {
    left: 0 !important;
    margin-bottom: 38px !important;

    &-content-wrapper {
        background: #C8A0AF !important;
        border-radius: 0 !important;
    }

    &-content {
        margin: 5px 17.5px !important;
        font-size: 16px;
        line-height: 24px !important;
        color: #FFFFFF;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
    }

    &-tip {
        padding: 0 !important;
        margin: 0 !important;
        transform: rotate(0deg) !important;
        width: 0 !important;
        height: 0 !important;
        border-style: solid;
        border-width: 8px 8px 0 0;
        border-color: #C8A0AF transparent transparent transparent;
        background: transparent !important;
    }

    &-tip-container {
        left: 0 !important;
        width: 8px !important;
        height: 8px !important;
        margin-left: 0 !important;
    }
}

.leaflet-popup-close-button {
    display: none;
}

.leaflet-marker-icon--hover {
    border-radius: 50%;
    transition: .5s;
}

.leaflet-marker-icon:not(:hover) {
    z-index: 0;
}

.marker-cluster {
    width: 42px !important;
    height: 42px !important;
    border-radius: 50% !important;
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    background: #8AA4B8;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.leaflet-marker-static-icon {
    display: flex !important;
    align-items: center;

    span {
        width: 135px;
        margin-left: 10px;
        font-family: $font-mont;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #213E60;
        text-shadow: -0   -1px 1px #FFFFFF,
        0   -1px 1px #FFFFFF,
        -0 1px 1px #FFFFFF,
        0 1px 1px #FFFFFF,
        -1px -0 1px #FFFFFF,
        1px -0 1px #FFFFFF,
        -1px 0 1px #FFFFFF,
        1px 0 1px #FFFFFF,
        -1px -1px 1px #FFFFFF,
        1px -1px 1px #FFFFFF,
        -1px 1px 1px #FFFFFF,
        1px 1px 1px #FFFFFF,
        -1px -1px 1px #FFFFFF,
        1px -1px 1px #FFFFFF,
        -1px 1px 1px #FFFFFF,
        1px 1px 1px #FFFFFF;
    }
}

.leaflet-touch .leaflet-bar {
    border: 0.5px solid rgba(33, 62, 96, 0.21) !important;
    border-radius: 0 !important;
}

.leaflet-top .leaflet-control {
    margin-top: 26px !important;
}

.leaflet-right .leaflet-control {
    margin-right: 26px !important;
}

.leaflet-touch .leaflet-bar a {
    width: 46px !important;
    height: 46px !important;
    line-height: 46px !important;
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.06));
    color: #213E60 !important;

}

.leaflet-bar a.leaflet-disabled {
    cursor: default;
    background-color: #f4f4f4;
    color: #bbb !important;
}

.leaflet-control-layers {
    display: none;
}

.snackbar {
    left: auto;
    right: 135px;
    bottom: 24px;
    justify-content: flex-end;
    display: flex;
    position: fixed;
    align-items: center;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
    opacity: 0;
    visibility: hidden;
    transition: .5s;

  @media (max-width: 576px) {
    right: 0;
    margin: 0 16px;
  }

    &--active {
        opacity: 1;
      visibility: visible;
        z-index: 1000;
    }

    &__content {
        background-color: #1E324B;
        display: flex;
        padding: 6px 16px;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.43;
        border-radius: 4px;
        letter-spacing: 0.01071em;
        color: #fff;
    }

    &__icon {
        display: flex;
        opacity: 0.9;
        padding: 7px 0;
        font-size: 22px;
        margin-right: 12px;
        align-items: center;

        img {
            width: 1em;
            height: 1em;
            display: inline-block;
            flex-shrink: 0;
            user-select: none;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        padding: 8px 0;
    }

    &__close {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: -8px;
        padding-left: 16px;
    }

    &__button {
        flex: 0 0 auto;
        padding: 12px;
        overflow: visible;
        text-align: center;
        border-radius: 50%;
        border: 0;
        cursor: pointer;
        margin: 0;
        display: inline-flex;
        outline: 0;
        position: relative;
        align-items: center;
        user-select: none;
        vertical-align: middle;
        justify-content: center;
        text-decoration: none;
        background-color: transparent;

        &:hover {
            background: transparent;
        }
    }

    &__label {
        width: 100%;
        display: flex;
        align-items: inherit;
        justify-content: inherit;

        img {
            width: 1em;
            height: 1em;
            display: inline-block;
            flex-shrink: 0;
        }
    }
}

.leaflet-tile-pane {
    z-index: 50 !important;
}

.leaflet-pane {
    z-index: 50 !important;
}

.leaflet-marker-pane {
    z-index: 51 !important;
}

.leaflet-popup-pane {
    z-index: 100 !important;
}

.scheme__img {
    transition: .5s;
    opacity: 1;
}

.scheme__zoom {
    transition: .5s;
    //opacity: 0;
    position: absolute;
    width: 74px;
    height: 74px;
    transform: scale(1);
    top: calc(50% - 37px);
    left: calc(50% - 37px);
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }
}

/*.floor-scheme__pic:hover {
  cursor: pointer;
  .scheme__zoom {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
  .scheme__img {
    opacity: 0.5;
  }
}*/

.leaflet-overlay-pane {
    z-index: 51 !important;
}

.st37,
.st23,
.st33,
.st34 {
    cursor: pointer;
}

.callback-button {
    position: fixed;
    right: 40px;
    bottom: 30px;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: url('/files/images/svg/ico-phone.svg') center / 28px no-repeat #DED1A2;
    z-index: 999;
    font-size: 0;
    line-height: 0;
    border: none;
    animation: ease-in pulse-btn 2s infinite;

    &:hover {
        background: url('/files/images/svg/ico-phone.svg') center / 28px no-repeat #c4b990;
    }


  @media (hover: hover) {
    .modal-open & {
      right: 57px;
    }
  }
}

a[href*="tel"] * {
  opacity: 1 !important;
}
