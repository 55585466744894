@import "styles/variable";

.link-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  opacity: 0;
  transition: .5s;
  &--active {
    background: rgba(0, 0, 0, 0.3);
    z-index: 5;
    opacity: 1;
  }

  &__exit {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: .5s;
    &:hover {
      background: #DDE7EE;
    }
    &::before {
      transition: 1s;
      content: '';
      position: absolute;
      width: 16px;
      height: 1px;
      top: 23px;
      left: 16px;
      background: $color-blue-dark;
      transform: rotate(-45deg);
    }
    &::after {
      transition: 1s;
      content: '';
      position: absolute;
      width: 16px;
      height: 1px;
      top: 23px;
      left: 16px;
      background: $color-blue-dark;
      transform: rotate(45deg);
    }
  }

  &__text {
    font-size: 20px;
    padding-right: 60px;
    line-height: 1.5;
    margin-bottom: 20px;
  }

  &__input {
    margin-bottom: 20px;
    word-wrap: break-word;
  }
}

.link-wrapper {
  max-width: 600px;
  margin: 20px;
  position: relative;
  padding: 40px;
  border-radius: 5px;
  width: 100%;
  background: #EEF3F6;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-content: normal;
}

/*
.hint {
  opacity: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  height: 40px;
  background: $color-blue-dark;
  color: white;
  font-size: 12px;
  padding: 10px;
  transition: .5s;
  &--active {
    opacity: 1;
  }
}*/
