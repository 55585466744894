@import 'styles/variable';

.roominess {
  margin-top: -110px;

  &__catalog {
    position: relative;
    padding-bottom: 98px;
    overflow: hidden;

    .catalog-nav {
      top: 0px;
      right: 80px;

      width: 44.5%;
      height: 76px;
      padding-left: 36px;
    }

    .filter {
      margin-top: 50px;

      &__item {
        // flex: 0 0 380px;

        // &:first-child {
        //   max-width: 370px;
        // }

        &--rooms {
          // flex-direction: row;

          >span {
            margin-top: 10px;
          }
        }

        // .range-section .rc-slider {
        //   margin: 0 30px;
        // }
      }
    }

    .popup-wrapper {
      padding: 70px 20px 20px 20px;
    }
  }

  &__location-items {
    margin-bottom: -69px;
  }
}

// @media all and (max-width: 1439px) {
//   .roominess {
//     &__catalog {
//       .filter__item {
//         flex: 0 0 380px;

//         &--rooms {
//           flex-direction: column;
//         }
//       }
//     }
//   }
// }

@media all and (max-width: 1168px) {
  .roominess {
    &__catalog {
      .catalog-nav {
        right: 40px;
      }
    }
  }
}

@media all and (max-width: 940px) {
  .roominess {

    &__header {
      .location-title {
        font-size: 30px;
      }
    }

    &__catalog {
      padding-bottom: 0;
      margin-bottom: -25px;

      .filter {
        margin-top: 40px;
      }

      .popup-wrapper {
        padding: 80px 20px 20px 20px;
      }
    }

    &__location-items {
      margin-bottom: -20px;
    }
  }
}

@media all and (max-width: 420px) {
  .roominess {
    &__catalog .catalog-more {
      width: 100%;

      font-size: 18px;
      line-height: 24px;
    }
  }
}