@import "styles/variable";

.documents {
    padding-bottom: 78px;
    h1 {
        margin: 30px 0;
        text-transform: uppercase;
        font-size: 40px;
    }
    &-list {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 40px;
        &__item {
            background: #FAFAFB;
            width: 32%;
            padding: 30px 25px;
            transition: .5s;
            display: flex;
            margin-top: 20px;
            text-transform: uppercase;
            height: 215px;
            position: relative;
            top: 0;
            opacity: 1;
            color: $color-blue-dark;
            &--start {
                top: 60px;
                opacity: 0;
            }
            &:hover {
                background: $color-gray-lighter; 
            }
            &:not(:nth-of-type(3n+3)) {
                margin-right: 11px;
            }
            &:nth-of-type(3n+2) {
                margin-top: -10px;
            }
            &:nth-of-type(3n+3) {
                margin-top: -53px;
            }
        }
        &__content {
            width: 100%;
            max-width: 230px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &__date {
            font-size: 12px;
        }
        &__link {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            flex-grow: 1;
            background: url("/files/images/documents/documents-link-bg.svg") center 25px no-repeat;
            color: $color-blue-dark;
            p {
               font-size: 12px; 
               text-align: center;
            }
            span {
                color: #f15642;
            }
        }
    }
    &-text {
        position: relative;
        padding-left: 40px;
        font-size: 12px;
        text-transform:uppercase;
        line-height: 2;
        color: $color-blue-dark;
        a {
            color: $color-blue-dark;
            text-decoration: underline;
        }
        &:before {
            content: "";
            position: absolute;
            background: url("/files/images/svg/info.svg");
            width: 24px;
            height: 24px;
            left: 0;
            top: 0;
        }
    }
}

@media all and (max-width: 1439px) {
    .documents {
        &-list {
            &__content {
                p {
                    font-size: 14px;
                }
            }
            &__item {
                &:not(:nth-of-type(3n+3)) {
                    margin-right: 20px;
                }
            }
        }
    }
}

@media all and (max-width: 1168px) {
    .documents {
        h1 {
            font-size: 54px;
        }
        &-list {
            padding-bottom: 60px;
            justify-content: center;
            &__item {
                width: 40%;
                &:not(:nth-of-type(3n+3)) {
                    margin-right: 0;
                }
                &:nth-of-type(3n+2) {
                    margin-top: 20px;
                }
                &:nth-of-type(3n+3) {
                    margin-top: 20px;
                }
                &:nth-of-type(2n+1) {
                    margin-right: 20px;
                }
                &:nth-of-type(2n+2) {
                    margin-top: -10px;
                }
            }
        }
    }
}

@media all and (max-width: 940px) {
    .documents {
        h1 {
            font-size: 44px;
        }
        &-list {
            &__item {
                width: 48%;
            }
            &__content {
                max-width: 185px;
            }
        }
    }
}

@media all and (max-width: 700px) {
    .documents {
        h1 {
            margin-top: 0;
            margin-bottom: 40px;
            font-size: 30px;
        }
        &-list {
            justify-content: center;
            &__item {
                &:not(:nth-of-type(3n+3)) {
                    margin: 20px auto 0;
                }
                &:nth-of-type(3n+2) {
                    margin: 20px auto 0;
                }
                &:nth-of-type(3n+3) {
                    margin: 20px auto 0;
                }
                &:nth-of-type(2n+1) {
                    margin: 20px auto 0;
                }
                &:nth-of-type(2n+2) {
                    margin: 20px auto 0;
                }
                &:first-child{
                    margin-top: 0;
                }
                width: 410px;
                max-width: 100%;
            }
            &__content {
                max-width: 160px;
                p {
                    font-size: 12px;
                }
            }
        }
    }
}