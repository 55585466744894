@import "styles/variable";

.btn-cell {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ABC3D5;
  text-align: center;
  padding: 12px 0;
  margin-right: 8px;
  cursor: pointer;
  transition: .3s;
  &--active {
    transition: .3s;
    background: $color-blue-dark;
    color: #fff;
  }
}

@media screen and (min-width: 1000px) {
  .btn-cell:hover {
    background: $color-blue-lightest;
    color: $color-blue-dark;
  }
}