@import 'styles/variable';

.locations {
  margin-top: -110px;

  &-title {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 152px 0 110px;
    min-height: 406px;
    width: 100%;
    transition: 1s;
    opacity: 1;
    color: #ffffff;

    &--start {
      opacity: 0;
    }

    &__container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 80px;
    }

    &__text {
      line-height: 24px;
      max-width: 682px;
    }

    h2 {
      font-size: 64px;
      font-weight: 400;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
  }

  &-section {
    display: flex;
    flex-wrap: wrap;

    &__title {
      font-size: 54px;
      line-height: 72px;
      text-transform: uppercase;
      padding-right: 58px;
      min-width: 685px;
      max-width: 730px;
    }

    &__green-zones {
      min-width: 0;
      max-width: 630px;
    }
  }

  &-item {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    left: 0;
    opacity: 1;
    transition: 1s;

    &--start-left {
      left: -100px;
      opacity: 0;
    }

    &--start-right {
      left: 100px;
      opacity: 0;
    }
  }

  &-cell {
    max-width: 640px;
  }

  &__pic {
    display: block;
    width: 100%;
    height: auto;

    &--one {
      margin: -75px 0 -104px;
    }
  }

  &__text {
    padding-top: 107px;
    padding-left: 58px;

    &--pt {
      padding-top: 37px;
    }

    &--max_width {
      max-width: 640px;
    }
  }

  &-culture {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: 62%;
      height: 65%;
      background: rgba(134, 134, 159, 0.1);
      z-index: -1;
    }
  }

  &-education {
    display: flex;
    flex-wrap: wrap;

    &__title {
      width: 100%;
      max-width: 1440px;
      margin: 0 auto;
      padding: 50px 80px 45px;
      font-size: 64px;
      line-height: 72px;
      text-transform: uppercase;
      text-align: center;
      opacity: 1;
      transition: 1s;

      &--start {
        opacity: 0;
      }
    }

    &__text {
      padding-right: 58px;

      &--right {
        padding-right: 0;
        padding-left: 65px;
      }

      &-title {
        font-size: 40px;
        line-height: 50px;
        text-transform: uppercase;
        margin-bottom: 35px;
        font-weight: 400;
      }

      p {
        margin-bottom: 35px;

        span {
          font-size: 24px;
        }
      }

      strong {
        font-size: 24px;
        font-weight: 400;
      }
    }

    &__pic {
      width: 100%;
      display: block;
      height: auto;
    }
  }

  &-environment {
    padding-top: 80px;

    &__slider {
      margin-top: 50px;
      position: relative;
      opacity: 1;
      transition: 1s;

      &--start {
        opacity: 0;
      }

      &-item {
        position: relative;

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          content: '';
          //background: $gradient-green-image;
        }

        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      &-description {
        position: absolute;
        right: 40px;
        bottom: 40px;
        color: #fff;
      }

      .swiper-pagination {
        left: 80px;
        bottom: 40px;

        .swiper-pagination-bullet {
          height: 2px;
          width: 32px;
          background: #fff;
          opacity: 0.4;
          margin-right: 8px;
          cursor: pointer;

          &-active {
            opacity: 1;
          }
        }
      }
    }
  }
}

.locations-environment .locations-item:nth-child(even) {
  padding-left: 65px;
}

.locations-district {
  position: relative;
  z-index: 1;
}

.locations-interlinks {
  background-color: $color-bg-aside;
  position: relative;
  right: 0;
  opacity: 1;
  transition: 1s;

  &--start-right {
    right: -100px;
    opacity: 0;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    padding: 80px 0px;
  }

  h2 {
    text-transform: uppercase;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    flex-direction: column;

    max-height: 224px;
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
  }

  &__item {
    position: relative;
    text-transform: uppercase;

    max-width: 365px;
    width: 100%;
    margin-bottom: 0;
    padding-left: 26px;

    a {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0.01em;
      color: $color-blue-dark;
    }

    &::before {
      content: "";
      position: absolute;
      top: 14px;
      left: 0;
      width: 20px;
      height: 2px;
      background-color: $color-blue-lighter;
    }
  }
}

@media (max-width: 1439px) {
  .locations {

    &-section__title,
    &-education__title {
      font-size: 40px;
      line-height: 52px;
      min-width: initial;
    }
  }

  .locations-interlinks {
    &__item {
      width: 33%;
    }
  }
}

@media all and (max-width: 1168px) {
  .locations {
    &-cell {
      max-width: calc(100% - 40px);
    }
  }

  .locations-interlinks {
    &__wrapper {
      row-gap: 20px;
      padding: 40px 0px;
      margin-top: 40px;
    }

    &__list {
      gap: 25px;
      max-height: 300px;
    }
  }
}

@media all and (max-width: 940px) {
  .locations {

    &-section__title,
    &-education__title {
      font-size: 30px;
      line-height: 38px;
    }

    &-title {
      h2 {
        font-size: 44px;
        line-height: 1.25;
      }
    }

    &-education {
      &__text {
        &-title {
          font-size: 30px;
          line-height: 36px;
          margin-bottom: 20px;
        }

        p span {
          font-size: 20px;
        }
      }
    }

    &-district {
      &__pic--one {
        margin: 0;
      }

      &__text {
        padding: 15px 0 15px 58px;
      }
    }

    &-environment {
      padding-top: 40px;

      &__sider {
        margin-top: 40px;
      }
    }
  }

  .locations-interlinks {
    &__list {
      row-gap: 20px;
      column-gap: 10px;
      max-height: 350px;
    }

    &__item {
      width: 50%;
    }
  }
}

@media all and (max-width: 700px) {
  .locations {
    &-title__container {
      padding: 0 20px;
    }

    &-item {
      width: 100%;
    }

    &-cell {
      width: 100%;
      margin: 0;
    }

    &-title {
      h2 {
        font-size: 30px;
        line-height: 1.25;
      }
    }

    &-section {
      &__title {
        font-size: 32px;
        line-height: 48px;
        margin-top: 0;
        margin-bottom: 16px;
        max-width: none;
        min-width: initial;
        padding-right: 0;
        text-align: center;
      }
    }

    &-district {
      &__pic {
        width: calc(100% + 40px);
        margin: 0 -20px;
      }

      &__text {
        padding: 40px 0;
      }
    }

    &-culture {
      &__pic {
        width: calc(100% + 40px);
        margin: 0 -20px;
      }

      &__text {
        padding: 40px 0;
      }
    }

    &-education {
      &__title {
        font-size: 40px;
        line-height: 48px;
        margin-top: 40px;
        margin-bottom: 16px;
        padding: 0;
      }

      &__pic {
        width: calc(100% + 40px);
        margin: 0 -20px;
      }

      &__text {
        padding: 40px 0;
      }
    }

    &-environment {
      padding-top: 0;
    }
  }

  .locations__pic {
    width: calc(100% + 40px);
    margin: 0 -20px;
  }

  .locations__pic--one {
    margin-top: 0;
    margin-bottom: 0;
  }

  .locations__text {
    padding: 20px 0 0;
  }

  .locations-environment .locations-item:nth-child(even) {
    padding-left: 0;
  }

  .locations-item:first-child .locations-section__title {
    margin-top: 40px;
  }

  .locations-environment__slider .swiper-pagination {
    bottom: 10px;
    left: 20px;
  }

  .locations-interlinks {
    h2 {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0;
    }

    &__list {
      gap: 15px;
      max-height: unset;
    }

    &__item {
      width: 100%;
    }
  }
}

@media all and (max-width: 420px) {
  .locations-section__title {
    font-size: 28px;
    line-height: 1.5;
  }
}
