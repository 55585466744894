@import "styles/variable";

.unsetPosition{
  position: unset !important;
}

.catalog-choice {
  padding-bottom: 80px;
}

.catalog-nav {
  display: flex;
  position: absolute;
  height: 218px;
  top: -24px;
  align-items: flex-end;
  justify-content: space-between;
  padding: 22px 20px;
  right: 0;
  background: $color-blue-dark;
  z-index: 1;
  width: 100%;
  max-width: 720px;

  &__link {
    color: #fff;
    text-transform: uppercase;
    padding-right: 28px;
    background: url("/files/images/svg/white-arrow-right.svg") right 35%/18px 14px no-repeat;
    font-weight: 600;
    font-size: 18px;

    &:hover{
      color: $color-yellow;
    }
  }

}

.catalog-more {
  margin: 0 auto;
  display: block;
}

.catalog-content {
  position: relative;
  min-height: 300px;
  margin-bottom: 40px;
}

.catalog-error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 300px;
  text-align: center;
  padding: 0 20px;
  p {
    max-width: 800px;
    text-transform: uppercase;
    font-size: 32px;
    opacity: 0.3;
    margin-bottom: 40px;
  }
}

@media (max-width: 1439px){
  .catalog-choice {
    max-width: 1400px;
  }
}

@media all and (max-width: 1350px){
  .catalog-nav {
    max-width: 400px;
  }
}

@media all and (max-width: 1000px){
  .catalog-nav {
    display: none;
  }
}