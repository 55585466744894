.lobby {
  margin-top: -110px;
  margin-bottom: -105px;

  .location-environment {
    margin-bottom: 105px;
  }

  &-title {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 152px 0 110px;
    min-height: 406px;
    width: 100%;
    transition: 1s;
    opacity: 1;
    color: #fff;

    &--start {
      opacity: 0;
    }

    &__container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 80px;
    }

    &__text {
      line-height: 24px;
      max-width: 682px;
    }

    h2 {
      font-size: 64px;
      font-weight: 400;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
  }

  &-section {
    display: flex;
    flex-wrap: wrap;

    &--two {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        background: rgba(134, 134, 159, 0.1);
        position: absolute;
        right: 72px;
        top: -174px;
        width: calc(50% + 72px);
        height: calc(100% - 61px);
      }
    }

    &--three {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        height: 471px;
        width: calc(50% - 98px);
        position: absolute;
        top: -227px;
        left: 0;
        background: rgba(134, 134, 159, 0.1);
      }
    }
  }

  &-item {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    left: 0;
    opacity: 1;
    transition: 1s;

    &--four {
      top: -105px;
    }

    &--start-left {
      left: -100px;
      opacity: 0;
    }

    &--start-right {
      left: 100px;
      opacity: 0;
    }
  }

  &-cell {
    max-width: 680px;
    padding-left: 40px;
    margin-left: auto;
    width: 100%;
  }

  &__pic {
    display: block;
    width: 100%;
    height: auto;

    &--one {
      position: relative;
      top: -105px;
    }
  }

  &__text {
    position: relative;
    top: -54px;

    &--one {
      top: -107px;
    }

    &--right {
      padding-left: 25px;
    }

    &--left {
      padding-right: 75px;
    }

    ul {
      margin-bottom: 0;
    }
  }
}

@media all and (max-width: 1168px) {
  .lobby {
    &__pic--one {
      top: -45px;
    }

    &-section {
      &--two {
        margin-bottom: -45px;
      }
    }

    &-item--four {
      top: -45px;
    }
  }
}

@media all and (max-width: 940px) {
  .lobby {
    margin-bottom: 0;

    &__pic--one {
      top: 0;
    }

    &-section {
      flex-wrap: nowrap;

      &--two {
        margin-bottom: 0;
      }
    }

    &-item {
      width: auto;
    }

    &-item--four {
      top: 0;
    }

    &-title {
      h2 {
        font-size: 44px;
        line-height: 1.25;
      }
    }

    &__text {
      padding: 40px;
      top: 0;
      min-width: 430px;
    }

    &-cell {
      padding-left: 0;
    }
  }
  .lobby-section:before{
    display: none;
  }
  .lobby-section--two{
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 20px 0;
  }
  .lobby-section--two:before{
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media all and (max-width: 700px) {
  .lobby {
    &-cell {
      padding: 20px 0;
    }

    &-title__container {
      padding: 0 20px;
    }

    &-section{
      flex-wrap: wrap;
    }

    &-item {
      width: 100%;
    }

    &__pic {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }

    &-title {
      h2 {
        font-size: 30px;
        line-height: 1.25;
      }
    }

    &__text {
      padding: 20px 0;
      min-width: auto;
    }
  }

  .lobby-section--three:before {
    width: 100%;
  }
  .lobby-section--two {
    padding: 0 20px;
    margin: 0;
    flex-direction: column-reverse;
  }
  .lobby-section--two:before {
    display: none;
  }

}