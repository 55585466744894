.range-section {
  display: flex;
  align-items: center;
}

.range-item {
  width: 20px;
  flex-shrink: 0;
  &_one {
    width: 30px;
  }
}

.range-section {
  .rc-slider {
    margin: 0 15px 0 10px;
    height: 12px;
  }

  .rc-slider-rail {
    height: 1px;
    background-color: rgb(135, 135, 160);
  }

  .rc-slider-track {
    height: 1px;
    background-color: rgb(30, 50, 75);
  }

  .rc-slider-handle {
    margin-top: -6px;
    height: 12px;
    width: 12px;
    background-color: rgb(30, 50, 75);
    border: none;
    box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px, rgba(34, 36, 38, 0.15) 0px 0px 0px 1px inset;
    opacity: 1;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: transparent;
    box-shadow: none;
  }
}
