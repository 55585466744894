.layout {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout__main {
  background: linear-gradient(180deg, #ABC3D5 0%, rgba(238, 243, 247, 0) 100%) top no-repeat;
  background-size: 100% 208px;
  padding-top: 110px;
  //flex-grow: 1;
  overflow: hidden;
}

.layout__padding {
  padding-top: 0;
}