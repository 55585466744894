.underground {
  display: flex;
  padding-bottom: 120px;
  flex-wrap: wrap;
  align-items: flex-start;
  overflow: hidden;
  .back {
    margin-bottom: 20px;
  }
  &-item {
    padding-right: 32px;
    width: 50%;
    position: relative;
    left: 0;
    opacity: 1;
    transition: 1s;
    &--start {
      left: -60px;
      opacity: 0;
    }
  }
  &__title {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 80px;
    h1 {
      max-width: 500px;
      text-transform: uppercase;
      margin-bottom: 45px;
      margin-top: 142px;
    }
  }
  &-cell {
    width: 100%;
    max-width: 640px;
    margin-left: auto;
    padding-left: 40px;
    p {
      max-width: 460px;
      &:nth-last-of-type(1) {
        margin-bottom: 129px!important;
      }
    }

  }
  &-data {
    > p {
      max-width: 564px;
      margin-bottom: 40px;
    }
  }
  &__pic {
    width: 50%;
    position: relative;
    right: 0;
    opacity: 1;
    transition: 1s;
    margin-top: -253px;
    z-index: 1;
    &--start {
      right: -60px;
      opacity: 0;
    }
  }
  &-slider {
    &__item {
      img {
        width: 100%;
        height: auto;
      }
    }
    &__btn {
      bottom: 30px;
      display: none;

      &:nth-last-of-type(2) {
        left: -48px;
      }
    }
  }
  &-actions {
    position: absolute;
    left: -48px;
    bottom: 17%;
    display: flex;
  }
  &-more {
    width: 290px;
    height: 290px;
    display: block;
    padding: 18px 20px;
    position: absolute;
    right: 0;
    bottom: -122px;
    opacity: 1;
    transition: .5s;
    filter: none;
    &:hover {
      filter: drop-shadow(20px 20px 20px rgba(0, 0, 0, 0.2));
      right: 10px;
      bottom: -112px;
    }
    &--down {
      bottom: -152px;
      &:hover {
        bottom: -142px;
      }
    }
    &--up {
      bottom: -92px;
      &:hover {
        bottom: -82px;
      }
    }
    &--storage {
      background: #C8A0AF;
    }
    &--parking {
      background: #213E60;
    }
    p {
      font-size: 16px;
      color: white;
      line-height: 150%;
      margin-bottom: 5px;
    }
    span {
      display: block;
      font-size: 16px;
      line-height: 150%;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 12px;
    }
    img {
      display: block;
      margin: 0 auto 10px auto;
      max-width: 141px;
      height: auto;
    }
    .signature {
      font-size: 60px;
      color: rgba(255, 255, 255, 0.3);
      font-weight: 200;
      margin-bottom: 0;
      line-height: 1;
      margin-left: -27px;
    }
  }
  &__switcher {
    max-width: 170px;
    margin-right: 30px;
  }
}

@media all and (max-width: 1439px) {
  .underground {
    &-cell {
      max-width: 548px;
      padding-left: 40px;
      padding-bottom: 20px;
    }
    &__desc {
      max-width: 470px;
    }
    &__title {
      max-width: 1168px;
      padding: 0 40px;
    }
  }
}

@media all and (max-width: 1168px) {
  .underground {
    &__title {
      padding: 0px 40px;
      h1 {
        margin-top: 30px;
      }
    }
    &__desc {
      max-width: 380px;
      margin-bottom: 100px!important;
    }
    &__pic {
      margin-top: -115px;
    }
  }
}

@media all and (max-width: 940px) {
  .underground {
    position: relative;
    &__title {
      h1 {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }
    &-item {
      z-index: 2;
      background: #ffffff;
    }
    &-cell {
      padding-bottom: 40px;
    }
    &__desc {
      margin-bottom: 0!important;
      max-width: 296px;
    }
    &__pic {
      margin-top: 0;
      button {
        bottom: 146px;
      }
      &--storage {
        button {
          bottom: 123px;
        }
      }
    }
    .floor-scheme {
      margin-top: 0;
      padding-top: 120px;
      .section {
        padding-bottom: 100px;
      }
    }
    &-more {
      bottom: -173px;
      right: 20px;
      &:hover{
        bottom: -173px;
        right: 20px;
      }
      &--down{
        &:hover{
          bottom: -173px;
        }
      }
    }
  }
}

@media all and (max-width: 700px) {
  .floor-scheme{
    margin-bottom: 0;
  }
  .underground {
    padding-bottom: 0;
    &__title {
      padding: 0 20px;
      order: 1;
      h1 {
        margin-top: 0;
        margin-bottom: 31px;
      }
    }
    &-item {
      order: 3;
      width: 100%;
      position: static;
      z-index: 1;
    }
    &__pic {
      order: 2;
      position: relative;
      top: 0;
      max-width: 100%;
      width: 100%;
      button {
        bottom: -24px;
        &.slick-prev {
          right: 68px;
          left: auto;
        }
        &.slick-next {
          right: 20px;
          left: auto;
        }
      }
    }
    &-cell {
      max-width: 100%;
      padding: 49px 20px 44px 20px;

      p:nth-last-of-type(1) {
        margin-bottom: 5px!important;
      }
    }
    &__desc {
      max-width: 100%;
    }
    &-more {
      position: relative;
      bottom: auto;
      right: auto;
      order: 3;
      width: 100%;
      &:hover{
        bottom: auto;
        right: auto;
      }
    }
    .floor-scheme {
      order: 4;
      padding-top: 34px;
      &__desc {
        bottom: auto;
        top: 0;
        p {
          margin-bottom: 10px;
        }
      }
      &__link {
        position: absolute;
        bottom: 134px;
      }
      .section {
        padding-bottom: 0;
      }
    }
    &__switcher{
      margin-bottom: 10px;
    }
  }
}