@import "styles/variable";

.architecture {
  margin-top: -110px;

  &-title {
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    padding: 152px 0 80px;
    min-height: 406px;
    width: 100%;
    transition: 1s;
    opacity: 1;
    color: #fff;

    &--start {
      opacity: 0;
    }

    &__container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 80px;
    }

    &__text {
      line-height: 24px;
      max-width: 682px;
    }

    &__name {
      margin-bottom: 24px;
      text-transform: uppercase;
      font-size: 64px;
      font-weight: 400;
    }
  }

  &-section {
    display: flex;
    flex-wrap: wrap;

    &__title {
      font-size: 64px;
      line-height: 72px;
      text-transform: uppercase;
      padding-right: 58px;
      max-width: 530px;
    }

    &--one {
      position: relative;

      &:before {
        content: '';
        margin-right: -65px;
        height: calc(100% + 89px);
        width: calc(50% - 15px);
        position: absolute;
        right: 50%;
        bottom: -123px;
        background: rgba(134, 134, 159, 0.1);
        z-index: -1;
      }
    }

    &--two {
      margin-bottom: 70px;
      margin-top: -105px;
    }

    &--three {
      position: relative;

      &:before {
        content: '';
        margin-right: -30px;
        height: calc(100% - 50px);
        width: calc(50% - 50px);
        position: absolute;
        right: 50%;
        bottom: -30px;
        background: rgba(134, 134, 159, 0.1);
        z-index: -1;
      }
    }

    &--five {
      position: relative;

      &:before {
        content: '';
        margin-right: -30px;
        height: calc(100% + 35px);
        width: calc(50% - 130px);
        position: absolute;
        right: 50%;
        bottom: 0;
        background: rgba(134, 134, 159, 0.1);
        z-index: -1;
      }
    }
  }

  &-item {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    left: 0;
    opacity: 1;
    transition: 1s;

    &--three {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        height: calc(100% - 50px);
        width: calc(100% - 50px);
        position: absolute;
        right: -30px;
        bottom: -30px;
        background: rgba(134, 134, 159, 0.1);
        z-index: -1;
      }
    }

    &--start-left {
      left: -100px;
      opacity: 0;
    }

    &--start-right {
      left: 100px;
      opacity: 0;
    }
  }

  &-cell {
    max-width: 638px;
    width: 100%;
    margin-left: auto;

    .architecture-section--one & {
      margin-top: 24px;
    }

    &--left {
      padding-right: 40px;
    }

    &--right {
      margin-left: 0;
      padding-left: 40px;
    }
  }

  &__pic {
    display: block;
    width: 100%;
    height: auto;

    &--one {
      margin-bottom: -39px;
      position: relative;
      top: -39px;
    }

    &--four {
      margin-top: -46px;
    }
  }

  &__text {
    padding-right: 94px;

    &--one {
      padding-bottom: 105px;
    }

    &--right {
      padding-right: 0;
      padding-left: 26px;
    }

    &-title {
      font-size: 64px;
      line-height: 72px;
      margin-bottom: 16px;
    }
  }

  &-banner {
    width: 100%;
    opacity: 1;
    transition: 1s;

    &--start {
      opacity: 0;
    }

    &__pic {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}

@media all and (max-width: 1439px) {
  .architecture-section--one:before {
    bottom: -61px;
    height: calc(100% + 24px);
  }
}

@media all and (max-width: 1168px) {
  .architecture {
    &__pic--one {
      top: -45px;
      margin-bottom: -45px;
    }

    &__text {
      &--one {
        padding-bottom: 45px;
      }

      &-title {
        font-size: 56px;
        line-height: 64px;
      }
    }

    &-section--two {
      margin-top: -45px;
    }
  }
  .architecture-section:not(.section) .architecture-cell--left {
    padding-left: 40px;
  }
  .architecture-section:not(.section) .architecture-cell--right {
    padding-right: 40px;
  }
  .architecture-section--one:before {
    margin-right: -20px;
    bottom: -20px;
    height: calc(100% + 24px);
    width: calc(50% - 47px);
  }
}

@media all and (max-width: 940px) {
  .architecture {
    &-title__name {
      font-size: 44px;
    }
    &__pic--one {
      top: 0;
      margin-bottom: 0;
    }

    &-section {
      flex-wrap: nowrap;
      &--two {
        margin-top: 0;
      }
    }

    &-item{
      width: auto;
    }

    &__text {
      padding: 20px 40px;
      min-width: 430px;

      &--one {
        padding-bottom: 0;
      }

      &-title {
        font-size: 36px;
        line-height: 44px;
      }
    }

    &-cell {
      padding-left: 0;
    }
  }
  .architecture-section--one:before,
  .architecture-section--three:before,
  .architecture-section--five:before{
    margin-right: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
  }
}

@media all and (max-width: 700px) {
  .architecture {
    &-title__name {
      font-size: 30px;
    }
    &-section {
      flex-direction: column;

      &:nth-child(even) {
        flex-direction: column-reverse;
      }
    }

    &-cell {
      margin: 0 auto;
      padding: 20px 0;
    }

    &-title__container {
      padding: 0 20px;
    }

    &-item {
      width: 100%;
    }

    &__pic {
      width: calc(100% + 40px);
      margin: 0 -20px;
    }

    &__text {
      padding: 0;
      min-width: auto;
    }

    &-section--two {
      margin-bottom: 0;
    }
  }
  .architecture-section--one .architecture-cell {
    margin-top: 0;
  }
  .architecture-section--one:before {
    margin: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
  }
  .architecture-section--three:before {
    margin: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
  }
  .architecture-section--five:before {
    margin: 0;
    width: 100%;
    height: 100%;
    right: 0;
    bottom: 0;
  }
  .architecture-section:not(.section) .architecture-cell--left {
    padding-left: 20px;
  }
}