@import "styles/variable";

.contacts {
    margin-top: -110px;
    padding-top: 110px;
    background: url("/files/images/contacts/contacts-bg.svg") top right no-repeat;
    h1 {
        margin-top: 60px;
        margin-bottom: 50px;
        text-transform: uppercase;
        line-height: 0.6;
    }
    &-main {
        width: 50%;
    }

    &-wrapper {
        max-width: 640px;
        margin-left: auto;
    }
    &-block {
        display: flex;
    }
    &-item {
        width: 50%;
        position: relative;
        transition: 1s;
        opacity: 1;
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
        &--start_left {
            opacity: 0;
            left: -60px;
        }
        &.animation {
            right: -60px;
            opacity: 0;
        }
    }
    &-cell {
        max-width: 640px;
        margin-left: auto;
    }
    &-details {
        margin-bottom: 82px;
        p {
            color: $color-blue-dark;
            font-family: $font-geo;
            font-size: 16px;
            line-height: 16px;
            margin-bottom: 22px;
            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
            a {
                font-weight: 500;
                font-size: 16px;
                color: $color-blue-dark;
                transition: .5s;
                &:hover {
                    opacity: .8;
                }
            }
        }
        .contacts__title {
            font-weight: 500;
            font-size: 18px;
        }
    }
    &-connection {
        display: flex;
        align-items: flex-start;
        font-family: $font-mont;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 18px;
        margin-bottom: 64px;
        &__link {
            color: $color-blue-darker;
            padding: 13px 16px;
            border: 1px solid transparent;
            margin-right: 15px;
            cursor: pointer;
            flex-shrink: 1;
            &--bordered {
                border-color: $color-blue-lighter;
            }
            &:hover {
                background: $color-blue-lightest;
            }
        }
    }
    &__map {
        margin-top: -108px;
        position: relative;
        img {
            max-width: 640px;
        }
    }
    &-slider {
        width: calc(100% + 80px);
        &__item{
            display: block!important;
            img {
                width: 100%;
                height: auto;
                display: block!important;
            }
        }
        button {
            bottom: 66px;
            &.slick-prev {
                right: 0;
            }
            &.slick-next {
                right: -48px;
            }
        }
    }
    &__switcher {
        max-width: 400px;
        margin-bottom: 37px;
        .switcher__item {
            transition: .5s;
        }
    }
}
.navigator {
    height: 48px;
    padding: 13px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    //background-image: url("/files/images/contacts/navigator.svg");
    //background-repeat: no-repeat;
    //background-position: 95% 40%;
    //background-size: 22px 20px;
    color: #2C3F56;
    transition: .7s;
    &:hover {
        background-color: $color-blue-lightest;
        background-size: 24px 22px;
        transition: .7s;
    }

    img {
        margin-left: 10px;
    }
}

@media all and (max-width: 1439px) {
    .contacts {
        &-cell {
            max-width: 584px;
            padding-left: 80px;
        }
        &-wrapper {
            max-width: 584px;
            padding-left: 80px;
        }
        &__desc {
            margin-right: 20px;
        }
        &__map {
            img {
                width: 100%;
            }
        }
        &-connection {
            flex-wrap: wrap;
            &__link {
                margin-bottom: 20px;
            }
        }
    }
}


.leaflet__map{
  width: 640px;
  height: 700px;
}

.leaflet__map--noCursor .leaflet-marker-icon.leaflet-interactive,
.leaflet-marker-icon.leaflet-interactive.leaflet-marker-icon--no-hover,
.leaflet-marker-icon.leaflet-interactive.leaflet-marker-static-icon
{
  pointer-events: none;
}

@media all and (max-width: 1168px) {
    .contacts {
        &-connection {
            &__link {
                font-size: 14px;
            }
        }
        &-cell {
            padding-left: 40px;
        }
        &-wrapper {
            padding-left: 40px;
        }
    }

  .leaflet__map{
    width: 100%;
    height: 500px;
  }
}

@media all and (max-width: 940px) {
    .contacts {
        background: none;
        &-connection {
            flex-wrap: wrap;
            margin-bottom: 30px;
            margin-right: 20px; 
            &__link {
                margin-bottom: 15px;
            }
        }
        &-slider {
            width: calc(100% + 200px);
        }
        &__switcher {
            margin-right: 20px;
        }
    }
    .navigator {
        margin-bottom: 15px;
    }
}

@media all and (max-width: 700px) {
    .contacts {
        flex-wrap: wrap;
        &-wrapper {
            padding: 0;
            max-width: 100%;
        }
        &-main {
            max-width: 100%;
            padding: 0 20px;
            width: 100%;
        }
        &-block {
            flex-direction: column;
        }
        &-item {
            width: 100%;
        }
        &-cell {
            max-width: 100%;
            padding: 0 20px;
        }
        h1 {
            margin-top: 0;
        }
        &-connection {
            margin-right: 0;
        }
        &__switcher {
            margin-right: 0;
        }
        &-slider {
            width: 100%;
            .slick-arrow {
                bottom: 0;
                &.slick-prev {
                    left: calc(50% - 48px);
                }
                &.slick-next {
                    left: 50%;
                }
            }
        }
        &__map {
            margin-top: 0;
        }

        &-details {
            margin-bottom: 45px;
        }
    }
}

@media all and (max-width: 420px) {
    .contacts-connection__link, .navigator {
        width: 100%;
        max-width: 100%;
        margin: 0 auto 15px;
        font-size: 16px;
    }
}