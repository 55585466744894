@import "styles/variable";

.favorites {
    &-nav {
        display: flex;
        position: absolute;
        height: 218px;
        top: -24px;
        align-items: flex-end;
        justify-content: flex-start;
        padding: 22px 20px;
        right: 0;
        background: $color-blue-dark;
        z-index: 1;
        width: 100%;
        max-width: 720px;
        &__link {
            color: #fff;
            text-transform: uppercase;
            padding-right: 28px;
            font-size: 18px;
            margin-right: 25px;
            cursor: pointer;
            line-height: 1;
            white-space: nowrap;
            &:hover {
                color: $color-yellow-light;
            }
            &--compaire {
                background: url("/files/images/svg/white-arrow-right.svg") right 35%/18px 14px no-repeat;
                font-weight: 600;
            }
            &--share {
                background: url("/files/images/favorites/share.svg") right 35%/18px 14px no-repeat;
            }
            &--delete {
                background: url("/files/images/favorites/delete.svg") right 35%/18px 14px no-repeat;
            }
        }
    }
    h1 {
        text-transform: uppercase;
        margin-top: 26px;
    }
}

.offer {
    display: flex;
    margin-bottom: 66px;
    &__img {
        position: relative;
        width: 50%;
        overflow: hidden;

        img {
            height: 100%;
            width: auto;
        }
    }
    &__text {
        width: 50%;
        padding: 31px 38px;
        background: linear-gradient(341.93deg, #7C8CB6 3.99%, #7F8CB0 52.17%, #63729A 119.62%);
        color: #fff;
    }
    &__title {
        font-size: 30px;
        line-height: 38px;
        font-family: $font-geo;
        text-transform: uppercase;
        font-weight: 300;
        margin-bottom: 12px;
    }
    &__button {
        margin-top: 17px;
        color: #fff;
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
}

@media all and (max-width: 1439px) {
    .favorites-nav {
        max-width: 648px;
        &__link {
            font-size: 16px;
        }
    }
}

@media all and (max-width: 1168px) {
    .favorites{
        h1 {
            font-size: 54px;
        }
    }
    .offer__title {
        font-size: 24px;
        line-height: 32px;
    }
    .favorites-nav {
        display: none;
    }
}

@media all and (max-width: 940px) {
    .favorites{
        h1 {
            font-size: 44px;
        }
    }
    .offer__title {
        font-size: 18px;
        line-height: 24px;
    }
}

@media all and (max-width: 700px) {
    .offer {
        flex-wrap: wrap;
        &__img {
            width: 100%;
            height: 180px;
        }
        &__text {
            width: 100%;
        }
    }
    .favorites{
        h1 {
            margin-top: 0;
            font-size: 30px;
        }
    }
}