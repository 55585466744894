@import "styles/variable";

.offer {
  &-title {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 155px 20px 90px;
    min-height: 405px;
    margin-top: -110px;

    div {
      max-width: 916px;
      margin: 0 auto;
    }

    h1 {
      color: white;
      text-transform: uppercase;
      max-width: 458px;
      line-height: 1.25;
    }
  }
  &-content {
    @extend %center;
    padding: 40px 20px 67px;

    h2 {
      font-size: 22px;
      margin-top: 49px;
      margin-bottom: 15px;
      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}