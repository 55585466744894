@import "styles/variable";

.tooltip {
    position: relative;
    display: inline-block;
    width: 0;
    height: 0;

    .tooltip-text {
        visibility: hidden;
        min-width: 180px;
        background: linear-gradient(rgba(255, 255, 255, 0), #ffffff 70%);
        text-align: center;
        padding: 0.5em 0;
        color: black;
        bottom: 100%;
        left: 0;
        transform: translate(0, -33px);
        font-size: 14px;
        line-height: 1.2;

        user-select: none;
        position: absolute;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            bottom: -7px;
            border-top: 8px solid #ffffff;
            border-right: 10px solid transparent;
        }

        @media (max-width: 940px) {
            transform: translate(0, -23px);
        }

        @media (max-width: 700px) {
            transform: translate(0, -17px);
        }
    }

    &--right {
        .tooltip-text {
            left: auto;
            right: 0;

            &::before {
                left: auto;
                right: 0;
                border-style: solid;
                border-width: 0 8px 10px 0;
                border-color: transparent #ffffff transparent transparent;
            }
        }
    }

    &--bottom {
        .tooltip-text {
            bottom: auto;
            top: 100%;
            transform: translate(0, 33px);
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

            @media (max-width: 940px) {
                transform: translate(0, 23px);
            }
            @media (max-width: 700px) {
                transform: translate(0, 17px);
            }

            &::before {
                top: -7px;
                bottom: auto;
                border-style: solid;
                border-width: 10px 0 0 8px;
                border-color: transparent transparent transparent #ffffff;
            }
        }
    }
}

.tooltip:hover .tooltip-text,
.tooltip._active .tooltip-text {
    visibility: visible;
}


.finish {
    overflow: hidden;

    &-scheme {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;

        &__ratio {
            margin: 0 !important;
            max-width: none !important;
            width: auto !important;
            height: 100% !important;
            object-fit: none !important;
            opacity: 0;
        }

        &__pic {
            position: absolute;
            margin: 0 !important;
            bottom: 0;
            right: 0;
            cursor: pointer;
            width: 80px !important;
            height: auto !important;

            @media (max-width: 700px) {
                width: 46px !important;
                height: auto !important;
            }
        }

        &__popup {
            background-color: white;

        }

        &--ellipse {
            margin: 0;
            animation: 500ms ellipse;
            max-width: none;
            width: 48px;
            height: 49px;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: rgba(135, 135, 160, 0.21);
            border: 1px solid #8787A0;
            border-radius: 50%;

            @media (max-width: 940px) {
                //margin-left: -8px;
                width: 30px;
                height: 30px;
            }

            @media (max-width: 700px) {
                //margin-left: -5px;
                width: 20px;
                height: 20px;
            }
        }

        &--ellipse:hover {
            cursor: pointer;
        }

        &--bulb {
            position: absolute;
            right: 50%;
            top: 4%;
            transform: translateX(-2px);
        }

        &--socket {
            position: absolute;
            right: 61%;
            top: 68%;
            transform: translateY(2px);
        }

        &--battery {
            position: absolute;
            right: 32%;
            top: 65%;
        }
    }

    &--section_one {
        padding-top: 200px;
        margin-top: -110px;

        &::after {
            content: "";
            position: absolute;
            top: 258px;
            left: 0;
            width: 950px;
            max-width: 100%;
            height: 950px;
            background: rgba(134, 134, 159, 0.1);
            z-index: -1;
        }

        .finish-title__desc {
            left: 0;

            &--start {
                left: -60px;
                opacity: 0;
            }
        }

        .finish-title__pic {
            right: 0;
            height: 100%;

            &--start {
                right: -60px;
                opacity: 0;
            }

            img {
                height: 100%;
            }
        }
    }

    &--section_three {
        padding-top: 88px;
        //background: $color-blue-dark;
        padding-bottom: 120px;

        .finish-title {
            max-width: 1440px;
            padding: 0;
            margin: 0 auto;
        }

        .finish-title__desc {
            margin-top: 90px;
            background: rgba(134, 134, 159, 0.1);
            color: $color-blue-dark;
            margin-left: auto;
            padding: 100px 200px 120px;
            max-width: 922px;
            right: 0;

            &--start {
                right: -60px;
                opacity: 0;
            }
        }

        .finish-one {
            position: relative;

            &:nth-of-type(3) {
                left: 0;
            }

            &:nth-of-type(4) {
                right: 0;
            }

            &--start {
                &:nth-of-type(3) {
                    left: -60px;
                }

                &:nth-of-type(4) {
                    right: -60px;
                }
            }
        }

        .finish-title__pic {
            right: auto;
            left: 0;
            filter: none;
            max-width: calc(100% - 760px);

            &--start {
                left: -60px;
                opacity: 0;
            }
        }

        .finish-one {
            margin-bottom: 0;
        }
    }

    &--section_two {
        padding-top: 110px;
        background: #1e324b;

        .finish-title__desc {
            left: 0;
            color: #fff;

            &--start {
                left: -60px;
                opacity: 0;
            }
        }

        .finish-one--width {
            width: 80%;
            margin-left: auto;
            position: relative;

            img {
                width: 100%;
                height: auto;
            }

            &.finish-one--start {
                &:nth-of-type(2n) {
                    left: -60px;
                }

                &:nth-of-type(2n + 1) {
                    right: -60px;
                }
            }

            &:nth-of-type(2n) {
                margin-right: auto;
                margin-left: 0;
                left: 0;
            }

            &:nth-of-type(2n + 1) {
                right: 0;
            }
        }

        //&::before {
        //  content: '';
        //  position: absolute;
        //  width: 50%;
        //  height: 660px;
        //  background: #fff;
        //  transform: matrix(-1, 0, 0, 1, 0, 0);
        //  right: 0;
        //}
    }

    &--section_four {
        position: relative;
        padding-bottom: 105px;
        padding-top: 30px;

        &::before {
            content: '';
            position: absolute;
            height: calc(100% - 166px);
            width: 100%;
            top: 166px;
            background: $color-blue-dark;
            z-index: -1;
        }

        .finish-title {
            max-width: 1440px;
            padding: 0;
            margin: 0 auto;
            z-index: 1;
        }

        .finish-title__pic {
            right: auto;
            left: 0;
            filter: none;

            &--start {
                opacity: 0;
                left: -60px;
            }

            &::before {
                content: '';
                right: -40px;
                bottom: -40px;
                position: absolute;
                background: #fff;
                width: 652px;
                height: 396px;
                z-index: -1;
            }
        }

        .finish-title__desc {
            color: white;
            margin-left: auto;
            padding: 60px 200px 100px;
            max-width: 922px;
            right: 0;

            &--start {
                opacity: 0;
                right: -60px;
            }

            h2 {
                color: $color-blue-dark;
                white-space: nowrap;
            }
        }

        .finish-one:nth-of-type(3) {
            position: relative;
            z-index: 1;
        }

        .finish-one--width {
            width: 80%;
            margin-left: auto;
            position: relative;
            right: 0;

            &::before {
                content: '';
                width: 1002px;
                height: 396px;
                position: absolute;
                left: -40px;
                bottom: -258px;
                background: #fff;
                z-index: -1;
            }

            &.finish-one--start {
                right: -60px;
            }
        }

        .finish-one--center {
            max-width: 1010px;
            margin: -100px auto 0;
            position: relative;
            z-index: 1;

            &::before {
                content: '';
                position: absolute;
                background: #fff;
                width: 841px;
                height: 543px;
                top: -40px;
                left: -40px;
                z-index: -1;
            }
        }
    }

    &--section_five {
        padding-top: 180px;
        padding-bottom: 160px;
        position: relative;

        .finish-title__pic {
            top: 110px;
            z-index: 2;
            filter: none;
            right: 0;
            max-width: 694px;

            &--start {
                right: -60px;
                opacity: 0;
            }
        }

        .finish-title__desc {
            left: 0;

            &--start {
                left: -60px;
                opacity: 0;
            }
        }

        .finish-one {
            margin-bottom: 0;

            &:nth-of-type(2) {
                position: relative;
                z-index: 1;
                left: 0;
            }

            &:nth-last-of-type(1) {
                right: 0;
                position: relative;
            }

            &.finish-one--start {
                &:nth-of-type(2) {
                    left: -60px;
                }

                &:nth-last-of-type(1) {
                    right: -60px;
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            width: 60%;
            background: rgba(134, 134, 159, 0.1);
            transform: matrix(-1, 0, 0, 1, 0, 0);
            height: 660px;
            top: 248px;
        }
    }

    &-title {
        display: flex;
        position: relative;
        max-width: 1440px;
        padding: 0 80px 100px;
        width: 100%;
        margin: 0 auto;

        &__desc {
            max-width: 522px;
            position: relative;
            opacity: 1;
            transition: 1s;

            h2 {
                font-family: $font-geo;
                text-transform: uppercase;
                margin-bottom: 50px;
            }

            p {
                &:nth-last-of-type(1) {
                    margin-bottom: 30px;
                }
            }
        }

        &__pic {
            position: absolute;
            right: 0;
            opacity: 1;
            transition: 1s;
            z-index: 1;
            top: 0;
            filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
            overflow: hidden;
            line-height: 0;

            img {
                width: 100%;
                object-fit: cover;
            }

            @media (max-width: 940px) {
                overflow: visible;
            }
        }
    }

    &-one {
        margin-bottom: 60px;
        opacity: 1;
        transition: 1s;

        &--start {
            opacity: 0;
        }

        img {
            width: 100%;
            height: auto;
        }

        &__bg-left {
            position: relative;
            padding-top: 60px;
            max-width: 76%;
            margin-bottom: 60px;

            img {
                position: relative;
                z-index: 1;
                max-width: 100%;
            }

            &::after {
                content: "";
                position: absolute;
                top: -3px;
                right: -60px;
                background: #F3F3F5;
                width: 927px;
                height: 688px;
                z-index: 0;
            }

            &--five {
                z-index: 0;
                margin-bottom: -35px;

                &::after {
                    width: 1149px;
                    max-width: 100%;
                    height: 528px;
                    bottom: -60px;
                    right: -60px;
                    top: auto;
                    background: $color-bg-aside;
                }
            }
        }

        &__bg-right {
            position: relative;
            padding-bottom: 80px;
            padding-right: 80px;
            max-width: 76%;
            margin-left: auto;

            img {
                position: relative;
                z-index: 1;
                width: 100%;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                right: 0;
                background: #F3F3F5;
                width: 927px;
                max-width: 100%;
                height: 688px;
                z-index: 0;
            }

            &--five {
                padding: 0;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: -70px;
                    left: -70px;
                    background: $color-bg-aside;
                    width: 1149px;
                    height: 638px;
                    z-index: 0;
                }
            }
        }
    }

    &-wrapper {
        position: relative;
        transition: 1s all;
        line-height: 0;
    }

    &-two {
        display: grid;
        grid-template-columns: 56% auto;
        gap: 20px;
        justify-content: center;
        margin-bottom: 100px;
        align-items: flex-start;
        overflow: hidden;
        opacity: 1;
        transition: 1s;

        img {
            object-fit: cover;
            opacity: 1;
            transition: 1s;
            max-width: 100%;
            width: 100%;
            height: auto;
            //position: relative;

            &:nth-last-of-type(1) {
                //margin-left: 20px;
                //  right: 0;
            }

            &:nth-last-of-type(2) {
                //  left: 0;
            }
        }

        &--start {
            img {
                opacity: 0;

                &:nth-last-of-type(1) {
                    right: -60px;
                }

                &:nth-last-of-type(2) {
                    left: -60px;
                }
            }
        }
    }

    &-button {
        background: transparent;
    }

    &-three {
        //   position: relative;
        margin-top: -60px;
        margin-bottom: 60px;

        img {
            width: 100%;
            height: auto;
        }

        @media (max-width: 940px) {
            margin: 40px 0;
        }
    }
}

.finish--section_one .finish-title__pic .finish-scheme {
    width: 1366px;

    @media (max-width: 940px) {
        width: 100%;
    }
}

.finish-hint {
    &._opened {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.finish-hint__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    opacity: 0;
    visibility: hidden;

    ._opened & {
        opacity: 1;
        visibility: visible;
    }
}

.finish-hint__body {
    position: absolute;
    right: 140px;
    bottom: 137px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0s ease-in;

    ._opened & {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s 1s ease-in, visibility 0.3s 1s ease-in;
    }

    @media (max-width: 700px){
        right: 103px;
        bottom: 95px;
    }
}

.finish-hint__text {
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;

    &:after {
        content: '';
        margin-left: -10px;
        margin-top: -10px;
        position: absolute;
        top: 100%;
        left: 100%;
        width: 60px;
        height: 42px;
        background: url("/files/images/svg/hint-arrow.svg") center no-repeat;
    }
}

.finish-hint__btn {
    margin-top: 20px;
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    text-transform: none;
    box-sizing: content-box;
    border: 2px solid #ffffff;
    border-radius: 20px;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Montserrat';
    color: #ffffff;
    background: transparent;
}

.finish-breadcrumbs {
    max-width: 1280px;
    margin: 0 auto;
}

.page-nav {
    display: flex;
    position: absolute;
    height: 210px;
    top: -24px;
    align-items: flex-end;
    justify-content: space-between;
    padding: 39px 20px;
    right: 0;
    background: $color-blue-dark;
    z-index: 1;

    &__link {
        font-size: 18px;
        color: #fff;
        padding: 10px 0;
        margin: 0 16px;
        border-bottom: 2px solid transparent;
        transition: .5s;

        &:hover {
            border-color: #fff;;
        }
    }
}

.button--dark {
    color: #fff;

    &:hover {
        background: #1A2C41;
        color: #fff !important;
    }
}

@media all {
    .finish {
        &--section_one {
            .finish-title__pic {
                max-width: 57%;
            }
        }

        &--section_four {
            .finish-title__pic {
                max-width: 45%;
            }
        }
    }
}

@media all and (max-width: 1439px) {
    .finish {
        &--section_one {
            .finish-title__desc {
                max-width: 52%;
            }

            .finish-title__pic {
                max-width: 47%;
            }
        }

        &--section_two {
            .finish-title__pic {
                max-width: 42%;
            }

            .finish-title__desc {
                max-width: 68%;
            }
        }

        &--section_four {
            .finish-title__pic {
                max-width: 42%;

                &::before {
                    bottom: -33px;
                }
            }

            .finish-title__desc {
                max-width: 68%;
                padding-top: 78px;
            }

            .finish-one--width {
                &::before {
                    left: auto;
                    right: 0;
                }
            }
        }

        &--section_five {
            .finish-title__desc {
                max-width: 42%;
            }

            .finish-title__pic {
                max-width: 54%;
            }
        }
    }
}

@media all and (max-width: 1168px) {
    .finish {
        &--section_one {
            .finish-title {
                padding: 0 40px 100px;
            }

            .finish-two {
                display: block;
            }

            .finish-two img:nth-last-of-type(2) {
                width: 100%;
                height: auto;
            }

            .finish-two img:nth-last-of-type(1) {
                height: auto;
                width: 100%;
            }
        }

        &--section_two {
            .finish-title__desc {
                padding: 100px 100px 100px 120px;
            }
        }

        &--section_three {
            .finish-title {
                flex-wrap: wrap;
            }

            .finish-title__pic {
                position: static;
                max-width: 100%;
                margin-bottom: 40px;
            }
        }

        &--section_four {
            .finish-title__desc {
                padding-right: 100px;
            }
        }

        &--section_five {
            padding-top: 100px;

            .finish-title__pic {
                top: 0;
            }
        }

        &-one__bg-left::after {
            right: 0;
            width: 867px;
            max-width: 100%;
        }
    }
    .finish--section_five:before {
        top: 206px;
        height: 765px;
    }
}

@media all and (max-width: 940px) {
    .finish {
        &-title {
            flex-wrap: wrap;
            margin-bottom: 50px;

            &__desc {
                max-width: 100%;
            }
        }

        &-two {
            flex-wrap: wrap;

            img:first-of-type {
                margin-bottom: 40px;
            }

            img:nth-last-of-type(1) {
                margin-left: 0;
            }
        }

        &--section_one {
            .finish-title__pic {
                position: relative;
                margin-left: auto;
            }

            .finish-two img:nth-last-of-type(2), .finish-two img:nth-last-of-type(1) {
                width: 100%;
            }
        }


        &--section_one {
            padding-top: 110px;

            &::after {
                display: none;
            }

            .finish-title {
                padding: 0 20px;
            }

            .finish-title__pic {
                max-width: calc(100% + 40px);
                margin: 40px -20px 0;
            }
        }

        &--section_one .finish-title__desc {
            max-width: none;
        }

        &--section_two {
            padding-bottom: 0;

            .finish-title__desc {
                max-width: 100%;
                margin-bottom: 40px;
                padding: 40px;
            }

            .finish-title__pic {
                position: static;
                margin-left: auto;
                margin-top: -95px;
            }
        }

        &--section_three {
            padding-top: 0;

            .finish-title__desc {
                max-width: 100%;
                margin-bottom: 40px;
                padding: 40px;
            }
        }

        &--section_four {
            .finish-title__desc {
                max-width: 100%;
                padding: 78px 40px 50px;
            }

            .finish-title__pic {
                position: static;

                &::before {
                    right: 0;
                }
            }

            .finish-one--center::before {
                display: none;
            }
        }

        &--section_five {
            padding-top: 0;

            &:before {
                display: none;
            }

            .finish-title {
                padding: 0 40px;
            }

            .finish-title__desc {
                max-width: 100%;
                padding: 100px 0 50px;

                &:before {
                    content: "";
                    position: absolute;
                    width: calc(100% + 80px);
                    background: rgba(134, 134, 159, 0.1);
                    height: 100%;
                    top: 0;
                    left: -40px;
                }
            }

            .finish-title__pic {
                position: static;
                margin-left: auto;
            }
        }

        &-one {
            margin-bottom: 50px;

            &__bg-right {
                &::after {
                    display: none;
                }
            }
        }

        &-two {
            margin-bottom: 50px;
        }
    }
}

@media all and (max-width: 700px) {
    .finish {
        &-title__desc {
            h2 {
                margin-bottom: 25px;
                font-size: 30px;
            }
        }

        &-title {
            padding: 0 20px;
        }

        &--section_one {
            padding-top: 110px;

            &::after {
                display: none;
            }

            .finish-title {
                padding: 0 20px;
            }

            .finish-title__pic {
                max-width: calc(100% + 40px);
                margin: 40px -20px 0;
            }
        }

        &--section_one .finish-title__desc {
            max-width: none;
        }

        &--section_two {
            padding-top: 30px;

            .finish-title__desc {
                padding: 40px 20px;
                margin-top: 0;
            }

            .finish-title__pic {
                max-width: 100%;
                margin: 0 0 40px 0;
            }
        }

        &--section_three {
            padding-bottom: 30px;

            .finish-title {
                padding: 0 20px;
            }

            .finish-one--width {
                width: 100%;
            }
        }

        &--section_four {
            padding-bottom: 0;
            padding-top: 30px;
            background: #1e324b;

            &::before {
                display: none;
            }

            .finish-title__desc {
                padding: 40px 20px;

                h2 {
                    color: #fff;
                    white-space: normal;
                }
            }

            .finish-title__pic {
                max-width: 100%;
            }

            .finish-one--width {
                width: 100%;
            }

            .finish-one--center {
                margin: 0;
            }
        }

        &--section_five {
            padding-bottom: 0;
            background: rgba(134, 134, 159, 0.1);

            &:before {
                display: none;
            }

            .finish-title {
                padding: 0 20px;
            }

            .finish-title__desc {
                padding-top: 70px;
            }

            .finish-title__pic {
                max-width: calc(100% + 40px);
                margin: 0 -20px;
            }

            .finish-one {
                margin-bottom: 40px;
            }
        }

        &-one__bg-left::after {
            display: none;
        }

        &-one__bg-left--five {
            padding-top: 0;
            margin-bottom: 40px;
        }

        &-one__bg-right {
            padding: 0;
        }

        &-two {
            flex-wrap: wrap;

            img {
                max-width: 100%;

                &:first-of-type {
                    margin-bottom: 40px;
                }

                &:nth-last-of-type(1) {
                    margin-left: 0;
                }
            }
        }
    }
}

@keyframes ellipse {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
