@media all and (max-width: 1439px) {
    .section {
      max-width: 1168px;
    }
}
  
@media all and (max-width: 1168px) {
    .section {
        padding: 0px 40px;
    }
    .breadcrumbs {
      ul {
        padding-left: 40px;
      }
    }
  .right-nav {
    top: 50%;
    transform: translateY(-50%);
    z-index: 99;
  }
}

@media all and (max-width: 1000px){
  .right-nav {
    display: none;
  }

  .modal-open {
    padding-right: 0!important;
  }
}

@media all and (max-width: 940px) {
    .empty {
      p {
        font-size: 20px;
      }
    }
    .modal{
      &__form {
        flex-direction: column;
        width: 600px;
        left: calc(50% - 300px);
        top: calc(50% - 300px);
      }
      &__left {
        width: 100%;
        padding: 0 32px 40px;
      }
      &__right {
        order: -1;
      }
      &__pic {
        display: none;
      }
    }
}
  
@media all and (max-width: 700px) {
    .section {
      padding: 0px 20px;
    }
    .title {
      font-size: 30px;
      line-height: 40px;
    }
    .modal {
      overflow: scroll;
      &__form {
        width: calc(100% - 40px);
        left: 20px;
        top: 30px;
      }
      &__title {
        font-size: 24px;
      }
      &__left {
        padding: 0 20px 20px;
      }
      &__right {
        padding: 20px;
      }
      &__settime {
        flex-wrap: wrap;
        width: 100%;
        p:first-of-type {
          width: 100%;
        }
      }
    }
    .breadcrumbs {
      ul {
        padding-left: 20px;
      }
    }
}