@import "styles/variable";

.about {
    margin-top: -110px;
    position: relative;
    &__h2 {
        font-size: 64px;
        text-transform: uppercase;
        margin-bottom: 24px;
        line-height: 96px;
        position: relative;
        opacity: 1;
        left: 0;
        transition: 1s;
        &--start {
            left: -80px;
            opacity: 0;
        }
        &--location {
            text-align: center;
            color: #fff;
            position: relative;
            z-index: 1;
        }
        &--environment {
            text-align: center;
            margin-bottom: 33px;
        }
        &--improvement {
            margin-top: 80px;
            margin-bottom: 40px;
            span {
                display: none;
            }
            br {
                display: none;
            }
        }
        &--views {
            color: #fff;
            max-width: 315px;
        }
        &--premises {
            line-height: 72px;
        }
        &--commercial {
            color: #fff;
            max-width: 648px;
        }
    }
    &-top {
        height: 405px;
        background: url("/files/images/about/about-top.jpg") top center / cover no-repeat;
        padding-top: 170px;
        h1 {
            color: #fff;
        }
    }
    &-item {
        width: 50%;
        z-index: 1;
        position: relative;
        opacity: 1;
        transition: 1s;
        &--right {
            right: 0;
        }
        &--left {
            left: 0;
        }
        &--start {
            &_right {
                right: -80px;
                opacity: 0;
            }
            &_left {
                left: -80px;
                opacity: 0;
            }
        }
    }
    &-cell {
        max-width: 640px;
        margin-left: auto;
        &--right {
            margin-right: auto;
            margin-left: 0;
        }
    }
    &-description {
        display: flex;
        margin-top: 90px;
        &__text {
            p {
                font-size: 18px;
                max-width: 538px;
            }
        }
        &__numerals {
            display: flex;
            flex-wrap: wrap;
            position: absolute;
            right: 80px;
            top: -59px;
            transition: 1s;
            opacity: 1;
            justify-content: flex-start;
            align-items: flex-start;
            &--start {
                right: 0;
                top: -29px;
                opacity: 0;
            }
        }
        &__item {
            background: #FAFAFB;
            width: 100%;
            max-width: 340px;
            margin-bottom: 20px;
            padding: 34px 50px;
            transition: .5s;
            min-height: 152px;
            cursor: pointer;
            &--entitled {
                padding-top: 27px;
                margin-top: -78px;
                min-height: 200px;
            }
            &:nth-of-type(3) {
                margin-top: -30px;
            }
            &:nth-of-type(4) {
                padding-left: 46px;
                padding-right: 36px;
                padding-top: 49px;
            }
            &:nth-of-type(2n+1) {
                margin-right: 20px;
            }
            &:hover {
                background: #213E60;
                color: #fff;
            }
        }
        &__title {
            font-size: 18px;
            color: $color-yellow-light;
            border-top: 1px solid $color-yellow-light;
            padding-top: 12px;
            font-weight: 500;
            max-width: 200px;
            margin-bottom: 14px;
        }
        &__content {
            display: flex;
            flex-wrap: wrap;
            p {
                max-width: 185px;
            }
        }
        &__numeral {
            font-size: 56px;
            font-family: $font-geo;
            margin-right: 16px;
        }
    }
    &-advantages {
        position: relative;
        padding-bottom: 64px;
        padding-top: 80px;
        &::before {
            content: '';
            width: 100%;
            height: 704px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: #1e324b;
        }
        &__wrapper {
            position: relative;
            opacity: 1;
            top: 0;
            transition: 1s;
            &--start {
                opacity: 0;
                top: 80px;
            }
        }
        &__slider {
            button {
                top: -84px;
                &.slick-prev {
                    right: 48px;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
    }
    &-location {
        &__banner {
            background: url("/files/images/about/about-location.jpg") center / cover no-repeat;
            min-height: 590px;
            position: relative;
            padding-top: 180px;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient(180deg, #8787A0 0%, rgba(135, 135, 160, 0) 100%);
                mix-blend-mode: multiply;
            }
        }
        &__places {
            position: relative;
            opacity: 1;
            top: 0;
            transition: 1s;
            z-index: 1;
            display: flex;
            justify-content: center;
            &--start {
                top: 80px;
                opacity: 0;
            }
        }
        &__place {
            font-size: 32px;
            text-transform: uppercase;
            color: #fff;
            position: relative;
            &--op07 {
                span {
                    opacity: .7;
                }
            }
            &--op05 {
                span {
                    opacity: .5;
                }
            }
            &:not(:last-child) {
                margin-right: 37px;
                &::after {
                    content: '';
                    position: absolute;
                    right: -21px;
                    top: calc(50% - 2.5px);
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: #fff;
                }
            }
        }
        &__map {
            margin-bottom: -120px;
            img {
                width: 100%;
            }
        }
        &__description {
            display: flex;
        }
        &__text {
            margin-bottom: 40px;
            position: relative;
            left: 0;
            top: 0;
            opacity: 1;
            transition: 1s;
            &--start {
                left: -80px;
                top: 80px;
                opacity: 0;
            }
            p {
                max-width: 538px;
                margin-bottom: 15px;
                &:nth-last-of-type(1){
                    margin-bottom: 40px;
                }
            }
            button {
                margin-top: 40px;
            }
        }
        &__choice {
            position: relative;
            //cursor: pointer;
            right: 0;
            top: 0;
            opacity: 1;
            transition: 1s;
            &--start {
                opacity: 0;
                right: -80px;
                top: -80px;
            }
            img {
                display: block;
                width: 100%;
            }
        }
        &__title {
            position: absolute;
            font-size: 18px;
            text-transform: uppercase;
            font-weight: 600;
            top: 40px;
            left: 40px;
            max-width: 230px;
            color: #1E324B;
        }
    }
    &-environment {
        padding-top: 64px;
        &__banner {
            img {
                display: block;
                width: 100%;
            }
        }
        &__description {
            display: flex;
        }
        &__pic {
            transition: 1s;
            opacity: 1;
            img {
                display: block;
                width: 100%;
            }
            &--right {
                max-width: 640px;
                margin-top: -284px;
                position: relative;
                top: 0;
                left: 0;
            }
            &--left {
                left: 0;
                position: relative;
            }
            &--start {
                &_left {
                    left: -80px;
                    opacity: 0;
                }
                &_right {
                    left: 80px;
                    top: -80px;
                    opacity: 0;
                }
            }
        }
        &__scroll {
            width: 36px;
            height: 36px;
            position: absolute;
            bottom: -18px;
            right: 20px;
            background: $color-blue-dark;
            border-radius: 50%;
            display: none;
            &::before {
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                top: 13px;
                right: 8px;
                border: 2px solid #fff;
                border-right-color: transparent;
                border-bottom-color: transparent;
                transform: rotate(135deg);
            }
            &::after {
                content: '';
                width: 10px;
                height: 10px;
                position: absolute;
                top: 13px;
                left: 8px;
                border: 2px solid #fff;
                border-right-color: transparent;
                border-bottom-color: transparent;
                transform: rotate(-45deg);
            }
        }
        &__text {
            padding: 46px 0 0 65px;
            max-width: 640px;
            margin-bottom: 40px;
            p {
                &:nth-last-of-type(1){
                    margin-bottom: 20px;
                }
            }
        }
    }
    &-architecture {
        .about__h2 {
            padding: 0 20px;
        }
        &__banner {
            height: 250px;
            overflow: hidden;
            margin-bottom: 24px;
            width: 100%;
            img {
                height: 100%;
                width: auto;
                display: block;
            }
        }
        &__smallpic {
            display: none;
            transition: 1s;
            opacity: 1;
            &--start {
                right: -40px!important;
                bottom: -120px!important;
                opacity: 0;
            }
        }
        &__text {
            padding: 0 20px;
            margin-bottom: 25px;
        }
        &__pic {
            opacity: 1;
            transition: 1s;
            &--one {
                padding: 0 20px;
                margin-bottom: 23px;
                width: 100%;
                right: 0;
            }
            &--two {
                display: none;
                position: relative;
                left: 0;
                top: 0;
                opacity: 1;
                transition: 1s;
            }
            &--start {
                &_one {
                    right: -80px!important;
                    opacity: 0;
                    opacity: 0;
                }
                &_two {
                    left: -80px;
                    top: 80px;
                    opacity: 0;
                }
            }
            img {
                width: 100%;
                display: block;
            }
        }
    }

    &-terraces {
        &__title {
            font-family: $font-geo;
            font-size: 28px;
            line-height: 35px;
            padding: 0 20px;
        }
        &__pic {
            width: 100%;
            margin: 20px 0 24px;
            position: relative;
            right: 0;
            top: 0;
            opacity: 1;
            transition: 1s;
            &--start {
                right: -80px!important;
                top: 80px!important;
                opacity: 0;
            }
            img {
                width: 100%;
            }
        }
        &__text {
            padding: 0 20px;
        }
    }
    &-improvement {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        &__pic {
            display: block;
            width: 100%;
            position: relative;
            left: 0;
            top: 0;
            opacity: 1;
            transition: 1s;
            &--start {
                left: -80px;
                top: 80px;
                opacity: 0;
            }
        }
        &__text {
            padding-left: 60px;
            p:nth-last-of-type(1) {
                margin-bottom: 25px;
            }
        }
        &__title {
            font-family: $font-geo;
            font-size: 40px;
            text-transform: uppercase;
            max-width: 400px;
            margin-top: 60px;
            margin-bottom: 33px;
        }
        &__banner {
            width: 100%;
        }
    }

    &-finish {
        padding-top: 67px;
        position: relative;
        padding-bottom: 131px;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1051px;
            bottom: 0;
            left: 0;
            background: $color-blue-dark;
        }
        &__title-text {
            max-width: 610px;
            margin-bottom: 20px;
        }
        &__types {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-end;
            position: relative;
        }
        &__item {
            position: relative;
            top: 0;
            opacity: 1;
            transition: 1s;
            background: $color-blue-dark;
            &:hover {
                filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.2));
                top: -10px;
            }
            &--w50 {
                width: 50%;
            }
            &--w33{
                width: calc(100% / 3);
                margin-top: -125px;
                padding-bottom: 76px;
            }
            &--start {
                top: 60px;
                opacity: 0;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 1px;
                height: 100%;
                transform: rotate(180deg);
                opacity: .4;
                background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 24.48%, #FFFFFF 80.21%, rgba(255, 255, 255, 0) 100%);
            }
        }
        &__img {
            display: block;
            width: 100%;
        }
        &__descr {
            position: absolute;
            color: #fff;
            bottom: 112px;
            left: 41px;
            &--higher {
                bottom: 151px;
            }
            &--lower {
                bottom: 71px;
            }
        }
        &__num {
            font-weight: 200;
            font-size: 100px;
        }
        &__text {
            font-family: $font-geo;
            font-size: 40px;
            font-weight: 300;
        }
        &__button {
            width: 48px;
            height: 48px;
            font-size: 0;
            border: .5px solid rgba(255,255,255,.2);
            background: url(/files/images/svg/arrow-slider.svg) center/10px 20px no-repeat #21364e;
            transition: .5s;
            position: absolute;
            z-index: 1;
            top: -24px;
            display: none;
            &--prev {
                transform: rotate(180deg);
                right: 48px;
            } 
            &--next {
                right: 0;
            }
        }
    }
    &-views {
        position: relative;
        &__pic {
            display: block;
            width: 100%;
        }
        &__title {
            position: absolute;
            top: 67px;
            left: calc(50% - 720px);
        }
        &__view {
            position: absolute;
            background: url("/files/images/about/about-eye.svg") center / 55% no-repeat, #fff;
            border-radius: 50%;
            cursor: pointer;
            &:hover {
                background-color: $color-blue-lightest;
            }
            &--bottom {
                width: 58px;
                height: 58px;
                left: 31.6%;
                bottom: 20.5%;
            }
            &--left {
                width: 32px;
                height: 32px;
                left: 22.5%;
                top: 42.4%;
            }
            &--top {
                height: 38px;
                width: 38px;
                top: 34.5%;
                left: 63.75%;
            }
            &--right {
                height: 34px;
                width: 34px;
                top: 72%;
                left: 72%;
            }
        }
    }
    &-premises {
        padding-top: 100px;
    }
    &-underground {
        display: flex;
        position: relative;
        &::before {
            content: '';
            width: 100%;
            height: 430px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: rgba(134, 134, 159, 0.1);
        }
        &__descr {
            margin-top: 82px;
            >p {
                max-width: 645px;
            }
            button {
                margin-top: 16px;
            }
        }
        &__title {
            font-size: 20px;
            margin-bottom: 17px;
        }
        &__num {
            margin-top: 16px;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            span {
                font-size: 64px;
                margin-right: 19px;
            }
        }
    }
    &-storage {
        display: flex;
        position: relative;
        padding-bottom: 97px;
        &::before {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 70%;
            height: 100%;
            background: rgba(134, 134, 159, 0.1);
        }
        &__pic {
            display: block;
            width: 100%;
        }
        &__descr {
            padding: 95px 0 0 65px;
            p {
                max-width: 456px;
            }
            button {
                margin-top: 32px;
            }
        }
        &__title {
            font-family: $font-geo;
            font-size: 40px;
            text-transform: uppercase;
            font-weight: 300;
            line-height: 50px;
            margin-bottom: 50px;
        }
        &__num {
            font-family: $font-geo;
            font-size: 40px;
            margin-top: 50px;
            font-weight: 300;
            margin-bottom: 20px;
        }
    }
    &-commercial {
        display: flex;
        flex-wrap: wrap;
        &__top {
            width: 100%;
            position: relative;
        }
        &__pic {
            display: block;
            width: 100%;
            &--mt {
                margin-top: -116px;
            }
        }
        &__title {
            position: absolute;
            left: calc(50% - 720px);
            top: 67px;
        }
        &__descr {
            padding-top: 69px;
            p {
                max-width: 538px;
                &:nth-last-of-type(1){
                    margin-bottom: 40px;
                }
            }
            button {
                margin-top: 56px;
            }
        }
        &__subtitle {
            font-family: $font-geo;
            font-size: 40px;
            text-transform: uppercase;
            font-weight: 300;
            line-height: 50px;
            margin-bottom: 50px;
        }
        &__statistic {
            padding-top: 90px;
            padding-bottom: 80px;
        }
    }
    &-order {
        padding: 41px 56px 78px 60px;
        color: #fff;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 80px;
        opacity: 1;
        top: 0;
        transition: 1s;
        &--start {
            top: 60px;
            opacity: 0;
        }
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(341.93deg, #6C6C89 3.99%, #8787A0 52.17%, #8787A0 119.62%);
            mix-blend-mode: multiply;
            transform: matrix(1, 0, 0, -1, 0, 0);
            z-index: -1;
        }
        &__title {
            font-family: $font-geo;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 30px;
            margin-bottom: 12px;
        }
        p {
            width: 100%;
        }
        &__button {
            border: 1px solid rgba(255, 255, 255, 0.5);
            color: #fff;
        }
        .statistics {
            &__item {
                position: relative;
                top: 0;
                opacity: 1;
                transition: 1s;
                &--start {
                    top: 60px;
                    opacity: 0;
                }
            }
        }
    }
}

@media all and (max-width: 1439px) {
    .about {
        &-cell {
            max-width: 548px;
            padding-left: 40px;
            &--right {
                padding-left: 0;
                padding-right: 40px;
            }
        }
        &__h2 {
            font-size: 58px;
            line-height: 88px;
            &--premises {
                line-height: 72px;
            }
        }
        &-description {
            &__title {
                font-size: 16px;
            }
            &__numerals {
                right: 0;
            }
            &__item {
                max-width: 240px;
            }
            &__numeral {
                font-size: 42px;
                margin-right: auto;
            }
        }
        &-environment {
            &__pic {
                &--right{
                    margin-top: -200px;
                }
            }
        }
        &-improvement {
            &__title {
                margin-top: 33px;
            }
        }
        &-finish {
            &::before {
                height: 760px;
            }
        }
        &-views {
            &__title {
                left: calc(50% - 584px);
            }
        }
        &-underground {
            &::before {
                height: 438px;
            }
            &__descr {
                margin-top: 20px;
            }
        }
        &-storage {
            &__descr {
                padding-top: 50px;
            }
        }
        &-commercial {
            &__title {
                left: calc(50% - 584px);
            }
            &__descr {
                padding-top: 50px;
            }
            &__title {
                margin-bottom: 33px;
            }
        }
    }
    .slider-stairs {
        &__text {
            display: none;
        }
        &__item {
            width: 495px;
        }
        .slick-active {
            &--first {
                width: 495px;
                .slider-stairs__text {
                    display: block;
                }
                margin-top: 0;
                height: 100%;
            }
            &--second {
              width: 267px;
              margin-top: 0;
              height: 100%;
            }
            &--third {
              width: 248px;
              margin-top: 0;
              height: 100%;
            }
        }
    }
}

@media all and (max-width: 1168px) {
    .about {
        &-description {
            flex-wrap: wrap;
            &__text {
                width: 100%;
                p {
                    max-width: 100%
                }
            }
            &__numerals {
                position: static;
                width: 100%;
                max-width: 768px;
                margin: 0 auto;
                order: -1;
                justify-content: center;
                margin-top: -74px;
            }
            &__item {
                max-width: 340px;
            }
            &__numeral {
                font-size: 56px;
            }
        }
        &__h2 {
            font-size: 50px;
            line-height: 74px;
        }
        &-environment {
            &__text {
                padding-top: 33px;
                padding-left: 40px;
            }
        }
        &-improvement {
            &__title {
                font-size: 32px;
                margin-bottom: 20px;
                margin-top: 0;
            }
            &__banner {
                margin-top: 0;
            }
        }
        &-views {
            &__title {
                max-width: 100%;
                left: 0;
            }
        }
        &-underground {
            margin-bottom: 33px;
            &::before {
                display: none;
            }
            &__descr > p {
                max-width: 400px;
            }
        }
        &-storage {
            &__title {
                font-size: 32px;
                line-height: 45px;
            }
        }
        &-commercial {
            &__title {
                width: 100%;
                left: 0;
            }
            &__subtitle {
                font-size: 32px;
                line-height: 45px;
            }
            &__descr {
                margin-bottom: 33px;
                button {
                    font-size: 16px;
                }
            }
        }
        &-order {
            padding-bottom: 60px;
            button {
                order: 3;
                margin-top: 33px;
            }
        }
    }
    .slider-stairs {
        &__item {
            width: 400px;
        }
        .slick-active {
            &--first {
                width: 400px;
            }
        }
    }
}

@media all and (max-width: 940px) {
    .about {
        flex-wrap: wrap;
        button {
            font-size: 14px;
            height: auto;
        }
        &-item {
            width: 100%;
        }
        &-cell {
            max-width: 100%;
            padding-right: 40px;
        }
        &__h2 {
            font-size: 44px;
            line-height: 54px;
            &--views {
                max-width: 100%;
            }
        }
        &-description {
            &__numerals {
                max-width: 100%;
            }
            &__item {
                max-width: 48%;
            }
            &__text {
                max-width: 569px;
            }
            &__title {
                border-top: 0;
            }
        }
        &-advantages {
            &__slider {
                button {
                    top: -24px;
                    &.slick-prev {
                        right: 88px;
                    }
                    &.slick-next {
                        right: 40px;
                    }
                }
            }
        }
        &-location {
            &__banner {
                min-height: 315px;
                padding-top: 81px;
            }
            &__places {
                display: none;
            }
            &__description {
                flex-wrap: wrap;
            }
            &__map {
                margin-bottom: -60px;
            }
            &__text {
                p {
                    max-width: 568px;
                }
            }
        }
        &-environment {
            padding-top: 47px;
            &__description {
                flex-wrap: wrap;
                position: relative;
            }
            &__banner {
                max-height: 451px;
                overflow: hidden;
                display: flex;
                justify-content: flex-end;
                img {
                    width: auto;
                    height: 100%;
                }
            }
            &__text {
                max-width: 100%;
                margin-top: 125px;
            }
            &__pic {
                &--right {
                    max-width: calc(100% - 80px);
                    margin: -40px auto 0;
                }
                &--left {
                    position: absolute;
                    max-width: 341px;
                    right: 40px;
                    z-index: 2;
                    top: -215px;
                }
            }
        }
        &-improvement {
            .about-item {
                width: 50%;
            }
            .about-cell {
                padding: 35px 40px;
                background: #fff;
            }
            &__text {
                padding-left: 0;
            }
            &__title {
                font-size: 24px;
                font-weight: 300;
            }
            &__banner {
                min-height: 470px;
                overflow: hidden;
                margin-top: -186px;
                img {
                    height: 100%;
                    width: auto;
                    margin-left: -265px;
                }
            }
        }
        &-finish {
            &::before {
                height: 610px;
            }
            .about__h2 {
                margin-bottom: 0;
            }
            &__types {
                margin-top: 31px;
            }
            &__item {
                display: none;
                &:nth-of-type(1) {
                    display: block;
                    width: 100%;
                }
            }
            &__button {
                display: block;
            }
        }
        &-views {
            &__title {
                top: 47px;
            }
            &__view {
                &--bottom {
                    width: 43px;
                    height: 43px;
                }
                &--left {
                    width: 24px;
                    height: 24px;
                }
                &--top {
                    height: 28px;
                    width: 28px; 
                }
                &--right {
                    width: 25px;
                    height: 25px;
                }
            }
        }
        &-premises {
            padding-top: 47px;
            position: relative;
            &::before {
                content: '';
                width: calc(100% - 80px);
                height: 84%;
                position: absolute;
                left: 40px;
                bottom: 60px;
                background: rgba(134, 134, 159, 0.1);
            }
        }
        &-underground {
            flex-wrap: wrap;
            position: relative;
            .about-item--right {
                position: absolute;
                top: 150px;
                left: 0;
            }
            .underground-slider {
                width: 100%;
                max-width: 610px;
                &__item {
                    height: 370px;
                    overflow:hidden;
                }
                .slick-arrow {
                    bottom: -24px;
                    &.slick-next {
                        left: auto;
                        right: 0;
                    }
                    &.slick-prev {
                        left: auto;
                        right: 48px;
                    }
                }
            }
            &__descr {
                margin: 477px auto 0;
                max-width: 468px;
                > p {
                    max-width: 100%;
                }
            }
        }
        &-storage {
            flex-wrap: wrap;
            padding-bottom: 120px;
            &::before {
                display: none;
            }
            &__pic {
                max-width: 610px;
                max-height: 370px;
                margin-right: auto;
            }
            &__descr {
                padding: 0;
                margin: 35px auto 0;
                max-width: 465px;
            }
            &__title {
                font-size: 26px;
                max-width: 312px;
                line-height: 33px;
                margin-bottom: 14px;
            }
            &__num {
                margin-top: 15px;
            }
        }
        &-commercial {
            &__top {
                min-height: 505px;
                overflow: hidden;
                .about-commercial__pic {
                    height: 100%;
                    width: auto;
                }
            }
            &__title {
                top: 47px;
            }
            &__subtitle {
                font-size: 26px;
                margin-bottom: 16px;
            }
            &__pic--mt {
                display: none;
            }
            &__descr {
                p {
                    max-width: 87.5%;
                }
            }
        }
        &-order {
            padding: 54px 65px 60px 60px;
            margin-bottom: 60px;
            &__title {
                font-size: 26px;
            }
        }
    }
    .slider-stairs {
        width: calc(100% + 80px);
        margin: 0 -40px;  
        &__item {
            width: 406px;
        }
        .slick-active {
            &--first {
                width: 406px;
            }
            &--second {
                width: 180px;
            }
            &--third {
                width: 182px;
            }
        }
    }
}

@media all and (max-width: 700px) {
    .about {
        &-top {
            padding-top: 94px;
        }

        &-cell {
            padding: 0px 20px;
        }

        &__h2 {
            font-size: 30px;
            line-height: 37px;
            &--improvement {
                span {
                    display: inline-block;
                }
                br {
                    display: block;
                }
            }
        }

        &-description {
            &__item {
                max-width: 100%;
                padding: 34px 40px;
                &:nth-of-type(2n+1) {
                    margin-right: 0;
                }
                &:nth-of-type(3) {
                    margin-top: 0;
                }
            }
            &__numeral {
                margin-right: 16px;
            }
            &__content {
                p {
                    max-width: 100%;
                }
            }
        }

        &-advantages {
            .about__h2 {
                margin-bottom: 56px;
            }
        }

        &-location {
            &__banner {
                padding-top: 32px;
                background-size: auto 100%;
                background-position: -90px center;
                background-repeat: no-repeat;
                min-height: 404px;
            }
            &__places {
                display: flex;
            }
            &__place {
                font-size: 15px;
            }
            &__map {
                height: 285px;
                overflow: hidden;
                img {
                    width: auto;
                    height: 100%;
                    margin-left: -145px;
                }
            }
            &__choice {
                padding-top: 0;
                height: 409px;
                overflow: hidden;
                img {
                    height: 100%;
                    width: auto;
                }
            }
            &__title {
                top: 24px;
                left: 29px;
            }
        }

        &-environment {
            margin-bottom: 33px;
            &__banner {
                height: 250px;
                justify-content: center;
            }
            &__pic {
                &--left {
                    display: none;
                }
                &--right {
                    width: calc(100% - 40px);
                }
            }
            &__scroll {
                display: block;
            }
            &__text {
                padding: 33px 20px 0;
                margin-top: 0;
            }
        }

        &-improvement {
            .about-item {
                width: 100%;
            }
            .about-cell {
                padding: 25px 20px;
            }
            &__banner {
                display: none;
            }
        }

        &-finish {
            &::before {
                height: 540px;
            }
            > p {
                display: none;
            }
            &__types {
                margin-top: 56px;
            }
            &__item {
                height: 569px;
                overflow: hidden;
            }
            &__img {
                height: 100%;
                width: auto;
            }
            &__descr {
                left: 25px;
            }
        }

        &-views {
            padding-top: 82px;
            height: 449px;
            overflow: hidden;
            &::before {
                content: '';
                width: 100%;
                height: 171px;
                background: linear-gradient(180deg, #4A5274 50.29%, rgba(255, 255, 255, 0) 100%);
                position: absolute;
                top: 0;
                left: 0;
            }
            &__title {
                top: 16px;
            }
            &__pic {
                height: 100%;
                width: auto;
            }
            &__view {
                width: 44px;
                height: 44px;
                &--bottom {
                    left: calc(25% - 22px);
                    bottom: calc(25% - 22px);
                }
                &--left {
                    left: calc(25% - 22px);
                    top: calc(25% - 22px);
                }
                &--top {
                    right: calc(25% - 22px);
                    top: calc(25% - 22px);
                    left: auto;
                }
                &--right {
                    right: calc(25% - 22px);
                    bottom: calc(25% - 22px);
                    top: auto;
                    left: auto;
                }
            }
        }

        &-premises {
            &::before {
                width: 100%;
                left: 0;
                bottom: 0;
            }
        }

        &-underground {
            .underground-slider {
                width: 390px;
                max-width: calc(100% - 40px);
                left: calc(50% - 195px);
                height: auto;
                top: 100px;
                &__item {
                    height: auto;
                }
            }
            &__descr {
                margin-top: 410px;
            }
        }

        &-storage {
            padding-bottom: 40px;
            &__pic {
                max-width: calc(100% - 40px);
                margin: 0 auto;
            }
            &__title {
                font-size: 22px;
                line-height: 27px;
            }
        }

        &-commercial {
            &__top {
                min-height: 400px;
            }
            &__subtitle {
                font-size: 22px;
                line-height: 27px;
            }
        }

        &-order {
            padding: 35px 20px 40px 20px;
            margin-bottom: 40px;
            &__title {
                font-size: 22px;
                line-height: 27px;
            }
        }
    }
    .slider-stairs {
        width: calc(100% + 40px);
        margin: 0 -20px;
        .slick-arrow {
            &.slick-next {
                right: 20px;
            }
            &.slick-prev {
                right: 68px;
            }
        }
    }
}

@media all and (max-width: 430px) {
    .about {
        &-description {
            &__numeral {
                width: 100%;
                margin-right: 0;
            }
        }
        &-architecture__banner img {
            width: auto;
        }
        &-underground {
            .underground-slider {
                width: 280px;
                left: calc(50% - 140px);
            }
            &__descr {
                margin-top: 305px;
            }
            &__title {
                line-height: 28px;
            }
        }
        &-commercial__descr button {
            margin-top: 24px;
            span {
                display: none;
            }
        }
    }
}

@media all and (min-width: 430px) {
    .about-architecture__banner {
        height: auto;
        img {
            height: auto;
            width: 100%;
        }
    }

}

@media all and (min-width: 701px) {
    .about {
        &-architecture {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            .about__h2 {
                padding: 0 40px;
                margin: 50px 0;
            }
            &__pic {
                &--one {
                    padding: 0;
                    position: relative;
                    width: calc(50% - 40px);
                    order: 5;
                    display: flex;
                    align-items: flex-end;
                    div {
                        position: absolute;
                        width: calc(50% - 40px);
                        right: 40px;
                    }
                }
                &--two {
                    display: block;
                    width: 100%;
                    padding: 0 40px;
                }
            }
            &__text {
                &--top {
                    position: relative;
                    padding: 0;
                    margin-bottom: 0;
                    width: 100%;
                    z-index: 1;
                    div {
                        position: absolute;
                        width: calc(50% - 40px);
                        right: 40px;
                        background: #fff;
                        padding: 35px 50px 35px 40px;
                        top: -64px;
                    }
                }
                &--bottom {
                    width: 50%;
                    padding: 35px 0 0 40px;
                    p {
                        max-width: 310px;
                        margin-bottom: 25px;
                    }
                }
            }
        }
        &-terraces {
            display: flex;
            flex-wrap: wrap;
            &__title {
                order: 1;
                margin-bottom: 15px;
                padding: 0 40px;
            }
            &__text {
                order: 2;
                padding: 0 40px;
            }
            &__pic {
                order: 3;
                margin: 34px auto 0;
                width: calc(100% - 80px);
            }
        }
    }
}

@media all and (min-width: 941px) {
    .about {
         &-architecture {
             margin-bottom: 0;
            &__banner {
                position: relative;
                overflow: visible;
            }
            .about__h2 {
                order: 1;
                width: 100%;
                margin-bottom: 20px;
            }
            &__text {
                &--top {
                    order: 2;
                    width: 50%;
                    div {
                        position: static;
                        width: 100%;
                        padding-top: 0;
                    }
                }
                &--bottom {
                    order: 6;
                    padding: 65px 40px 0 65px;
                    margin-bottom: 0;
                    p {
                        max-width: 538px;
                    }
                }
            }
            &__pic {
                &--one {
                    order: 3;
                    width: 50%;
                    position: absolute;
                    right: 0;
                    div {
                        position: static;
                        width: 100%;
                    }
                }
                &--two {
                    order: 5;
                    width: 50%;
                    padding-right: 0;
                    margin-top: -67px;
                    z-index: 1;
                }
            }
            &__banner {
                order: 4;
                position: relative;
                margin-bottom: 0;
            }
            &__smallpic {
                display: block;
                position: absolute;
                right: 40px;
                bottom: -40px;
                max-width: 290px;
            }
        }
        &-terraces {
            position: relative;
            &__title {
                font-weight: 300;
                font-size: 40px;
                margin-top: 75px;
                margin-bottom: 40px;
                width: 100%;
            }
            &__text {
                width: 50%;
                padding: 0 0 0 40px;
                p {
                    max-width: 569px;
                }
            }
            &__pic {
                width: 50%;
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 0;
            }
        }
    }
}

@media all and (min-width: 1169px) {
    .about {
        &-architecture {
            .about__h2 {
                max-width: 1168px;
                padding: 0 80px;
                margin: 50px auto 20px;
            }
            &__text {
                &--top {
                    div {
                        padding: 0 50px 35px 80px;
                    }
                }
                &--bottom {
                    padding: 0;
                    div {
                        padding: 65px 80px 100px 65px;
                    }
                }
            }
            &__cell {
                max-width: 584px;
                padding-left: 80px;
                margin-left: auto;
                &--right {
                    padding-left: 0;
                    padding-right: 80px;
                    margin-left: 0;
                    margin-right: auto;
                }
            }
            &__pic {
                &--two {
                    padding-left: 0;
                }
            }
        }
        &-terraces {
            margin-bottom: 100px;
            &__title {
                max-width: 1168px;
                padding: 0 80px;
                margin: 75px auto 40px;
            }
            &__cell {
                max-width: 584px;
                padding-left: 80px;
                margin-left: auto;
            }
            &__text {
                p {
                    max-width: 470px;
                }
            }
        }
        
    }
}

@media all and (min-width: 1440px) {
    .about {
        &-architecture {
            .about__h2 {
                max-width: 1440px;
                width: 100%;
                padding: 0 80px;
                margin-top: 154px;
            }
            &__cell {
                max-width: 640px;
                margin-left: auto;
                padding-left: 0;
                &--right {
                    margin-right: auto;
                    margin-left: 0;
                }
            }
            &__text {
                &--top {
                    div {
                        padding-left: 0;
                    }
                }
                &--bottom {
                    div {
                        padding-bottom: 0;
                    }
                }
            }
        }
        &-terraces {
            margin-bottom: 0;
            &__cell {
                max-width: 640px;
                margin-left: auto;
                padding-left: 0;
            }
            &__title {
                max-width: 1440px;
                width: 100%;
                padding: 0 80px;
            }
            &__text {
                margin-bottom: 96px;
            }

        }
    }
}

@media all and (min-width: 1600px) {
    .about-terraces__text {
        margin-bottom: 270px;
    }
}