@import "styles/variable";

.filter {
  background: $color-gray-lighter;
  padding: 30px 30px 8px;
  h1 {
    font-size: 40px;
    margin-bottom: 30px;
    max-width: 600px;
  }
  h2 {
    font-size: 40px;
    margin-bottom: 30px;
    max-width: 600px;
  }
  &__section {
    margin-left: -20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &--top {
      padding-bottom: 22px;
    }
    &--bottom {
      padding: 25px 0;
      position: relative;

      &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 20px;
        width: calc(100% - 20px);
        height: 0;
        border-top: 1px solid rgba(187, 193, 207, 0.5);
      }
    }
  }

  &__item {
    margin-left: 20px;
    flex: 0 0 328px;
    &:first-child{
      flex: 1 0 auto;
    }
    &--rooms {
      display: flex;
      align-items: center;
      > span {
        margin-right: 15px;
      }
    }
    sup {
      line-height: 0;
    }
  }

  &__term {
    font-size: 14px;
    color: #1E324B;
    border: 1px solid #536589;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    padding: 7px 15px;
    width: fit-content;
    margin-left: auto;
  }

  &__range {
    margin-bottom: 15px;
    display: block;
  }

  &__change {
    padding: 10px 27px 10px 0;
    position: relative;
    cursor: pointer;
    color: #213E60;
    &::after {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border: 2px solid #ABC3D5;
      border-right-color: transparent;
      border-bottom-color: transparent;
      right: 5px;
      transform-origin: center;
    }
    &--more {
      max-width: 270px;
      &::after {
        transform: rotate(-135deg);
        top: calc(50% - 8px);
      }
    }
    &--less {
      max-width: 106px;
      &::after {
        transform: rotate(45deg);
        top: calc(50% - 2px);
      }
    }
  }
  &__more {
    margin-top: 15px;
    display: flex;
  }

  &__checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
      display: none;

      &:checked + .filter__checkbox-item {
        background: url("/files/images/svg/check.svg") center / cover no-repeat;
      }
    }

    &-item {
      margin-right: 10px;
      width: 18px;
      height: 18px;
      border-radius: 3px;
      border: 1px solid #ABC3D5;
    }

    + .filter__checkbox {
      margin-left: 20px;
    }
  }
}

.filter-popup__block {
  display: flex;
}



@media all and (max-width: 1440px){
  .filter__more {
    flex-direction: column;
  }
  .filter__checkbox + .filter__checkbox {
    margin-left: 0;
    margin-top: 10px;
  }
  .filter__item--rooms {
    flex-direction: column;
    align-items: flex-start;
    > span {
      margin-bottom: 10px;
    }
  }
}

@media all and (max-width: 1350px){
}

@media all and (max-width: 1168px) {
  .filter__item {
    width: auto;
  }
  .filter__section {
    justify-content: flex-start;
  }
}

@media all and (max-width: 1080px) {
  .filter__item {
    margin-left: 10px;
  }
  .fix__width{
    width: 245px;
  }
  .filter__term{
    margin-left: 25px;
  }
}

@media all and (max-width: 1000px){
  .filter {
    background: none;
    padding: 0;
    h1 {
      margin-bottom: 30px;
      margin-top: 20px;
    }
  }
  .filter__item {
    display: flex;
    margin-bottom: 30px;
    &:nth-last-of-type(1){
      margin-bottom: 50px;
    }
    > span {
      min-width: 250px;
    }
  }
  .filter__rooms {
    justify-content: flex-start;
  }
  .filter__checkbox {
    justify-content: flex-start;
    align-items: center;
    >span {
      display: block;
      margin-right: 20px;
    }
  }
  .filter__more {
    margin-top: 0;
    flex-direction: row;
  }
  .filter__checkbox + .filter__checkbox {
    margin-top: 0;
  }
  .popup-wrapper {
    padding: 115px 40px 50px;
    background: #FFFFFF linear-gradient(180deg, #ABC3D5 0%, rgba(238, 243, 247, 0) 100%) top no-repeat;
    background-size: 100% 208px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .filter-popup {
    padding-right: 20px;
    margin-right: -20px;
    width: calc(100% + 20px);
    position: relative;
    align-self: flex-start;
    max-height: 100%;
    overflow-y: auto;
    h1 {
      margin-top: 0;
    }
    &__block {
      display: flex;
    }
    &__button {
      width: 100%;
      color: white;
      background: #213E60;
      display: block;
      margin-bottom: 20px;
    }
    &__exit {
      cursor: pointer;
      position: absolute;
      top: 12.5px;
      right: 20px;
      width: 17px;
      height: 17px;
      background: url("/files/images/svg/exit.svg") center no-repeat;
      background-size: 17px;
    }
    button {
      width: 100%;
    }
  }
}

@media all and (max-width: 750px){
  .filter-popup {
    h1 {
      font-size: 30px;
      padding-right: 40px;
    }
    .filter__item {
      flex-direction: column;
      margin-left: 0;
      &--rooms {
        align-items: flex-start;
      }
      >span {
        margin-bottom: 20px;
      }
    }
    .filter__checkbox {
      align-items: flex-start;
    }
    &__exit {
      top: 8px;
    }
  }
  .popup-wrapper {
    padding: 120px 20px 20px 20px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

@media all and (max-width: 500px){
 .filter__more {
    flex-direction: column;
  }
  .filter__checkbox + .filter__checkbox {
    margin-top: 10px;
  }
}
