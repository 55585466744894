@import "styles/variable";

.purchase {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    &-item {
        width: 50%;
        z-index: 1;
        position: relative;
        opacity: 1;
        transition: 1s;
        &--left {
            left: 0;
        }
        &--right {
            right: 0;
        }
        &--start {
            &_left {
                left: -100px;
                opacity: 0;
            }
            &_right {
                right: -100px;
                opacity: 0;
            }
        }
    }
    &-cell {
        width: 100%;
        max-width: 640px;
        margin-left: auto;
        padding-bottom: 100px;
        &--right {
            margin-left: 0;
        }
    }
    h1 {
        max-width: 463px;
        text-transform: uppercase;
        margin-bottom: 24px;
        margin-top: 40px;
        font-size: 50px;
    }
    &__desc {
        max-width: 564px;
        line-height: 1.5;
    }
    &__pic {
        img {
            display: block;
            width: 100%;
            height: auto;
        }
        &--mt {
            margin-top: 42px;
        }
    }
    &__step {
        &--one {
            width: 70%;
            margin-top: -232px;
        }
        &--two {
            margin-top: -20px;
        }
        &--three {
            width: 70%;
            z-index: 1;
        }
        &--six {
            margin-top: 112px;
            margin-bottom: -93px;
        }
        &--seven {
            z-index: 1;
            margin-bottom: 56px;
        }
    }
    &__laststep {
        margin-top: -159px;
    }
    &-order {
        padding: 41px 56px 78px 60px;
        color: #fff;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;
        width: 100%;
        opacity: 1;
        transition: 1s;
        top: 0;
        &--start {
            top: 20px;
            opacity: 0;
        }
        &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(341.93deg, #6C6C89 3.99%, #8787A0 52.17%, #8787A0 119.62%);
            mix-blend-mode: multiply;
            transform: matrix(1, 0, 0, -1, 0, 0);
            z-index: -1;
        }
        &__title {
            font-family: $font-geo;
            text-transform: uppercase;
            font-weight: 300;
            font-size: 30px;
            margin-bottom: 12px;
        }
        p {
            width: 100%;
        }
        
        &__button {
            border: 1px solid rgba(255, 255, 255, 0.5);
            color: #fff;
        }
    }
}

.step {
    position: relative;
    transition: 1s;
    opacity: 1;
    &--left {
        background: $color-bg-aside;
        left: 0;
    }
    &--right {
        box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.06);
        max-width: 1010px;
        margin-left: auto;
        background: #ffffff;
        right: 0;
    }
    &--one {
        width: 70%;
        z-index: 1;
    }
    &--two {
        min-height: 280px;
        padding: 80px 0 0 106px;
        display: flex;
        align-items: flex-start;
    }
    &--three {
        p {
            max-width: 291px;
        }
    }
    &--four {
        min-height: 320px;
        padding: 95px 197px 0 106px;
        display: flex;
        align-items: flex-start;
        min-height: 320px;
    }
    &--five {
        align-self: flex-start;
        min-height: 372px;
    }
    &--six {
        margin-left: 0;
        margin-right: auto;
        background: #DDE7EE;
        min-height: 372px;
    }
    &--seven {
        min-height: 248px;
        padding: 88px 132px 0 179px;
        display: flex;
        align-items: flex-start;
    }
    &--start_left {
        left: -120px;
        opacity: 0;
    }
    &--start_right {
        right: -120px;
        opacity: 0;
    }
    &__cell {
        display: flex;
        position: relative;
        &--left {
            max-width: 896px;
            margin-left: auto;
            padding-right: 143px;
            padding-top: 58px;
            padding-bottom: 86px;
        }
        &--three {
            padding-bottom: 0;
        }
        &--five {
            padding: 59px 73px 0 0;
        }
        &--six {
            padding: 50px 0 0 41px;
            margin-left: 0;
        }
    }
    &__number {
        font-size: 100px;
        font-weight: 200;
        line-height: 96px;
        margin-right: 36px;
        &::before {
            content: '';
            position: absolute;
            width: 1px;
            background: #213E60;
            opacity: .5;
            top: 0;
        }
        &::after {
            content: '';
            position: absolute;
            width: 1px;
            background: #213E60;
            opacity: .5;
            bottom: 0;
        }
        &--one {
            &::after {
                height: 332px;
                left: 60px;
            }
        }
        &--two {
            &::after {
                height: 97px;
                left: 166px;
            }
            &::before {
                height: 74px;
                left: 166px;
            }
        }
        &--three {
            &::after {
                height: 210px;
                bottom: 20px;
                left: 60px;
            }
        }
        &--four {
            &::after {
                height: 121px;
                left: 166px;
            }
            &::before {
                height: 87px;
                left: 166px;
            }
        }
        &--five {
            &::after {
                height: 245px;
                left: 60px;
                bottom: auto;
                top: 127px;
            }            
        }
        &--six {
            &::after {
                height: 218px;
                left: 103px;
                bottom: auto;
                top: 130px;
            }
        }
        &--seven {
            &::before {
                height: 80px;
                left: 240px;
            }
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;
    }
    &__title {
        font-size: 20px;
        text-transform: uppercase;
        width: 100%;
        margin-bottom: 30px;
        line-height: 1;
        &--weighty {
            font-weight: 500;
        }
    }
    &__choice {
        width: 290px;
        height: 290px;
        background: #fff;
        padding: 20px;
        position: relative;
        p {
            font-size: 14px;
            opacity: .5;
            color: $color-blue-dark;
        }
        &--visual {
            background: url("/files/images/purchase/purchase-choice.png") center / cover no-repeat;
            //cursor: pointer;
            p {
                color: #fff;
            }
        }
    }
    &__subtitle {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 500;
        max-width: 174px;
        margin-bottom: 6px;
        &--white {
            color: #fff;
        }
    }
    &__button {
        position: absolute;
        left: 20px;
        bottom: 20px;
    }
    &__pic {
        margin-bottom: -20px;
        z-index: 1;
    }
}

@media all and (max-width: 1439px) {
    .purchase {
        &-cell {
            max-width: 548px;
            padding-left: 40px;
            &--right {
                padding-left: 0;
                padding-right: 40px;
            }
        }
        &__desc {
            margin-right: 20px;
            padding-bottom: 40px;
        }
        &__step {
            &--one {
                margin-top: -100px;
            }
        }
        &-order {
            padding-bottom: 60px;
            button {
                margin-bottom: 20px;
            }
        }

    }
    .step {
        &--one, &--three {
            width: 80%;
        }
        &__cell {
            &--left {
                max-width: 877px;
                padding-left: 0;
                padding-right: 80px;
            }
        }
        &__number {
            &--five {
                &::after {
                    left: 100px;
                }
            }
        }
    }
}

@media all and (max-width: 1168px) {
    .step {
        &--three {
            .step__pic {
                margin-left: auto;
            }
        }
        &--four, &--seven {
            padding-left: 80px;
        }
        &__cell {
            &--left {
                padding-left: 40px;
                padding-bottom: 40px;
            }
        }
        &__number {
            &::before, &::after {
                display: none;
            }
        }
        &__choice {
            margin: 0 auto 20px;
        }
    }
}

@media all and (max-width: 940px) {
    .purchase {
        &-item {
            width: 100%;
        }
        &-cell {
            max-width: 100%;
            padding-bottom: 0;
        }
    }
    .step {
        min-height: auto;
        padding: 60px 40px;
        &--one {
            margin-top: 0;
            width: 100%;
            padding: 0;
        }
        &--two {
            z-index: 1;
        }
        &--three {
            width: 100%;
            padding: 0;
            .step__pic {
                margin-bottom: 0;
                margin-top: 20px;
            }
        }
        &--four {
            margin-top: -20px;
        }
        &--six {
            margin-top: 0;
            margin-bottom: 0;
        }
        &__cell {
            &--left {
                padding: 40px;
                max-width: 100%;
            }
            &--five, &--six {
                padding: 0;
            }
        }
        &__number {
            font-size: 64px;
        }
    }
}

@media all and (max-width: 700px) {
    .purchase {
        h1 {
            margin-top: 0;
        }
        &-cell {
            padding: 0px 20px;
        }
        &-order {
            &__title {
                font-size: 18px;
                line-height: 24px;  
            }
        }
        &-order {
            width: calc(100% + 40px);
            margin: 0 -20px 0;
            padding: 20px;
        }
        &__pic {
            &--mt {
                margin-top: 0;
            }
        }
        &__laststep {
            margin-top: 0;
            padding: 0!important;
        }
    }
    .step {
        flex-wrap: wrap;
        padding: 0;
        &--two, &--four, &--seven {
            padding: 20px;
        }
        &--two, &--four {
            width: calc(100% + 40px);
            margin: 0 -20px;
        }
        &--seven {
            margin-bottom: 0;
        }
        &__number {
            font-size: 32px;
            line-height: 44px;
            font-weight: 400;
            margin-right: 0;
            width: 100%;
        }
        &__cell {
            flex-wrap: wrap;
            padding: 20px;
        }
        &__pic {
            max-width: 100%;
        }
        &__content {
            width: 100%;
        }
        &__choice {
            max-width: 100%;
        }
    }
}

@media all and (max-width: 500px) {
  .purchase {
    h1 {
      font-size: 30px;
    }
  }
}