@import "styles/variable";

.flat-card__price-shield {
  border-radius: 1.5rem;
  background-color: #c6b166;
  color: rgba(250, 250, 251, 1);
  font-weight: 400;
  white-space: nowrap;
  transform: translateY(-5px);
  font-size: 10px;
  padding: 4px 10px;
  gap: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 4px 10px;
  gap: 8px;
  min-width: 96px;
  height: 24px;

  &--detail {
    height: 24px;
    font-size: 10px;
    line-height: 25px;
    margin-left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    gap: 8px;
    background: #C6B166;
    border-radius: 30px;
  }

  &--profit{
    background: #C68484;
  }
}

.share-price {
  display: flex;
  padding: 0;
  width: 100%;
}

.tile-wrapper {
  width: 25%;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.tile-item {
  width: 305px;
  background: #FAFAFB;
  padding: 20px 20px 32px 20px;
  cursor: pointer;
  transition: .5s;
  position: relative;
  color: #1E324B;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media (hover: hover) {
    &:hover {
      background: $color-gray-lighter !important;

      .apartments__more {
        background: #ABC3D5;
      }
    }
  }

  &.sale {
    background: linear-gradient(163.5deg, rgba(222, 209, 162, 0.5) 0%, rgba(222, 209, 162, 0) 17.02%), #FAFAFB;
    &::before {
      content: '';
      position: absolute;
      width: 45px;
      height: 45px;
      background: url("/files/images/choice/sale.svg");
      left: 0;
      top: 0;
    }
  }
  .chosennes {
    margin-left: auto;
    margin-right: 0;
    text-align: right;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    background: url("/files/images/svg/star.svg") right center no-repeat;
    padding: 5px 25px 5px 0;
    height: 17px;
    &.active {
      background-image: url("/files/images/svg/star-selected.svg");
    }
  }
  .svg {
    display: block;
    margin: 0 auto 12px;
    max-height: 176px;
    svg {
      width: 100%;
      height: auto;
    }
  }
  .pic {
    display: block;
    margin: 0 auto 12px;
    max-height: 176px;
  }
  .inner{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
  }
  .item_body{
    margin-top: auto;
  }
  .snackbar {
    display: flex;
    flex-wrap: wrap;
  }
  .snackbar__item {
    height: 28px;
    padding: 9px 11px;
    font-size: 10px;
    max-width: 92px;
    border-radius: 14px;
    margin-bottom: 12px;
    margin-right: 10px;
    &.thereis {
      color: #fff;
      background: #8787A0;
    }
  }
  .paragraph {
    margin-bottom: 16px;
    &:nth-last-of-type(1) {
      margin-bottom: 0;
    }
  }
  .name {
    margin-bottom: 0;
  }
  .type {
    font-weight: 700;
  }
  .area {
    font-weight: 600;
  }
  .price {
    font-weight: 600;
    font-size: 18px;
    &.sale {
      color: #C6B166;
      width: 60%;
      display: inline-block;
    }
    &.old {
      width: 49%;
      display: inline-block;
      font-size: 14px;
      font-weight: 400;
      text-decoration: line-through;
      text-align: right;
    }
  }
  .tile-location {
    display: flex;
    p {
      width: 50%;
    }
  }

  &__tools{
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

@media all and (max-width: 1439px) {
  .tile-wrapper {
    width: calc(100% / 3);
  }
}

@media all and (max-width: 1000px){
  .tile-item {
    .name {
      font-size: 11px;
    }

    .area {
      font-size: 11px;
    }

    .location {
      font-size: 11px;
    }

    .price {
      font-size: 12px;
    }

    .price.old {
      font-size: 10px;
    }

    .chosennes {
      font-size: 0;
      height: 22px;
    }
  }
}

@media all and (max-width: 750px){
  .tile-wrapper {
    width: 50%;
  }
}

@media all and (max-width: 480px){
  .tile-wrapper {
    width: 100%;
  }
  .tile-item {
    .name {
      font-size: 14px;
    }
    .area {
      font-size: 14px;
    }
    .location {
      font-size: 14px;
    }
    .price {
      font-size: 16px;
    }
    .price.old {
      font-size: 12px;
    }
    .pic {
      max-height: 205px;
    }
  }
}
