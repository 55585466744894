.catalog-info {
  display: flex;
  margin-top: 26px;
  align-items: center;
  padding-left: 15px;
  justify-content: space-between;
}

.catalog-info__result {
  font-size: 18px;
  font-weight: 600;
  margin-right: 35px;
  display: inline-block;
  text-transform: uppercase;
}


.catalog-info__switcher {
  max-width: 260px;
  border: 1px solid #536589;
  display: flex;
  justify-content: space-between;
  font-family: Montserrat,Arial,sans-serif;
  &-item {
    line-height: 171%;
    font-size: 14px;
    text-align: center;
    color: #1e324b;
    cursor: pointer;
    text-transform: initial;
    flex-grow: 1;
    background-position: 16px center;
    background-repeat: no-repeat;
    padding: 9px 12px 9px 44px;
    transition: .3s;
    &:not(:last-child) {
      border-right: 1px solid #536589;
    }
    &--tile {
      background-image: url("/files/images/choice/tile-dark.svg");
    }
    &--list {
      background-image: url("/files/images/choice/list-dark.svg");
    }
    &--active {
      transition: .3s;
      color: #fff;
      background-color: #1e324b;
      &.catalog-info__switcher-item--tile {
        background-image: url("/files/images/choice/tile.svg");
      }
      &.catalog-info__switcher-item--list {
        background-image: url("/files/images/choice/list-white.svg");
      }
    }
  }
}

.catalog-info__button {
  width: 100%;
  color: white;
  background:#1E324B;
  display: block;
  font-weight: 400;
  font-size: 14px;
}

@media all and (max-width: 1000px){
  .catalog-info {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    &__switcher {
      max-width: none;
      width: 100%;
      margin-bottom: 30px;
      &-item {
        background-position: 40% 12px;
      }
    }
  }
}

@media all and (max-width: 700px){
  .catalog-info__switcher {
    &-item {
      background-position: 30% 12px;
    }
  }
}


@media all and (max-width: 400px){
  .catalog-info__switcher {
    &-item {
      background-position: 20% 12px;
    }
  }
}