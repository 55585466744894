.progress-popup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center;
  &--active {
    background: rgba(0,0,0, 0.7);
    z-index: 1000;
    opacity: 1;
    transition: .5s;
    overflow: hidden;
  }
  &__body {
    max-width: 970px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    margin: 20px;
    padding: 60px;
    position: relative;
  }
  &__exit {
    width: 48px;
    height: 48px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      background: #DDE7EE;
    }
    &:before {
      transition: 1s;
      content: "";
      position: absolute;
      width: 16px;
      height: 1px;
      top: 23px;
      left: 16px;
      background: #1E324B;
      transform: rotate(-45deg);
    }
    &::after {
      transition: 1s;
      content: "";
      position: absolute;
      width: 16px;
      height: 1px;
      top: 23px;
      left: 16px;
      background: #1E324B;
      transform: rotate(45deg);
    }
  }
  &__player {
    width: 100%;
    height: 100%;
  }
  &__youtube {
    max-width: 850px;
    width: 100%;
    height: 100%;
    margin: auto;
  }
  &__iframe {
    width: 100%;
    height: 100%;
    margin: auto;
  }
}

@media all and (max-width: 1000px){
  .progress-popup__body {
    max-height: 400px;
    max-width: 620px;
  }
  .progress-popup__iframe {
    width: 500px;
  }
}

@media all and (max-width: 600px){
  .progress-popup__body {
    max-height: 248px;
    max-width: 440px;
    padding: 0;
  }
  .progress-popup__exit {
    top: -60px;
  }
  .progress-popup__iframe {
    width: 440px;
  }
}

@media all and (max-width: 470px ){
  .progress-popup__iframe {
    width: 300px;
  }
}
