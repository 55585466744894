@import 'styles/variable';

.location {
  margin-top: -110px;

  &-title {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 152px 0 110px;
    min-height: 436px;
    width: 100%;
    transition: 1s;
    opacity: 1;
    color: #ffffff;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, #121C3D 37.44%, #8787A000 211.33%);
      opacity: 0.5;
    }

    &--start {
      opacity: 0;
    }

    &__container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 0 80px;
      position: relative;
      z-index: 1;
    }

    &__text {
      line-height: 24px;
      max-width: 682px;
    }

    h1,
    h2 {
      font-size: 64px;
      font-weight: 400;
      margin-top: 28px;
      text-transform: uppercase;
      max-width: 1170px;
    }
  }

  &-section {
    display: flex;
    flex-wrap: wrap;

    &--two {
      position: relative;
      z-index: 1;

      &:before {
        content: '';
        background: $color-bg-aside;
        position: absolute;
        right: 0px;
        top: -120px;
        width: calc(85% + 72px);
        height: 98%;
      }
    }

    &--three {
      position: relative;
      z-index: 0;

      &:before {
        content: '';
        height: 471px;
        width: calc(50% - 98px);
        position: absolute;
        top: -227px;
        left: 0;
        background: $color-bg-aside;
      }
    }
  }

  &-item {
    width: 50%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    left: 0;
    opacity: 1;
    transition: 1s;
    hyphens: auto;

    &--four {
      top: -105px;
    }

    &--start-left {
      left: -100px;
      opacity: 0;
    }

    &--start-right {
      left: 100px;
      opacity: 0;
    }
  }

  &-cell {
    max-width: 680px;
    margin-left: auto;
    width: 100%;
  }

  &__pic {
    display: block;
    width: 100%;
    height: auto;
    z-index: 2;

    &--one {
      position: relative;
      top: -60px;
    }
  }

  &__text {
    position: relative;
    padding: 70px 0;
    top: -54px;
    z-index: 2;

    margin: 0;
    margin-left: 58px;

    p:not(:last-child) {
      margin-bottom: 15px;
    }

    &--one {
      top: -107px;
      margin: 0;
    }

    &--right {
      padding-left: 60px;
    }

    &--left {
      padding-right: 75px;
    }

    ul {
      margin-bottom: 0;
    }
  }

  &-types {
    position: relative;
    left: 0;
    opacity: 1;
    transition: 1s;

    &--start-left {
      left: -100px;
      opacity: 0;
    }

    &__title {
      padding-right: 100px;
      margin-bottom: 45px;

      font-size: 40px;
      line-height: 56px;
      text-transform: uppercase;
    }

    &__list {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;

      width: 100%;
    }
  }

  &-type {
  }
}

.location-type {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  row-gap: 16px;
  flex: 1 1;

  min-width: 240px;
  height: 100%;
  padding: 32px 24px 32px 24px;
  background-color: #FAFAFB;

  &__pic {
    margin: 0 auto;

    img {
      width: 100%;
      height: auto;

      min-width: 192px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;

    flex-grow: 1;
  }

  &__info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    column-gap: 10px;
    flex-wrap: wrap;
  }

  &__price {
    &-value {
      font-size: 24px;
      line-height: 32px;
    }

    &-currency {
      line-height: 32px;
    }
  }

  &__area {
    line-height: 32px;

    &-value {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__link {
    display: inline-block;
    min-height: 48px;
    padding: 12px 14px;
    background: #ffffff;
    border: 1px solid $color-blue-lighter;
    outline: none;

    color: $color-blue-dark;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;

    cursor: pointer;
  }
}

@media (max-width: 1439px) {
  .location {
    &-section p {
      font-size: 14px;
    }

    &__text {
      top: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      margin-bottom: 62px;

      &--one {
        top: -107px;
        margin-bottom: 0;
      }
    }

    &-types {
      &-item {
        flex-basis: 48%;
      }
    }

    &-type {
      flex-basis: 48%;
    }
  }
}

@media all and (max-width: 1168px) {
  .location {
    &__pic--one {
      top: -45px;
    }

    &-section {
      &--two {
        margin-bottom: -45px;
      }
    }

    &-item--four {
      top: -45px;
    }

    &__text {
      top: 0;
      margin-bottom: 62px;

      &--one {
        top: -107px;
        margin-bottom: 0;
      }
    }

    &-types {
      &__title {
        margin-top: 32px;
        margin-bottom: 20px;
      }
    }
  }
}

@media all and (max-width: 940px) {
  .location {
    margin-bottom: 0;

    &__pic--one {
      top: 0;
    }

    &-section {
      flex-wrap: nowrap;

      &:before {
        display: none;
      }

      &--two {

        padding-top: 20px;
        padding-bottom: 20px;
        margin: 20px 0;


        &:before {
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &-item {
      width: auto;

      &--four {
        top: 0;
      }
    }

    &-title {

      h1,
      h2 {
        font-size: 44px;
        line-height: 1.25;
      }
    }

    &__text {
      padding: 40px;
      top: 0;
      min-width: 430px;
      margin-bottom: 0;
    }

    &-cell {
      padding-left: 0;
    }
  }
}

@media all and (max-width: 700px) {
  .location {

    &-title__container {
      padding: 0 20px;

      h1,
      h2 {
        font-size: 28px !important;
      }
    }

    &-section {
      flex-wrap: wrap;

      &--two {
        padding: 0 20px;
        margin: 0;
        flex-direction: column-reverse;

        &:before {
          height: 80%;
        }
      }

      &--three:before {
        width: 100%;
      }

      &__title{
        margin-bottom: 24px;
        max-width: 100%;
      }
    }

    &-item {
      width: 100%;
    }

    &__pic {
      width: calc(100% + 40px);
      margin: 0 -20px;

      .location-section--two & {
        padding: 20px;
      }
    }

    &-title {

      h1,
      h2 {
        font-size: 30px;
        line-height: 1.25;
      }
    }

    &__text {
      margin: 0;
      padding: 20px 0;
      min-width: auto;
    }


    &-type__pic img {
      min-width: 261px;
    }

    &-types {
      &__title {
        padding-right: 0;
        margin-top: 0;

        font-size: 30px;
        line-height: 1.25;
      }
    }
  }
}

@media all and (max-width: 420px) {

  .location {

    &-title h1,
    &-title h2 {
      margin-top: 46px;
      white-space: inherit;
    }

    &-type {
      margin-bottom: -34px;
    }

    &-type {
      padding: 20px;
      min-width: 280px;

      &__info {
        gap: 0;

      }
    }
  }

  .location-types__list .location-type{
    margin-bottom: 0;
  }
}