@import "styles/variable";

.main {
    &__h2 {
        font-size: 64px;
        text-transform: uppercase;
        margin-top: 30px;
        margin-bottom: 32px;
        &--apartment {
            color: #fff;
            span {
                color: #f5f5f5;
                opacity: .5;
            }
        }
        &--location {
            max-width: 598px;
            color: #A4A4BF;
            span {
                color: $color-blue-dark;
            }
        }
        &--advantages {
            max-width: 589px;
            color: #A4A4BF;
            span {
                color: $color-blue-dark;
            }
        }
    }
    &-page {
        margin-top: -130px;        
    }
    &-top {
        position: relative;
        &__slider {
            .slick-dots {
                list-style: none;
                padding-left: 0;
                margin-bottom: 0;
                display: flex;
                position: absolute;
                bottom: 0;
                left: 81px;
                bottom: 25px;
                li {
                    margin-bottom: 0;
                    margin-right: 8px;
                    &.slick-active {
                        button {
                            opacity: 1;
                        }
                    }
                }
                button {
                    font-size: 0;
                    height: 2px;
                    width: 32px;
                    background: #fff;
                    padding: 0;
                    border: 0;
                    opacity: .4;
                }
            }
        }
        &__item {
            position: relative;
        }
        &__title {
            position: absolute;
            top: 137px;
            left: 100px;
            max-width: 426px;
            line-height: 80px;
            z-index: 1;
            font-size: 64px;
            color: #a4b0c4;
            h1 {
                color: #fff;
            }
        }
        &__pic {
            position: relative;
            img {
                width: 100%;
            }
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 86%;
                background: linear-gradient(180deg, #121C3D 0%, rgba(135, 135, 160, 0) 100%);
                left: 0;
                top: 0;
            }
        }
        &__text {
            position: absolute;
            height: 440px;
            width: 440px;
            background: url(/files/images/main/main-ellipse.svg) center top no-repeat,
            url(/files/images/main/main-arc.svg) left 42px no-repeat;
            top: 185px;
            left: calc(50% - 220px);
            color: #fff;
            font-size: 18px;
            text-align: center;
            line-height: 23px;
            font-weight: 300;
            display: flex;
            justify-content: center;
            align-items: center;
            div {
                max-width: 240px;
            }
        }
        &__fin {
            position: absolute;
            right: 0;
            bottom: 91px;
            padding: 16px 40px;
            background: rgba(30, 50, 75, 0.7);
        }
    }
    &-parking {
        padding-top: 40px;
        margin-bottom: 40px;
        &__content {
            display: flex;
        }
        &__item {
            width: 50%;
        }
        &__text {
            padding: 31px 38px;
            background: linear-gradient(341.93deg, #7C8CB6 3.99%, #7F8CB0 52.17%, #63729A 119.62%);
            mix-blend-mode: multiply;
            color: #fff;
            p {
                font-weight: 300;
            }
        }
        &__title {
            font-size: 30px;
            font-family: $font-geo;
            text-transform: uppercase;
            font-weight: 300;
            margin-bottom: 12px;
        }
        &__button {
            margin-top: 59px;
            color: #fff;
            border: 1px solid rgba(255, 255, 255, 0.5);
        }
    }
    &-about {
        display: flex;
        flex-wrap: wrap;
        &__section {
            display: flex;
            margin-bottom: 40px;
            z-index: 1;
        }
        &__desc {
            max-width: 538px;
            p {
                margin-bottom: 20px;
                font-size: 18px;
                font-weight: 400;
            }
        }
        &__pic {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 190px;
                bottom: 3px;
                left: 0;
                background: linear-gradient(180deg, rgba(30, 50, 75, 0) 0%, #1e324b7a 100%);
            }
        }
        &__title {
            font-size: 40px;
            font-family: $font-geo;
            position: absolute;
            left: 36px;
            top: 32px;
        }
        &__button {
            position: absolute;
            left: 24px;
            bottom: 20px;
            color: #fff;
        }
        &__counter {
            position: absolute;
            right: 32px;
            bottom: 32px;
            color: #fff;
        }
        &__numerals {
            width: 100%;
            margin-bottom: -259px;
        }
    }
    &-item {
        width: 50%;
    }
    &-apartment {
        position: relative;
        z-index: 1;
        margin-top: -100px;
        &__content {
            display: flex;
            align-items: center;
        }
    }
    &-choice {
        &__title {
            font-size: 48px;
            text-transform: uppercase;
            position: relative;
            z-index: 1;
            margin-bottom: 20px;
            &--white {
                color: #fff;
            }
        }
        &__item {
            padding: 41px 66px 0;
            position: relative;
            &--parameters {
                min-height: 682px;
                background: #fff;
                box-shadow: 0px 24px 74px rgba(0, 0, 0, 0.06);
            }
            &--visual {
                min-height: 762px;
                padding-top: 79px;
                background: url("/files/images/main/main-visual-choice-gradient.svg") top center / 100% no-repeat, url("/files/images/main/main-visual-choice.jpg") bottom center / 100% no-repeat;
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 300px;
                    background: linear-gradient(180deg, #1E324B 0%, rgba(30, 50, 75, 0) 100%);
                    opacity: 0.4;
                    top: 0;
                    left: 0;
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 745px;
                    background: linear-gradient(244.06deg, #F4D4D8 19.57%, rgba(244, 212, 216, 0) 47.98%);
                    background-blend-mode: screen;
                    mix-blend-mode: screen;
                    opacity: 0.8;
                    top: 0;
                    left: 0;
                }
            }
        }
        &__button {
            margin-top: 26px;
        }
        &__solutions {
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 0 66px 78px
        }
        &__building {
            position: absolute;
            z-index: 1;
            font-size: 10px;
            display: flex;
            color: #fff;
            align-items: stretch;
            cursor: pointer;
            &--first {
                top: 347px;
                left: 144px;
            }
            &--second {
                left: 344px;
                top: 286px;
            }
            &:hover {
                .main-choice__info {
                    display: block;
                }
            }
        }
        &__bubble {
            position: relative;
            width: 50px;
            height: 78px;
            background: $color-blue-dark;
            text-align: center;
            font-family: $font-geo;
            padding: 12px 0;
            margin-right: 1px;
            div {
                font-size: 30px;
                line-height: 38px;
                font-weight: 300;
            }
            &::before {
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                top: 100%;
                left: 0;
                border: 6px solid $color-blue-dark;
                border-bottom-color: transparent;
                border-right-color: transparent;
            }
        }
        &__info {
            max-width: 71px;
            padding: 20px 8px 12px;
            background: rgba(30, 50, 75, 0.9);
            line-height: 15px;
            display: none;
        }
        &__data {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: space-between;
            z-index: 1;
        }
        &__cell {
            width: calc(50% - 1px);
            background: $color-blue-dark;
            color: #fff;
            padding: 12px 0 24px 20px;
            font-family: $font-geo;
            p {
                font-size: 14px;
            }
            sup {
                line-height: 0;
                font-size: 20px;
            }
            span {
                font-size: 40px;
                font-weight: 300;
                margin-top: 10px;
            }
        }
    }
    &-slider {
        max-width: 1100px;
        margin-bottom: 40px;
        button {
            top: 113px;
            &.slick-prev {
                right: 0;
            }
            &.slick-next {
                left: 100%;
            }
        }
    }
    &-location {
        margin-bottom: 83px;
        &__map {
            position: absolute;
            bottom: 10px;
            right: 80px;
        }
        &__text {
            max-width: 538px;
            p {
                margin-bottom: 15px;
            }
        }
    }
    &-map {
        border: 1px solid rgba(0, 0, 0, 0.06);
        box-shadow: 0px 34px 74px -20px rgba(0, 0, 0, 0.16);
        img {
            display: block;
        }
        &__button {
            position: absolute;
            left: 32px;
            bottom: 32px;
        }
    }
    &-advantages {
        padding-bottom: 170px;
        position: relative;
        &__slider {
            button {
                top: -84px;
                &.slick-prev {
                    right: 48px;
                }
                &.slick-next {
                    right: 0;
                }
            }
        }
        &::before {
            content: '';
            width: 100%;
            height: 704px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: $color-blue-dark;
        }
    }
    &-bottom {
        position: relative;
        &__img {
            width: 100%;
            display: block;
        }
        &__section {
            position: absolute;
            height: 100%;
            left: calc(50% - 720px);
            top: 0;
        }
        &__text {
            position: absolute;
            bottom: 275px;
            left: 660px;
            font-size: 64px;
            color: #fff;
            text-transform: uppercase;
            span {
                color: #F5F5F5;
                opacity: .5;
            }
        }
    }
}

.slider-stairs {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 1px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 24.48%, #FFFFFF 80.21%, rgba(255, 255, 255, 0) 100%);
        opacity: .4;
        transform: rotate(180deg);
    }
    &__item {
        transition: width .5s, margin .5s, height .5s, left .5s;
        left: 0;
        width: 640px;
        background: $color-blue-dark;
        position: relative;
        margin-top: 0;
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 1px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 24.48%, #FFFFFF 80.21%, rgba(255, 255, 255, 0) 100%);
            opacity: .4;
            transform: rotate(180deg);
            z-index: 1;
        }
    }
    &__number {
        position: absolute;
        left: 40px;
        top: 56px;
        font-size: 100px;
        color: #fff;
        font-weight: 200;
    }
    &__text {
        position: absolute;
        max-width: 246px;
        top: 600px;
        left: 40px;
        font-size: 18px;
        color: $color-gray-light;
        font-weight: 600;
    }
    &__title {
        color: #fff;
        text-transform: uppercase;
    }
    .slick-track {
        height: 800px;
    }
    .slick-active {
        &--first {
            margin-top: 40px;
        }
        &--second {
            margin-top: 20px;
            width: 346px;
            height: calc(100% + 20px);
            &:hover {
                width: 640px;
                left: -294px;
                & + .slick-active--third {
                    left: -294px;
                }
            }
        }
        &--third {
            width: 294px;
            height: calc(100% + 40px);  
            &:hover {
                width: 640px;
                left: -346px;
            }
        }
    }
}

.solutions {
    &__title {
        font-size: 28px;
        margin-bottom: 15px;
        font-family: $font-geo;
        font-weight: 400;
    }
    &__list {    
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
    }
    &__item {
        font-size: 14px;
        margin-right: 15px;
        color: #8AA4B8;
        margin-bottom: 13px;
        &--small {
            font-size: 10px;
        }
        &--big {
            font-size: 24px;
        }
        &--sale {
            color: #c68484;
        }
    }
}

.numerals {
    display: flex;
    padding-top: 85px;
    &__item {
        width: 25%;
        height: 651px;
        background: linear-gradient(180deg, #1E324B 0%, #345471 100%);
        position: relative;
        padding: 65px 30px;
        color: #fff;
        &:not(:first-child) {
            &::before {
                content: '';
                position: absolute;
                width: 1px;
                height: 60%;
                background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                top: 0;
                left: 0;
                opacity: .4;
            }
        }
        &:nth-child(2) {
            margin-top: -45px;
        }
        &:nth-child(3) {
            margin-top: -90px;
        }
        &:nth-child(4) {
            margin-top: -143px;
        }
        &--entitled {
            padding-top: 52px;
        }
    }
    &__title {
        font-size: 18px;
        font-weight: 600;
        color: $color-yellow-light;
        padding-top: 12px;
        border-top: 1px solid $color-yellow-light;
        margin-bottom: 14px;
        display: inline-block;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &__text {
        max-width: 186px;
    }
    &__numeral {
        font-size: 56px;
        font-weight: 400;
        font-family: $font-geo;
        margin-right: 16px;
    }
    &__img {
        display: block;
        margin: 0 auto;
        &--second {
            margin-top: 34px;
        }
        &--third {
            margin-top: 81px;
        }
        &--fourth {
            margin-top: 76px;
        }
    }
}
