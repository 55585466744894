@import "styles/variable";

.range-section {
  margin-top: 20px;
  margin-bottom: 6px;


  .rc-slider {
    margin: 0 15px 0 10px;
    height: 12px;
  }

  .rc-slider-rail {
    height: 1px;
    background-color: #c4c4c4 !important;
  }

  .rc-slider-rail:disabled {
    height: 1px;
    background-color: rgb(135, 135, 160);
  }

  .rc-slider-track {
    height: 1px;
    background-color: rgb(30, 50, 75);
  }

  .rc-slider-disabled {
    background-color: transparent;

    .rc-slider-handle {
      background-color: rgb(140, 140, 140);
      box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px, rgba(34, 36, 38, 0.15) 0px 0px 0px 1px inset;
    }
  }

  .rc-slider-handle {
    margin-top: -6px;
    height: 12px;
    width: 12px;
    background-color: rgb(30, 50, 75);
    border: none;
    box-shadow: rgba(34, 36, 38, 0.15) 0px 1px 2px 0px, rgba(34, 36, 38, 0.15) 0px 0px 0px 1px inset;
    opacity: 1;
  }

  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    border-color: transparent;
    box-shadow: none;
  }
}

.mortgage {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  h1 {
    text-transform: uppercase;
    margin-top: 64px;
    margin-bottom: 25px;
  }

  &-item {
    width: 50%;
    position: relative;
    opacity: 1;
    transition: 1s;

    &--left {
      left: 0;
      z-index: 1;
    }

    &--right {
      right: 0;
    }

    &--start {
      &_left {
        left: -150px;
        opacity: 0;
      }

      &_right {
        right: -150px;
        opacity: 0;
      }
    }
  }

  &-cell {
    width: 100%;
    max-width: 640px;
    margin-left: auto;
  }

  &-pic {
    img {
      width: 100%;
    }
  }

  &__desc {
    padding-right: 20px;

    &:nth-last-of-type(1) {
      margin-bottom: 28px;
    }
  }

  &-pic {
    margin-bottom: 30px;
  }
}

.visit {
  position: relative;
  background: rgba(134, 134, 159, 0.1);

  &__wrapper {
    max-width: 640px;
    margin-left: auto;
    padding: 47px 0;
  }

  &__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: uppercase;
  }

  &-list {
    list-style: none;
    padding-left: 0;

    &__item {
      margin-bottom: 20px;
    }

    &__num {
      min-width: 40px;
      font-size: 32px;
      display: inline-block;
      margin-right: 16px;
    }
  }

  &__appointment {
    width: 290px;
    height: 290px;
    background: #8787A0;
    padding: 20px;
    position: absolute;
    bottom: -21px;
    right: -35px;
  }

  &__p {
    color: #fff;
    max-width: 195px;

    &--big {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 5px;
    }

    &--small {
      font-size: 14px;
      opacity: 0.5;
    }
  }

  &-button {
    color: #fff;
    margin-top: 79px;
    height: 48px;
    padding: 13px 16px;
  }

  margin-bottom: 90px;
}

.calculation {
  margin-bottom: 80px;
  opacity: 1;
  transition: 1s;
  position: relative;
  top: 0;

  &--start {
    opacity: 0;
    top: 30px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    p {
      max-width: 526px;
    }
  }

  &__parameters {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__item {
    width: calc((100% / 3) - (80px / 3));
    margin-right: 40px;

    &:nth-last-of-type(1) {
      margin-right: 0;
    }

    & > p {
      width: 100%;
    }

    .semantic_ui_range_inner {
      margin-top: 20px !important;
    }
  }

  &-wrapper {
    display: flex;
  }

  &__number {
    font-size: 32px;
    margin-top: 18px;
    min-height: 40px;

    span {
      font-family: $font-geo;
    }

    label {
      font-size: 0;
      line-height: 0;
    }
  }

  &__input {
    font-size: 32px;
    color: #1E324B;
    min-height: 32px;
    height: 40px;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }

  &__percentage {
    text-align: right;
    color: #A6A5A4;
    opacity: 0.4;
    font-size: 32px;
    margin-top: 18px;
    margin-left: auto;
  }

  &__range {
    position: relative;
    margin-bottom: 14px;
    cursor: pointer;
  }

  &__scale {
    width: 400px;
    height: 1px;
    background: #C4C4C4;
    display: inline-block;
    margin-bottom: 6px;
    position: relative;

    &--plume {
      &::before {
        content: '';
        position: absolute;
        width: 61px;
        height: 10px;
        background: linear-gradient(180deg, $color-blue-lighter 0%, rgba(171, 195, 213, 0) 100%);
        opacity: 0.6;
      }
    }

    &--full {
      &::before {
        content: '';
        position: absolute;
        width: 132px;
        height: 2px;
        background: $color-border;
      }
    }
  }

  &__slider {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $color-blue-dark;
    position: absolute;
    display: inline-block;
    left: 58px;

    &--further {
      left: 125px;
    }
  }

  &__min {
    font-size: 14px;
    margin-top: 13px;
    margin-left: 10px;
  }

  &__checks {
    display: flex;
  }

  &__check {
    padding: 10px 13px;
    border-radius: 18px;
    border: 1px solid $color-blue-lighter;
    font-size: 14px;
    cursor: pointer;
    margin-right: 8px;
    white-space: nowrap;
    transition: .5s;

    &--active {
      background: rgba(171, 195, 213, 0.6);
    }

    &:hover {
      background: $color-blue-lightest;
    }
  }
}

.result {
  margin-bottom: 69px;
  opacity: 1;
  transition: 1s;
  position: relative;
  right: 0;

  &--start {
    opacity: 0;
    right: -200px;
  }


  &__empty{
    margin: 32px 0;
    text-align: center;

    .btn{
      margin-top: 32px;
    }
  }

  &__title {
    font-weight: 700;
  }

  &-list {
    min-height: 426px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 48px -10px 16px;

    &__wrapper {
      padding-bottom: 75px;
      width: 100%;
      max-width: 305px;
      margin: 10px;
      transition: .5s;

      &:hover {
        .result-list__item {
          background: #EEF3F6;
          z-index: 1;
        }

        .result__application {
          height: 96px;
          padding: 16px 24px 32px;
          background: #EEF3F6;
          opacity: 1;
          transition: .5s;
        }
      }
    }

    &__item {
      background: #FAFAFB;
      padding: 24px 24px 32px;
      position: relative;
      display: flex;
      height: 100%;
      flex-direction: column;
      transition: .5s;

      &:not(:nth-last-of-type(1)) {
        margin-right: 17px;
      }

      &--best {
        &::before {
          content: 'Лучший выбор';
          position: absolute;
          width: 95px;
          height: 24px;
          background: $color-yellow-light;
          color: $color-blue-dark;
          font-size: 10px;
          padding: 7px 8px;
        }
      }
    }

    &__name {
      display: block;
      margin: 0 0 16px;
      height: 65px;
      width: 126px;
      object-fit: contain;
      object-position: left center;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &__rate{
      position: absolute;
      top: 41px;
      right: 24px;
      text-align: right;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 1;

      span{
        margin-top: 4px;
        display: block;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1;
      }
    }

    &__conditions {
      margin-top: auto;
      display: flex;

      div {
        & > span {
          font-size: 10px;
        }

        p {
          margin-top: 5px;
          white-space: nowrap;

          span {
            font-size: 24px;
          }
        }
      }

      .payment {
        width: 55%;
      }
    }
  }

  &__link {
    margin-top: 10px;
    display: block;
    text-align: center;
    color: $color-blue-dark;
    opacity: 0.5;
    font-size: 12px;
    cursor: pointer;
    padding: 10px;
  }

  &__more {
    display: block;
    margin: 0 auto;
    text-transform: uppercase;
    height: 48px;
  }

  &__application {
    opacity: 0;
    padding: 0 24px;
    margin-top: -32px;
    transition: 0.3s;
    display: block;
    width: 100%;
    text-transform: uppercase;
    background: #FAFAFB;
    height: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 100%;


    button{
      width: 100%;
    }
  }

  &-popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    opacity: 0;
    transition: .5s;

    &--active {
      background: rgba(0, 0, 0, 0.3);
      z-index: 999;
      opacity: 1;
    }
  }

  &-programs {
    transition: 1s;
    max-height: 90%;
    opacity: 1;
    padding: 20px 27px 32px;
    width: 100%;
    background: #EEF3F6;
    position: relative;
    max-width: 1280px;
    border-radius: 5px;
    margin: 20px;
    overflow-y: auto;
    /*&::before {
        content: '';
        position: absolute;
        width: 14px;
        height: 14px;
        border: 7px solid  #EEF3F6;
        border-left-color: transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        top: -14px;
        left: 12%;
    }*/
    &__item {
      background: #fff;
      display: flex;
      padding: 27px 37px 22px;
      margin-bottom: 8px;
      align-items: center;
      cursor: pointer;
      transition: .5s;

      &:hover {
        background: $color-gray-lighter;
      }
    }

    &__title {
      display: flex;
      font-family: $font-geo;
      font-size: 30px;
      font-weight: 300;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;
    }

    &__close {
      width: 48px;
      height: 48px;
      background: #fff;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      transition: .5s;

      &:hover {
        background: #DDE7EE;
      }

      &::before {
        transition: 1s;
        content: '';
        position: absolute;
        width: 16px;
        height: 1px;
        top: 23px;
        left: 16px;
        background: $color-blue-dark;
        transform: rotate(-45deg);
      }

      &::after {
        transition: 1s;
        content: '';
        position: absolute;
        width: 16px;
        height: 1px;
        top: 23px;
        left: 16px;
        background: $color-blue-dark;
        transform: rotate(45deg);
      }
    }

    &__row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-grow: 1;
    }

    &__block {
      width: 25%;
      font-size: 24px;

      span {
        display: block;
        font-size: 13px;
        margin-bottom: 4px;
      }

      &--name {
        font-size: 18px;
      }
    }
  }
}

.special {
  img {
    margin-left: 0;
    margin-right: auto;
    display: block;
    max-width: 100%;
    height: auto;
  }

  &__pic {
  }

  &__offer {
    width: 100%;
    max-width: 770px;
    margin-left: auto;
    margin-right: 0;
    background: linear-gradient(341.93deg, #6C6C89 3.99%, #8787A0 52.17%, #8787A0 119.62%);
    padding: 40px 60px;
    position: relative;
    margin-top: -209px;
    margin-bottom: 72px;
  }

  &__title {
    font-family: $font-geo;
    font-size: 30px;
    color: #fff;
    margin-bottom: 18px;
    font-weight: 300;
    text-transform: uppercase;
    line-height: 38px;
  }

  p {
    color: #fff;
  }

  &-button {
    margin-top: 24px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
  }
}

@media all and (max-width: 1439px) {
  .mortgage {
    &-cell {
      max-width: 548px;
      padding-left: 40px;
    }

    &__desc {
      margin-right: 20px;
    }
  }

  .visit {
    &__wrapper {
      width: 100%;
      max-width: 548px;
      padding-left: 40px;
    }

    &__appointment {
      right: -230px;
    }
  }

  .calculation {
    margin-bottom: 40px;

    &__top {
      flex-wrap: wrap;

      p {
        max-width: 100%;
      }
    }

    &__title {
      margin-bottom: 20px;
    }

    &__parameters {
      flex-wrap: wrap;
      gap: 40px 0;
    }

    &__item {
    }
  }
}

@media all and (max-width: 1168px) {
  .result {
    &-list {
      justify-content: center;

      &__conditions {
        div {
          p {
            span {
              font-size: 20px;
            }
          }
        }
      }
    }

    &-programs {
      &__block {
        &--name {
          font-size: 16px;
        }
      }
    }

    &__application {
      font-size: 14px;
      height: auto;
    }
  }
  .calculation {
    &__item {
      width: calc(50% - 40px)

    }
  }
}

@media all and (max-width: 940px) {
  .mortgage {
    flex-wrap: wrap;

    &-item {
      width: 100%;
    }

    &-cell {
      max-width: 100%;
    }
  }
  .visit {
    &__wrapper {
      max-width: 100%;
    }

    &__appointment {
      right: 0;
    }
  }
  .calculation {
    &__item {
      width: 60%;
      margin: 0 auto 30px;

      &:first-of-type {
        margin: 0 auto 30px;
      }

      &:nth-last-of-type(1) {
        margin-left: 0;
      }
    }
    &__parameters{
      gap: 0;
      justify-content: center;
    }
  }
  .result {
    &-list {
      &__wrapper{
        padding-bottom: 0;
        &:hover{
          .result__application{
            margin-top: 16px;
            padding: 0;
            height: auto;
          }
        }
      }
    }

    &__application {
      padding: 0;
      position: relative;
      top: 0;
      margin-top: 16px;
      opacity: 1;
    }

    &-programs {
      padding: 20px;

      &__title {
        font-size: 20px;
      }

      &__item {
        padding: 20px;
      }

      &__block {
        font-size: 18px;

        &--name {
          font-size: 14px;
        }
      }
    }
  }
  .special {
    &__offer {
      width: 770px;
      max-width: 100%;
      margin-top: -100px;
    }
  }
}

@media all and (max-width: 800px) {
  .visit__appointment {
    position: static;
    margin-left: auto;
    max-width: 100%;
  }
}

@media all and (max-width: 700px) {
  .mortgage {
    h1 {
      margin-top: 0;
    }

    &-cell {
      padding: 0px 20px;
    }
  }
  .visit {
    margin-bottom: 0;

    &__wrapper {
      padding: 40px 20px;
    }

    &-button {
      width: 100%;
    }

    &-list {
      &__num {
        margin-right: 10px;
        font-size: 24px;
      }
    }
  }
  .calculation {
    &__item {
      width: 400px;
      max-width: 100%;

    }

    &__number {
      width: auto;
      font-size: 24px;
    }

    &__percentage {
      font-size: 24px;
      margin-left: auto;
      width: auto;
      margin-top: 18px;
    }

    &__checks {
      flex-wrap: wrap;
    }

    &__check {
      margin-bottom: 8px;
    }

    &__range {
      max-width: 100%;
    }

    &__scale {
      max-width: 100%;
    }
  }
  .result {
    &-list {
      justify-content: center;
    }

    &-programs {
      &__title {
        font-size: 16px;

        div:first-of-type {
          max-width: 80%;
        }
      }

      &__item {
        flex-wrap: wrap;
      }

      &__close {
        width: 30px;
        height: 30px;

        &::before, &::after {
          top: 15px;
          left: 7px;
        }
      }

      &__block {
        width: 30%;
        margin-right: 10px;
        justify-content: space-between;
        font-size: 12px;

        &:nth-last-of-type(1) {
          margin-right: 0;
        }

        &--name {
          width: 100%;
          margin-bottom: 10px;
        }

        span {
          font-size: 11px;
        }
      }
    }
  }
  .special {
    &__offer {
      margin-top: 0;
      padding: 40px 20px;
    }

    &__title {
      font-size: 18px;
      line-height: 24px;
    }

    p {
      font-size: 11px;
    }
  }
}

@media all and (max-width: 500px) {
  .result {
    &-list {
      &__wrapper {
        max-width: none;
        min-height: auto;
      }

      &__name {
        margin: 20px auto;
      }
    }

  }
}
