.plan {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    h1 {
        text-transform: uppercase;
        margin-top: 64px;
        margin-bottom: 25px;   
    }
    &-item {
        width: 50%;
        position: relative;
        opacity: 1;
        transition: 1s;
        &--left{
            left: 0;
            z-index: 1;
        }
        &--right {
            right: 0;
        }
        &--start {
            &_left {
                left: -50px;
                opacity: 0;
            }
            &_right {
                right: -50px;
                opacity: 0;
            }
        }
    }
    &-cell {
        max-width: 640px;
        margin-left: auto;
    }
    &-pic {
        img {
            width: 100%;
            height: auto;
        }
    }
    &__desc {
        margin-bottom: 90px;
        max-width: 564px;
    }
}

.advantages {
    background: linear-gradient(0deg, #213E60, #213E60);
    padding: 34px 50px;
    color: #ffffff;
    position: relative;
    left: 0;
    bottom: 0;
    opacity: 1;
    transition: 1s;
    margin-bottom: 64px;
    width: calc(100% + 95px);
    &--start {
        opacity: 0;
        left: -60px;
        bottom: -60px;
    }
    &__title {
       font-size: 20px;
       text-transform: uppercase; 
       margin-bottom: 30px;
    }
    &__list {
        list-style: none;
        padding-left: 0;
        margin-bottom: 57px;
    }
    &__button {
        color: #fff;
        &:hover {
            background: #1A2C41;
            color: #fff;
        }
    }
    &__rate {
        position: absolute;
        right: 50px;
        bottom: 0;
        font-size: 67px;
        font-weight: 300;
        span {
            font-size: 153px;
        }
    }
}

@media all and (max-width: 1439px) {
    .plan {
        &-cell {
            max-width: 548px;
            padding-left: 40px;
        }
        &__desc {
            margin-right: 20px;
        }
    }
}

@media all and (max-width: 1168px) {
    .advantages {
        &__rate {
            bottom: 20px;
            span {
                font-size: 80px;
            }
        }
    }
}

@media all and (max-width: 940px) {
    .plan {
        flex-wrap: wrap;
        &-item {
            width: 100%;
        }
        &-cell {
            max-width: 100%;
            padding-right: 40px;
        }
        &-pic {
            img {
                display: block;
            }
        }
        &__desc {
            margin-bottom: 30px;
            max-width: 100%;
        }
    }
    .advantages {
        width: 100%;
        margin-bottom: 30px;
    }
}

@media all and (max-width: 700px) {
    .plan {
        h1 {
            margin-top: 0;
        }
        &-cell {
            padding: 0px 20px;
        }
    }
    .advantages {
        width: calc(100% + 40px);
        margin: 0 -20px;
        padding: 40px 20px;
        &__list {
            margin-bottom: 100px;
        }
        &__rate {
            bottom: 90px;
        }
    }
}