@import "styles/variable";

.choice {
    padding-bottom: 80px;
    h1 {
        font-size: 40px;
        margin-bottom: 30px;
    }
    &-nav {
        display: flex;
        position: absolute;
        height: 218px;
        top: -24px;
        align-items: flex-end;
        justify-content: space-between;
        padding: 22px 20px;
        right: 0;
        background: $color-blue-dark;
        z-index: 100;
        width: 100%;
        max-width: 720px;
        &__link {
            color: #fff;
            text-transform: uppercase;
            padding-right: 28px;
            background: url(/files/images/svg/white-arrow-right.svg) right 35%/18px 14px no-repeat;
            font-weight: 600;
            font-size: 18px;
            &:hover {
                color: $color-yellow-light;
            }
        } 
    }
    &__info {
        display: flex;
        margin-top: 26px;
        align-items: center;
        padding-left: 15px;
        justify-content: space-between;
    }
    &__switcher {
        max-width: 260px;
        .switcher__item {
            background-position: 16px center;
            background-repeat: no-repeat;
            padding: 9px 12px 9px 44px;
            &--tile {
                background-image: url(/files/images/choice/tile.svg);
            }
            &--list {
                background-image: url(/files/images/choice/list-dark.svg);
            }
        }
    }
    &__result {
        font-size: 18px;
        font-weight: 600;
        margin-right: 35px;
        display: inline-block;
    }
    &__result {
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
    }
    &__sort {
        display: flex;
        margin-top: 15px;
        padding-left: 15px;
        justify-content: flex-start;
        align-items: flex-start;
        span {
            margin-right: 32px;
            padding-top: 10px;
        }
    }
    &__select {
        font-size: 16px;
        padding-bottom: 14px;
        border: 0;
        border-bottom: 2px solid transparent;
        margin-right: 32px;
        padding-top: 10px;
        color: $color-blue-dark;
        font-family: $font-mont;
        &--active {
            font-weight: 600;
            border-color: $color-blue-dark;
        }
    }
    &__more {
        margin: 0 auto;
        display: block;
    }
}

.apartments {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 48px;
    &__item {
        width: 24%;
        background: #FAFAFB;
        margin-bottom: 20px;
        padding: 20px;
        cursor: pointer;
        transition: .5s;
        position: relative;
        &:hover {
            background: $color-gray-lighter;
            .apartments__more {
                background: $color-blue-lighter;
            }
        }
        &--sale {
            background: linear-gradient(163.5deg, rgba(222, 209, 162, 0.5) 0%, rgba(222, 209, 162, 0) 17.02%), #FAFAFB;
            &::before {
                content: '';
                position: absolute;
                width: 45px;
                height: 45px;
                background: url(/files/images/choice/sale.svg);
                left: 0;
                top: 0;
            }
        }
        &--types {
            &::before {
                content: '';
                position: absolute;
                width: calc(100% - 20px);
                height: 100%;
                max-height: 364px;
                left: 10px;
                bottom: 0;
                z-index: -1;
                background: #213E60;
                opacity: 0.2;
                filter: blur(5px);
            }
            &::after {
                content: '';
                position: absolute;
                width: calc(100% - 20px);
                height: 100%;
                left: 10px;
                bottom: -4px;
                z-index: -2;
                background: #FAFAFB;
            }
        }
    }
    &__chosennes {
        margin-left: auto;
        margin-right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        background: url(/files/images/choice/star.svg) right center no-repeat;
        padding: 5px 0;
        &--active {
            background-image: url(/files/images/choice/star-selected.svg);
        }
    }
    &__number {
        margin-left: auto;
        margin-right: 0;
        max-width: 60px;
        text-align: right;
        font-size: 12px;
        opacity: 0.4;
    }
    &__rooms {
        span {
            display: inline-block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #FFF;
            border: 1px solid rgba(0, 0, 0, 0.04);
            text-align: center;
            padding: 4px 0;
            font-size: 15px;
        }
    }
    &__pic {
        display: block;
        margin: 0 auto 12px;
    }
    &__finish {
        height: 28px;
        padding: 9px 11px;
        font-size: 10px;
        max-width: 83px;
        border-radius: 14px;
        margin-bottom: 12px;
        &--thereis {
            color: #fff;
            background: #8787A0;
        }
    }
    &__p {
        margin-bottom: 16px;
        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }
    }
    &__name {
        margin-bottom: 0;
    }
    &__type {
        font-weight: 700;
    }
    &__area {
        font-weight: 600;
    }
    &__price {
        font-weight: 600;
        font-size: 18px;
        &--sale {
            color: #C6B166;
            width: 49%;
            display: inline-block;
        }
        &--old {
            width: 49%;
            display: inline-block;
            font-size: 14px;
            font-weight: 400;
            text-decoration: line-through;
            text-align: right;
        }
    }
    &__location {
        display: flex;
        p {
            width: 50%;
        }
    }
    &__more {
        width: calc(100% + 40px);
        margin-left: -20px;
        margin-right: -20px;
        padding: 12px 20px;
        font-size: 14px;
        margin-top: 20px;
        background: $color-gray-lighter;
        text-align: center;
        font-weight: 500;
        transition: .5s;
    }
    &-list {
        margin-bottom: 32px;
        margin-top: 15px;
        &__sort {
            padding: 10px 130px 0 95px;
            display: flex;
            margin-top: 40px;
            justify-content: space-between;
        }
        &__select {
            padding-bottom: 14px;
            position: relative;
            border-bottom: 2px solid transparent;
            padding-right: 28px;
            cursor: pointer;
            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                border: 2px solid $color-blue-dark;
                border-right-color: transparent;
                border-bottom-color: transparent;
                transform: rotate(225deg);
                right: 0;
                top: 13px;
                transform-origin: left top;
            }
            &--active {
                font-weight: 600;
                border-color: $color-blue-dark;
            }
            &--floors {
                margin-right: 30px;
            }
            &--price {
                padding-right: 84px;
            }
        }
        &__item {
            display: flex;
            justify-content: flex-start;
            padding: 10px 33px;
            align-items: center;
            background: rgba(231, 231, 236, 0.2);
            transition: .5s;
            font-size: 22px;
            font-weight: 400;
            color: $color-blue-dark;
            margin-bottom: 8px;
            position: relative;
            &:nth-last-of-type(1) {
                margin-bottom: 0;
            }
            &--sale {
                background: linear-gradient(94.18deg, rgba(222, 209, 162, 0.2) 0%, rgba(222, 209, 162, 0) 25.86%), rgba(231, 231, 236, 0.2);
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 30px;
                    height: 30px;
                    background: url("/files/images/choice/sale.svg") center / cover no-repeat;
                }
            }
            &:hover {
                background: $color-gray-lighter;
            }
        }
        &__characteristics {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: calc(100% - 152px)
        }
        &__characteristic {
            text-align: center;
        }
        // &__pic {
        //     margin-right: -55px;
        // }
        &__rooms {
            width: 90px;
            > span {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                text-align: center;
                font-size: 22px;
                padding: 14px 0;
                background: #fff;
                display: block;
                margin: 0 auto;
                span {
                    display: none;
                }
            }
        }
        &__area {
            font-size: 30px;
            width: 120px;
            span {
                font-size: 16px;
            }
        }
        &__floor {
            width: 69px;
            span {
                display: none;
            }
        }
        &__building {
            width: 89px;
        }
        &__finish {
            width: 97px;
        }
        &__price {
            font-size: 30px;
            width: 200px;
            text-align: right;
            &--sale {
                color: $color-yellow-light;
                position: relative;
                .old {
                    position: absolute;
                    text-decoration: line-through;
                    font-size: 16px;
                    top: -100%;
                    right: 0;
                    color: $color-blue-dark;
                }
            }
        }
        &__chosennes {
            width: 20px;
            height: 20px;
            background: url("/files/images/svg/star.svg") center center / cover no-repeat;
            margin: 0 20px 0 32px;
            &--active {
                background: url("/files/images/svg/star-selected.svg") center center / cover no-repeat;
            }
        }
        &__compare {
            width: 24px;
            height: 24px;
            background: url("/files/images/svg/to-compaire.svg") center center / 100% no-repeat;
            margin-left: auto;
            &--active {
                background: url("/files/images/svg/in-compaire.svg") center center / 100% no-repeat;
            }
        }
    }
    &__details {
        padding: 87px 216px 61px 60px;
    }
    &-table {
        width: 100%;
        height: 100%;
        &__num {
            font-size: 24px;
            line-height: 32px;
        }
        &__price {
            font-size: 20px;
            span {
                font-weight: 400;
                font-size: 30px;
                line-height: 1.4;
            }
        }
        &__rooms {
            display: inline-block;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid $color-blue-lighter;
            text-align: center;
            padding: 12px 0;
            margin-left: 8px;
        }
    }
}

//.filter {
//    background: $color-gray-lighter;
//    padding: 30px 30px 8px;
//    &__section {
//        display: flex;
//        justify-content: space-between;
//        align-items: flex-end;
//        &--top {
//            padding-bottom: 22px;
//        }
//        &--bottom {
//            border-top: 1px solid rgba(187, 193, 207, 0.5);
//            padding: 25px 0;
//            display: none;
//        }
//    }
//    &__item {
//        &--rooms {
//            display: flex;
//            align-items: center;
//            span {
//                margin-right: 15px;
//            }
//        }
//        sup {
//            line-height: 0;
//        }
//    }
//    &__rooms {
//        width: 40px;
//        height: 40px;
//        border-radius: 50%;
//        border: 1px solid $color-blue-lighter;
//        text-align: center;
//        padding: 12px 0;
//        margin-right: 8px;
//        cursor: pointer;
//        &--active {
//            background: $color-blue-dark;
//            color: #fff;
//        }
//        &:hover {
//            background: $color-blue-lightest;
//            color: $color-blue-dark;
//        }
//    }
//    &__switcher {
//        max-width: 144px;
//        .switcher__item {
//            padding: 8px 20px;
//        }
//    }
//    &__change {
//        padding: 10px 27px 10px 0;
//        position: relative;
//        cursor: pointer;
//        display: none;
//        color: #213E60;
//        &::after {
//            content: '';
//            position: absolute;
//            width: 8px;
//            height: 8px;
//            border: 2px solid $color-blue-lighter;
//            border-right-color: transparent;
//            border-bottom-color: transparent;
//            right: 5px;
//            transform-origin: center;
//        }
//        &--more {
//            max-width: 270px;
//            &::after {
//                transform: rotate(-135deg);
//                top: calc(50% - 8px);
//            }
//        }
//        &--less {
//            max-width: 106px;
//            display: none;
//            &::after {
//                transform: rotate(45deg);
//                top: calc(50% - 2px);
//            }
//        }
//    }
//    &__range {
//        margin-top: 10px;
//    }
//    &__term {
//        background: transparent;
//        border-radius: 0;
//        border: 1px solid #536589;
//        font-family: $font-mont;
//        padding: 13px 10px;
//        color: $color-blue-dark;
//        font-size: 14px;
//        max-width: 207px;
//        cursor: pointer;
//        &:hover {
//            background: $color-blue-lightest;
//        }
//    }
//    &__more {
//        margin-top: 15px;
//        span {
//            position: relative;
//            display: inline-block;
//            padding-left: 28px;
//            margin-right: 10px;
//            cursor: pointer;
//            &::before {
//                content: '';
//                position: absolute;
//                width: 20px;
//                height: 20px;
//                border: 1px solid $color-blue-lighter;
//                left: 0;
//                top: -2px;
//            }
//        }
//    }
//}

.show-flex {
    display: flex;
}

//.range {
//    display: flex;
//    justify-content: space-between;
//    min-width: 360px;
//    &__slider {
//        width: 12px;
//        height: 12px;
//        border-radius: 50%;
//        background: $color-blue-dark;
//        display: inline-block;
//    }
//    &__item {
//        cursor: pointer;
//    }
//    &__scale {
//        width: 260px;
//        height: 1px;
//        background: #8787A0;
//        opacity: 0.4;
//        display: inline-block;
//        margin: auto -5px 5px;
//    }
//}

@media all and (max-width: 940px) {
    .apartments-list {
        &__select {
            font-size: 12px;
            &__after {
                width: 9px;
                height: 9px;
                top: 9px;
            }
            &--building {
            display: none;
            }
        }
        &__building {
            display: none;
        }
        &__area {
            font-size: 22px;
        }
        &__floor {
            font-size: 18px;
        }
        &__price {
            font-size: 22px;
            .rouble {
                &::before {
                    top: -1px;
                }
                &::after {
                    top: 2px;
                }
            }
        }
    }
}

@media all and (max-width: 700px) {
    .apartments-list {
        margin-top: 20px;
        &__sort {
            display: none;
        }
        &__item {
            padding: 13px;
        }
        &__characteristics {
            flex-wrap: wrap;
            position: relative;
            padding-right: 100px;
            width: calc(100% - 54px);
            margin-left: 5px;
        }
        &__characteristic {
            margin-right: 5px;
        }
        &__rooms {
            width: auto;
            > span {
                width: auto;
                height: auto;
                border-radius: 0;
                background: none;
                font-size: 12px;
                line-height: 15px;
                padding: 0;
                font-weight: 400;
                span {
                    display: inline-block;
                }
            }
        }
        &__area {
            font-weight: 400;
            font-size: 12px;
            width: auto;
            sup {
                line-height: 0;
            }
            span {
                font-size: 12px;
            }
        }
        &__price {
            position: absolute;
            right: 0;
            width: 150px;
            width: 100px;
        }
        &__compare {
            display: none;
        }
        &__chosennes {
            display: none;
        }
        &__floor {
            font-size: 12px;
            width: auto;
            span {
                display: inline-block;
            }
        }
        &__building {
            display: block;
            font-size: 12px;
            width: auto;
        }
        &__price {
            font-size: 14px;
            &--sale .old {
                display: none;
            }
        }
    }
}

@media all and (max-width: 430px) {
    .apartments-list__characteristics {
        justify-content: flex-start;
    }
}